import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/zh-cn';
// import dva from 'dva'
import { BrowserRouter, Route, Switch, Router } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { injectIntl, IntlProvider, FormattedRelative, useIntl } from 'react-intl';
import '@/api/apiconfig';
import '@/js/appbase';
import '@/js/fetch';
import '@/utils/globle';
import '@/utils/cache';
import Layout from './layouts/index';
import './asset/style/reset.css';
import './asset/style/common.scss';

// import '@ant-design/pro-components/dist/components.css';

import Home from './page/home/index';
import Login from './page/login';
import Logout from './page/login/logout';
import Login_code from './page/login/login_in';
import CustomerList from './page/customer/customer-list';
import User from './page/setting/user';
import Role from './page/setting/role';
import UserPrivace from './page/setting/user-privace';

import Settle from './page/miniprogram/settle';
import Miniprogram from './page/miniprogram/list';

import Consultation from './page/consultation';
import Decorate from './page/consultation/decorate';
import Turntable from './page/turntable';
import Coupon from './page/coupon';
import Payment from './page/payment';
import ClueConsult from './page/clueConsult';
import ClueVisitor from './page/clueVisitor';
import AuthUser from './page/authUser';
import AuthVideoList from './page/authVideoList';

dayjs.locale('zh-cn');
moment.locale('zh-cn');

class Container extends Component {
  render() {
    return (
      <BrowserRouter basename="/pc">
        <CacheSwitch>
          <Layout>
            <Route path="/qrcode" exact component={Login_code} />
            <Route path="/home" component={Home} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/login" exact component={Login} />
            {/* 客户管理 */}
            <CacheRoute path="/customers" exact component={CustomerList} />
            {/* 用户管理 */}
            <Route path="/setting-user" exact component={User} />
            {/* 角色管理 */}
            <Route path="/setting-role" exact component={Role} />
            {/* 用户隐私协议设置 */}
            <Route path="/setting-user-privace" exact component={UserPrivace} />
            <Route path="/settle" exact component={Settle} />
            <Route path="/miniprogram" exact component={Miniprogram} />
            <Route path="/consultation" exact component={Consultation} />
            <Route path="/consultation-decorate" exact component={Decorate} />
            <Route path="/turntable" exact component={Turntable} />
            <Route path="/coupon" exact component={Coupon} />
            <Route path="/payment" exact component={Payment} />
            <Route path="/clue-consult" exact component={ClueConsult} />
            <Route path="/clue-visitor" exact component={ClueVisitor} />
            <Route path="/auth-user" exact component={AuthUser} />
            <Route path="/auth-video" exact component={AuthVideoList} />
          </Layout>
        </CacheSwitch>
      </BrowserRouter>
    );
  }
}
ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <Container />
  </ConfigProvider>,
  document.getElementById('root')
);
