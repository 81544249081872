import React, { useEffect } from 'react'
import { Modal, Input, Empty, Spin } from 'antd';
import classnames from 'classnames'
import update from 'immutability-helper'
import _ from 'lodash'
import './Label.scss'
import { useSingleState } from 'nice-hooks';

function Label({
  visible,
  onCancel,
  onOk,
  allLabels,
  value,
  excludeValue,
  loading,
  title = '标签列表'
}) {
  const [state, setState] = useSingleState({
    selectedLabels: [],
    excludeLabels: [],
    searchValue: '',
    searchData: [],
  })

  useEffect(() => {
    setState({
      selectedLabels: value,
      excludeLabels: excludeValue ? excludeValue : [],
    })
  }, [visible, value])


  function onSearch(value) {
    let data = []
    for (const a of allLabels) {
      const item = { ...a, label_array: a.label_array.filter(aDataItem => aDataItem.names.includes(value)) }
      if (item.label_array.length > 0) {
        data.push(item)
      }
    }
    setState({
      searchData: data,
      searchValue: value,
    })
  }

  function onCheckLabel(item) {
    const excludeCodes = state.excludeLabels.map(item => item.code)
    if (excludeCodes.includes(item.code)) {
      return false
    }
    const codes = state.selectedLabels.map(item => item.code)
    if (codes.includes(item.code)) {
      const oldList = state.selectedLabels
      const index = oldList.findIndex(list => list.code === item.code);
      setState({
        selectedLabels: update(state.selectedLabels, { $splice: [[index, 1]] })
      })
    } else {
      setState({
        selectedLabels: update(state.selectedLabels, { $push: [{ name: item.names, code: item.code, tag_id: item.tag_id }] })
      })
    }
  }

  const onSubmit = () => {
    setState({searchValue: null})
    typeof onOk === 'function' && onOk(state.selectedLabels)
  }

  function onCloseModal() {
    setState({searchValue: null})
    onCancel()
  }

  return <Modal
    title={title}
    visible={visible}
    className='label-modal'
    onOk={onSubmit}
    onCancel={onCloseModal}
    destroyOnClose
  >
    {
      loading ? <Spin /> : <div className='list'>
        <Input placeholder='请输入标签' onPressEnter={(e) => onSearch(e.target.value)} />
        {
          state.searchValue && state.searchData.length === 0 ? <Empty description='没有搜到相关标签哦~' /> : <ul>
            {
              (state.searchValue ? state.searchData : allLabels).map((item, index) => (
                <li key={item.id}>
                  <div className='parent'>{item.name}:</div>
                  <div className='tags'>
                    {item.label_array.map((itemChildren, index) => (
                      <div key={index} className={classnames('tag_item',
                        { active: state.selectedLabels.map(item => item.code).includes(itemChildren.code) },
                        { disabled: state.excludeLabels.map(item => item.code).includes(itemChildren.code) }
                      )}
                        onClick={() => onCheckLabel(itemChildren)}>
                        {itemChildren.names}
                      </div>
                    ))}
                  </div>
                </li>
              ))
            }
          </ul>
        }
      </div>
    }

  </Modal>
}

export default Label
