import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Tag } from 'antd';
import { Line } from '@ant-design/charts';
import moment from 'moment';

import './index.scss';

function Home() {
  const [statiscInfo, setStatiscInfo] = useState(null);
  const [lineChartsData, setLineChartsData] = useState([]);

  const lineChartsConfig = {
    data: lineChartsData,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    smooth: true,
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { name, value, color } = item;
                return (
                  <li
                    key={item.date}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                  >
                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">{value}</span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#2593fc',
        lineWidth: 2,
      },
    },
  };

  useEffect(async () => {
    getData();
    getAnalysis();
  }, []);

  const getData = async () => {
    try {
      let { data } = await window.g_dyFetch({
        url: window.g_api.home_statisc_url,
        method: 'GET',
      });
      setStatiscInfo(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAnalysis = async () => {
    try {
      let { data } = await window.g_dyFetch({
        url: window.g_api.home_analysis_url,
        method: 'GET',
      });
      let vList = [];
      let mList = [];
      data.forEach((item, index) => {
        vList.push({
          date: item.date,
          value: Number(item.vist_num),
          category: '访客量',
        });
        mList.push({
          date: item.date,
          value: Number(item.message_num),
          category: '留资量',
        });
      });
      setLineChartsData([...vList, ...mList]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="home-page">
      <Row gutter={10} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <Row style={{ height: '100%' }}>
            <Col span={12} className="add-border">
              <div className="flex-view">
                <div className="info-view">
                  <p className="title">{statiscInfo?.app_num || 0}</p>
                  <p className="desc">小程序</p>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex-view">
                <div className="info-view">
                  <p className="title">{statiscInfo?.tools_num || 0}</p>
                  <p className="desc">营销活动</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row style={{ height: '100%' }}>
            <Col span={8} className="add-border">
              <div className="flex-view">
                <div className="info-view">
                  <p className="title">{statiscInfo?.pv || 0}</p>
                  <p className="desc">浏览量</p>
                </div>
              </div>
            </Col>
            <Col span={8} className="add-border">
              <div className="flex-view">
                <div className="info-view">
                  <p className="title">{statiscInfo?.uv || 0}</p>
                  <p className="desc">访客数</p>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="flex-view">
                <div className="info-view">
                  <p className="title">{statiscInfo?.message || 0}</p>
                  <p className="desc">留资量</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Card style={{ height: '100%' }}>
            <Row justify="start" gutter={15}>
              <Col span={18}>
                <div className="company-name">
                  <span style={{ marginRight: 10 }}>
                    {window.g_cache.getItem('company')
                      ? window.g_cache.getItem('company')
                      : '请先授权小程序或使用代入驻功能'}
                  </span>
                  {window.g_cache.getItem('company') &&
                    window.g_cache.getItem('company') !== '' && <Tag color="green">已认证</Tag>}
                </div>
                <p>专业版</p>
                <p>
                  {moment(Number(window.g_cache.getItem('enterprise').expired_time) * 1000).format(
                    'YYYY-MM-DD'
                  )}
                  到期
                </p>
                <div>
                  人数：
                  {window.g_cache.getItem('enterprise')
                    ? window.g_cache.getItem('enterprise').user_num
                    : 1}
                  人
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card title="数据分析" style={{ marginBottom: 10 }}>
        <Line key="lineCharts" {...lineChartsConfig} />
      </Card>
    </div>
  );
}

export default Home;
