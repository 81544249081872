/*
 * @Author: zhao
 * @Date: 2021-05-19 19:31:22
 * @LastEditTime: 2021-12-31 14:24:06
 * @LastEditors: Please set LastEditors
 * @Description:
 */
import { message } from 'antd';

export default function postFetch(url, body, callback, method = 'POST') {
  let options = {};
  if (method === 'POST') {
    options = {
      method: method,
      mode: 'cors',
      body: body,
    };
  } else if (method === 'GET') {
    options = {
      method: method,
      mode: 'cors',
    };
    for (let key in body) {
      url += '&' + key + '=' + body[key];
    }
  }
  fetch(url, options)
    .then(res => res.json())
    .then(ress => {
      if (ress.code === '40007') {
        message.warning(ress.msg).then(() => {
          window.location.href = '/logout';
        });
      }
      if (ress.code === '401') {
        message.warning('账号已被禁用,正在退出登录').then(() => {
          window.location.href = '/logout';
        });
      }
      if (ress.code === '4001') {
        window.location.href = '/401';
      }
      callback(ress);
    })
    .catch(err => {
      console.log(err);
    });
}

/**
 * 新请求方法
 * @param {} token
 * @param {*} url
 * @param {*} body
 * @param {*} callback
 */
function request(options, callback) {
  console.log('请求', options);
  window.g_appbase.get_app_token(token => {
    // const access_key = window.g_api.appId + "_pull_msg_token_" + window.g_api.debug;
    // window.g_appbase.setCookie(access_key, token, 29000);
    let middle_url;
    var str = options.url;
    var url_length = str.split('/').length - 1;
    if (url_length >= 3) {
      middle_url =
        options.url.indexOf('?') != -1
          ? `${window.g_api.base_url}${options.url}&token=${token}`
          : `${window.g_api.base_url}${options.url}?token=${token}`;
    } else {
      middle_url =
        window.g_api.request_action + '?token=' + token + '&request_action=' + options.url;
    }
    let formData = new FormData();
    if (options.body) {
      for (let key in options.body) {
        if (
          options.body[key] === undefined ||
          options.body[key] === null ||
          options.body[key] === false
        ) {
          formData.append(key, '');
        } else {
          formData.append(key, options.body[key]);
        }
      }
    }
    fetch(middle_url, {
      method: 'POST',
      mode: 'cors',
      body: options.type != 'old' ? formData : options.body,
    })
      .then(res => res.json())
      .then(ress => {
        if (ress.code === '40007') {
          message.warning(ress.msg).then(() => {
            window.location.href = '/logout';
          });
        }
        if (ress.code === '401') {
          message.warning('账号已被禁用,正在退出登录').then(() => {
            window.location.href = '/logout';
          });
        }
        if (ress.code === '4001') {
          window.location.href = '/401';
        }
        callback(ress);
      })
      .catch(err => {
        console.log(err);
      });
  });
}

/**
 * 抖音项目请求方法
 * @param {} token
 * @param {*} url
 * @param {*} body
 */
function dyFetch(options) {
  return new Promise((resolve, reject) => {
    window.g_appbase.get_app_token(async token => {
      let middle_url =
        options.url.indexOf('?') === -1
          ? options.url + '?token=' + token
          : options.url + '&token=' + token;
      let fetchOptions = {
        method: options.method || 'POST',
        mode: 'cors',
      };
      if (options.method === 'POST') {
        // body为对象时转换为formData
        if (options.bodyType === 'obj') {
          let formData = new FormData();
          if (options.body) {
            for (let key in options.body) {
              if (options.body[key]) {
                formData.append(key, options.body[key]);
              }
            }
          }
          console.log('formData:', formData);
          fetchOptions.body = formData;
        } else {
          fetchOptions.body = options.body;
        }
      } else if (options.method === 'GET') {
        for (let key in options.body) {
          middle_url += '&' + key + '=' + options.body[key];
        }
      }
      try {
        let res = await fetch(middle_url, fetchOptions);
        let data = await res.json();
        if (data.code === '40007') {
          message.warning(data.msg).then(() => {
            window.location.href = '/logout';
          });
        } else if (data.code === '401') {
          message.warning('账号已被禁用,正在退出登录').then(() => {
            window.location.href = '/logout';
          });
        } else if (data.code === '4001') {
          window.location.href = '/401';
        } else if (data.code == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });

    // .then(res => res.json())
    // .then(ress => {
    //   if (ress.code === '40007') {
    //     message.warning(ress.msg).then(() => {
    //       window.location.href = '/logout';
    //     });
    //   }
    //   if (ress.code === '401') {
    //     message.warning('账号已被禁用,正在退出登录').then(() => {
    //       window.location.href = '/logout';
    //     });
    //   }
    //   if (ress.code === '4001') {
    //     window.location.href = '/401';
    //   }
    //   callback(ress);
    // })
    // .catch(err => {
    //   console.log(err);
    // });
  });
}

window.g_request = request;
window.g_postFetch = postFetch;
window.g_dyFetch = dyFetch;
