import React from 'react';

import {
  CloseOutlined,
  DownOutlined,
  HomeOutlined,
  LogoutOutlined,
  EllipsisOutlined,
  HolderOutlined,
  UserOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { useAtom } from 'jotai';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Menu, Dropdown, Divider, Tooltip, message, Badge, Space } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { Router } from 'react-router';
import '../utils/globle';
import _ from 'lodash';
import classnames from 'classnames';
import zh_CN from '../locales/zh-CN';
import en_US from '../locales/en-US';
import MyIcon from '../component/MyIcon';
import appbase from '../js/appbase';
import ApiConfig from '../api/apiconfig';
import NavHeaderCreate from '@/component/Header/create';
import NavHeader from '@/component/Header';
import styles from './index.module.scss';
import Lang from '@/asset/image/lang.svg';
import LightTheme from '@/asset/image/light-theme.svg';
import DarkTheme from '@/asset/image/dark-theme.svg';
import contact from '@/asset/image/contact.svg';
import qrcode from '@/asset/image/ser_qrcode.png';
import logo1 from '@/asset/image/logo1.jpg';
import logo2 from '@/asset/image/logo2.png';
import MenuNew from '@/asset/image/new.png';
import MenuTest from '@/asset/image/test.png';

// import { localMenu } from './localMenu';

const { Header, Content, Sider, Footer } = Layout;
const { SubMenu } = Menu;

const noFooterArr = ['material_library_ckt'];

class DefaultLayout extends React.Component {
  state = {
    collapsed: window.g_cache.getItem('isSidebar') ? true : false,
    language: 'zh',
    is_show: '0',
    menu: [],
    selectedKeys: [],
    openKeys: _.split(localStorage.getItem('openKeys'), ',') || '',
    init: false,
    theme: localStorage.getItem('theme') || 'light',
    currentUser: window.g_cache.getItem('currentUser') || '',

    service: false,
    menus: window.g_cache.getItem('scrm_menu') || [],
    version: 'scrm',
    column: window.g_cache.getItem('column') || '1',
  };
  componentDidMount() {
    const { location } = this.props;
    if (location.pathname === '/login') {
      return <>{this.props.children}</>;
    } else {
      this.fetchMenu();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var key = ApiConfig.appId + '_pull_msg_token_' + ApiConfig.debug;
    var access_token = appbase.getCookie(key);
    // if(!access_token) return false
    const allowJump = localStorage.getItem('allowJump');

    document.title = nextProps.location.state?.title
      ? `抖音助手-${nextProps.location.state?.title}`
      : '抖音助手';
    if (!access_token && nextProps.location.pathname === '/') {
      nextProps.history.push('/logout');
    }
    if (!access_token) {
      localStorage.clear();
      sessionStorage.clear();
    } else {
      if (allowJump != 1) {
        const system = localStorage.getItem('system');
        if (system == 1 || system == 2) {
          localStorage.setItem('allowJump', 1);
        } else {
          localStorage.setItem('allowJump', 0);
          window.location.href = '/home';
        }
      }
    }

    const { location } = nextProps;
    if (location.pathname === '/home' && !prevState.currentUser) {
      return {
        openKeys: _.split(localStorage.getItem('openKeys'), ','),
        selectedKeys: location.pathname,
        currentUser: window.g_cache.getItem('currentUser'),
        menus: window.g_cache.getItem('scrm_menu') || [],
        collapsed: window.g_cache.getItem('isSidebar') ? true : false,
      };
    }
    return null;
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onOpenChange = keys => {
    const { openKeys } = this.state;
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    const menu = window.g_cache.getItem('scrm_menu');
    if (menu.indexOf(latestOpenKey) === -1) {
      this.setState(
        {
          openKeys: keys,
        },
        () => {
          localStorage.setItem('openKeys', this.state.openKeys);
        }
      );
    } else {
      this.setState(
        {
          openKeys: latestOpenKey ? [latestOpenKey] : [],
        },
        () => {
          localStorage.setItem('openKeys', this.state.openKeys);
        }
      );
    }
  };

  langMenu = key => {
    this.setState({
      language: key.key,
    });
  };

  toggleTheme = () => {
    const theme = localStorage.getItem('theme');
    if (theme == 'light') {
      localStorage.setItem('theme', 'dark');
      this.setState({
        theme: localStorage.getItem('theme'),
      });
    } else {
      localStorage.setItem('theme', 'light');
      this.setState({
        theme: localStorage.getItem('theme'),
      });
    }
  };

  serviceChange = () => {
    this.setState({
      service: !this.state.service,
    });
  };

  linkToLogout = () => {
    this.setState({
      init: false,
    });
    window.location.href = '/pc/logout';
  };

  fetchMenu = async () => {
    const menu = window.g_cache.getItem('scrm_menu');
    if (!menu) {
      let res = await window.g_dyFetch({
        url: window.g_api.get_menu_url,
        method: 'POST',
      });

      const menu = res.info.menu.filter(item => item.name != '首页');
      const openKeys = menu.map(item => item.id);
      localStorage.setItem('openKeys', openKeys.join(','));
      this.setState({
        menus: menu,
        openKeys: openKeys.join(','),
      });
      window.g_cache.setItem('scrm_menu', menu);

      window.g_cache.setItem('scrm_auth', res.info.auth);
      window.g_appbase.setSessionGlobalItem('__access_sauth', JSON.stringify(res.info.auth));

      // window.g_request(
      //   {
      //     url: 'api_new/get_menu',
      //   },
      //   res => {
      //     console.log('ddd');
      //     if (res.code == '0') {
      //       const menu = res.info.menu.filter(item => item.name != '首页');
      //       this.setState({
      //         menus: menu,
      //       });
      //       window.g_cache.setItem('scrm_menu', menu);
      //       const openKeys = menu.map(item => item.id);
      //       localStorage.setItem('openKeys', openKeys);
      //       window.g_cache.setItem('scrm_auth', res.info.auth);
      //       window.g_appbase.setSessionGlobalItem('__access_sauth', JSON.stringify(res.info.auth));
      //     }
      //   }
      // );
    }
  };

  verionSelect = key => {
    if (key.key === 'pt') {
      localStorage.setItem('allowJump', 0);
      window.location.href = '/home';
    }

    this.setState({
      version: key.key,
    });
  };

  cutToggle = () => {
    const column = window.g_cache.getItem('column');
    if (column == 1) {
      window.g_cache.setItem('column', '2');
      this.setState({
        column: window.g_cache.getItem('column'),
      });
    } else {
      window.g_cache.setItem('column', '1');
      this.setState({
        column: window.g_cache.getItem('column'),
      });
    }
  };

  onSiderMenuClick = ({ item }) => {
    this.props.history.push(item.props.link, { title: item.props.linkname });
  };

  render() {
    const code = this.props.location.pathname;
    if (code.split('&').includes('/qrcode')) {
      var access_key = window.g_api.appId + '_pull_msg_token_' + window.g_api.debug;
      appbase.removeCookie(access_key);
      appbase.removeCookie(window.g_api.appId + '_pull_msg_user_range_' + window.g_api.debug);
      // return null
    }
    const { language, openKeys, theme, currentUser, menus, column } = this.state;
    let logo_url = this.props.logo_url;
    logo_url = logo_url ? logo_url : appbase.getSessionGlobalItem('__access_logo');
    let logo_path = logo_url ? logo_url : './images/default_logo.png';
    const checkMenu = menus && _.flattenDeep(menus.map(item => item._data.map(aa => aa.mca_name)));
    const noLayoutPathnames = [
      '/login',
      '/qrcode',
      '/article-show',
      '/Analytics',
      '/middlepage',
      '/configuration',
      '/cloose-version',
      '/test-page',
    ];
    const navPath = [
      '/home',
      '/logout',
      '/pc/login',
      '/pc',
      '/401',
      '/user-center',
      '/cloose-version',
    ];
    const company = window.g_cache.getItem('company');

    let messages = {};
    messages['en'] = en_US;
    messages['zh'] = zh_CN;

    const system = localStorage.getItem('system');
    const isHeader = sessionStorage.getItem('isHeader');

    return (
      // <ConfigProvider locale={en_US}>
      <IntlProvider locale={language} messages={messages[language]}>
        {noLayoutPathnames.indexOf(this.props.location.pathname) === -1 ? (
          <div
            className={classnames(
              styles.layout,
              theme == 'dark' ? styles.dark_theme : styles.white_theme
            )}
          >
            <Layout>
              {!isHeader ? (
                <Header className={styles.header}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={styles.logo}>
                      <img src={theme === 'light' ? logo1 : logo2} alt="" />
                      <Divider type="vertical" className={styles.ant_divider} />
                      <span className={styles.logo_company}>{company}</span>
                      <Divider type="vertical" />
                    </div>
                  </div>

                  <div className={styles.brand}>
                    <div className={styles.brand_left}>
                      <Divider type="vertical" />
                      <div
                        className={classnames(styles.theme, styles.header_item)}
                        onClick={this.toggleTheme}
                      >
                        <img
                          style={{ width: 22, cursor: 'pointer', marginBottom: 5 }}
                          src={theme != 'light' ? LightTheme : DarkTheme}
                          alt=""
                        />
                      </div>
                    </div>
                    <Divider type="vertical" />
                    <div className={styles.brand_right}>
                      <div className={classnames(styles.version, styles.header_item)}>
                        {system != '2' ? (
                          <span>抖音助手</span>
                        ) : (
                          <Dropdown
                            overlay={
                              <Menu
                                onClick={this.verionSelect}
                                selectable
                                defaultSelectedKeys="scrm"
                              >
                                <Menu.Item style={{ padding: '20px 20px' }} key="scrm">
                                  <div
                                    className={styles.version_info}
                                    style={{ textAlign: 'center' }}
                                  >
                                    <p>抖音助手</p>
                                    <span style={{ fontSize: 12, color: '#999' }}>
                                      专注营销管理,客户画像,SOP管理
                                    </span>
                                  </div>
                                </Menu.Item>
                                <Menu.Item style={{ padding: '20px 20px' }} key="pt">
                                  <div
                                    className={styles.version_info}
                                    style={{ textAlign: 'center' }}
                                  >
                                    <p>赢在销客聊天存档</p>
                                    <span style={{ fontSize: 12, color: '#999' }}>
                                      专注聊天存档管理,记录监管,关键词提醒
                                    </span>
                                  </div>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <div className="ant-dropdown-link">
                              {this.state.version == 'scrm' ? '赢在销客SCRM版' : '赢在销客聊天存档'}{' '}
                              <DownOutlined />
                            </div>
                          </Dropdown>
                        )}
                      </div>
                      <Dropdown
                        className={styles.H_dropdown}
                        placement="bottomLeft"
                        overlay={
                          <Menu onClick="">
                            <Menu.Item key="userCenter">
                              <Link
                                to={{
                                  pathname: '/userCenter',
                                  state: { title: '个人中心' },
                                }}
                              >
                                <UserOutlined style={{ fontSize: '12px', marginRight: 5 }} />
                                个人中心
                              </Link>
                            </Menu.Item>
                            <Menu.Item key="logout">
                              <div
                                onClick={this.linkToLogout}
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <LogoutOutlined style={{ fontSize: '12px', marginRight: 5 }} />
                                退出
                              </div>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <div className={styles.name} style={{ cursor: 'pointer' }}>
                          {currentUser}
                          <DownOutlined />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </Header>
              ) : null}

              <Layout id="main">
                <Sider
                  style={{ height: isHeader ? '100vh' : 'calc(100vh - 40px)' }}
                  className={classnames(styles.sider, column == 1 ? 'single_column' : 'double_row')}
                  trigger={null}
                  collapsible
                  collapsed={this.state.collapsed}
                >
                  <Menu
                    mode="inline"
                    selectedKeys={this.props.location.pathname}
                    onOpenChange={this.onOpenChange}
                    defaultOpenKeys={openKeys}
                    openKeys={openKeys}
                    style={{ height: '100%', borderRight: 0 }}
                    theme={theme == 'light' ? 'light' : 'dark'}
                    onClick={this.onSiderMenuClick}
                  >
                    <Menu.Item
                      style={{
                        padding: !this.state.collapsed ? '0 16px 0 24px' : '0 calc(50% - 16px / 2)',
                        marginTop: 10,
                        fontWeight: 500,
                      }}
                      key="/home"
                    >
                      <Link to="/home">
                        <HomeOutlined />
                        <FormattedMessage id="menu.home" tagName="span" />
                      </Link>
                    </Menu.Item>
                    {menus &&
                      menus.map((menuItem, index) => {
                        return menuItem._data.length >= 1 ? (
                          <SubMenu
                            className={styles.submenu}
                            title={
                              <div className={styles.menu_group_title}>
                                {menuItem.ico && (
                                  <span
                                    style={{ marginRight: 10 }}
                                    className={`iconfont ${menuItem.ico}`}
                                  ></span>
                                  // <MyIcon className={styles.icon} type={menuItem.ico} />
                                )}
                                <FormattedMessage id={`menu.${menuItem.name}`} tagName="span" />
                              </div>
                            }
                            key={menuItem.id}
                          >
                            {menuItem._data.map(menuChild => (
                              <Menu.Item
                                className={styles.sub_menu_child}
                                key={menuChild.mca_name}
                                link={menuChild.mca_name}
                                linkname={menuChild.name}
                              >
                                <a>
                                  <FormattedMessage id={`menu.${menuChild.name}`} tagName="span" />
                                  {menuChild.show_new === '1' && (
                                    <i
                                      style={{ color: '#ee502f' }}
                                      className="menu-icon iconfont icon-SCRM-icofont-01"
                                    ></i>
                                  )}
                                  {menuChild.show_new === '2' && (
                                    <i
                                      style={{ color: '#ee502f' }}
                                      className="menu-icon iconfont icon-SCRM-icofont-02"
                                    ></i>
                                  )}
                                </a>
                              </Menu.Item>
                            ))}
                          </SubMenu>
                        ) : (
                          <Menu.Item className={styles.menuItem} key={menuItem.mca_name}>
                            {/* <LegacyIcon type={menuItem.ico} /> */}
                            <span
                              style={{ marginRight: 10 }}
                              className={`iconfont ${menuItem.icon}`}
                            ></span>
                            <span>{menuItem.name}</span>
                          </Menu.Item>
                        );
                      })}
                    <div className={styles.siderFooter}>
                      <Space>
                        {column == 1 ? (
                          <Tooltip title="切换到多列">
                            <MoreOutlined onClick={this.cutToggle} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="切换到单列">
                            <HolderOutlined onClick={this.cutToggle} />
                          </Tooltip>
                        )}
                        <Tooltip title={this.state.collapsed ? '展开' : '收起'}>
                          <LegacyIcon
                            className="trigger icon"
                            type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.toggle}
                          />
                        </Tooltip>
                      </Space>
                    </div>
                  </Menu>
                </Sider>
                <Layout
                  style={{ height: isHeader ? '100vh' : 'calc(100vh - 40px)' }}
                  className={styles.layout_content}
                >
                  <Content className={styles.content}>
                    {navPath.indexOf(this.props.location.pathname) === -1 &&
                      checkMenu.indexOf(this.props.location.pathname) != -1 && (
                        <NavHeader title={this.props.location.state?.title || '列表/管理'} />
                      )}
                    {navPath.indexOf(this.props.location.pathname) === -1 &&
                      checkMenu.indexOf(this.props.location.pathname) === -1 && (
                        <NavHeaderCreate title={this.props.location.state?.title || '列表/管理'} />
                      )}
                    <div
                      className={styles.page_content}
                      style={{
                        height: isHeader
                          ? 'calc(100vh - 40px)'
                          : `calc(100vh - ${
                              navPath.indexOf(this.props.location.pathname) === -1 ? '80px' : '40px'
                            })`,
                      }}
                    >
                      {this.props.children}
                      {/* {noFooterArr.indexOf(this.props.location.pathname != -1) && (
                        <Footer style={{ textAlign: 'center' }}>
                          @{window.g_api.use_edition === 'saas' ? '惠岚科技' : company}
                        </Footer>
                      )} */}
                    </div>

                    <div className={styles.service_action} onClick={this.serviceChange}>
                      <div className={styles.meiqia_box}>
                        <img src={contact} />
                      </div>
                    </div>
                    <div className={styles.help_prompt}>
                      <div
                        className={classnames(
                          styles.index_qr_box_new,
                          !this.state.service ? styles.hide : ''
                        )}
                      >
                        <CloseOutlined className={styles.close} onClick={this.serviceChange} />
                        <div className={styles.content}>
                          <p className={styles.title}>添加专属客服</p>
                          <p className={styles.text_gray}>一对一为您答疑解惑</p>
                          <img src={qrcode} className="qr-img"></img>
                          <p>立即扫码添加我吧！</p>
                        </div>
                        <p className={styles.phone}> 咨询电话：4000-868-208 </p>
                      </div>
                    </div>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          </div>
        ) : (
          this.props.children
        )}
        {/* </ConfigProvider> */}
      </IntlProvider>
    );
  }
}
export default withRouter(DefaultLayout);
