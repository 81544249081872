import React, { useState, useEffect } from 'react';
import {
  Space,
  Table,
  Button,
  message,
  Popconfirm,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
} from 'antd';

import './index.scss';

const { Option } = Select;

function Payment() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([
    {
      id: '1345679',
      name: '活动名称1',
      miniprogram: '关联小程序',
      time: '2023-05-01 12:00',
      read: 10000,
      status: '运行中',
    },
    {
      id: '1345675',
      name: '活动名称2',
      miniprogram: '关联小程序',
      time: '2023-05-01 12:00',
      read: 10000,
      status: '运行中',
    },
    {
      id: '1345674',
      name: '活动名称3',
      miniprogram: '关联小程序',
      time: '2023-05-01 12:00',
      read: 10000,
      status: '运行中',
    },
  ]);
  const [statusList, setStatusList] = useState([{ label: '运行中', value: '运行中' }]);

  const [searchForm] = Form.useForm();

  const columns = [
    {
      title: '活动ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '活动名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '关联小程序',
      dataIndex: 'miniprogram',
      key: 'miniprogram',
    },
    {
      title: '活动阅读量',
      dataIndex: 'read',
      key: 'read',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '更新时间',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setOpen(true);
            }}
          >
            配置
          </Button>
          <Popconfirm
            title="确认删除此条数据?"
            onConfirm={() => {
              let temp = [...data];
              temp.splice(index, 1);
              setData(temp);
            }}
            okText="确认"
            cancelText="取消"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  const initSearchForm = () => {
    return (
      <Form
        layout="inline"
        form={searchForm}
        onFinish={values => {
          console.log(values);
        }}
      >
        <Form.Item label="规则编号" name="name">
          <Input placeholder="请填写规则编号" />
        </Form.Item>
        <Form.Item label="状态" name="status">
          <Select placeholder="请选择状态" allowClear>
            {statusList.map(item => {
              return (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={() => {
              searchForm.resetFields();
            }}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const initDrawer = () => {
    return (
      <Drawer title="新增大转盘" placement="right" onClose={onClose} open={open}>
        <Form
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <Form.Item label="活动名称" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="转盘类型" name="type">
            <Radio.Group>
              <Radio value={'0'}>九宫格</Radio>
              <Radio value={'1'}>圆盘</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Drawer>
    );
  };

  return (
    <div className="payment">
      {initSearchForm()}
      <Button
        type="primary"
        style={{ marginBottom: '10px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        新增
      </Button>
      <Table columns={columns} dataSource={data} rowKey="id" />
      {initDrawer()}
    </div>
  );
}

export default Payment;
