import React from 'react'
import { Spin, Alert } from 'antd';
import './index.scss'



export default () => {

  return <div className='spin'>
    <Spin tip="Loading..." />
  </div>
}