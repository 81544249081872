import React, { useState, useEffect } from 'react';
import {
  Space,
  Table,
  Button,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Steps,
  Select,
  message,
  Row,
  Col,
  Timeline,
} from 'antd';
import moment from 'moment';

import './index.scss';

const { Option } = Select;

function ClueVisitor() {
  const [open, setOpen] = useState(false);
  const [currItemInfo, setCurrItemInfo] = useState({});
  const [miniAppData, setMiniAppData] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(async () => {
    await getMiniAppData();
    await getTableData();
  }, []);

  const [followUpStatusList] = useState([
    { title: '未沟通', label: '未沟通', key: 0, value: 0 },
    { title: '可跟进', label: '可跟进', key: 1, value: 1 },
    { title: '有意向', label: '有意向', key: 2, value: 2 },
    { title: '无意向', label: '无意向', key: 3, value: 3 },
    { title: '已签约', label: '已签约', key: 4, value: 4 },
  ]);

  const [typeList] = useState([
    {
      label: '标准留资',
      value: '1',
    },
    {
      label: '优惠券',
      value: '2',
    },
    {
      label: '小额支付',
      value: '3',
    },
  ]);

  const [searchForm] = Form.useForm();

  const columns = [
    {
      title: '用户openId',
      dataIndex: 'openid',
      key: 'openid',
    },
    {
      title: '访问活动',
      dataIndex: 'active_name',
      key: 'active_name',
    },
    {
      title: '访问时间',
      dataIndex: 'add_time',
      key: 'add_time',
      render: (_, { add_time }) => {
        return <span>{window.g_getDayTime(add_time)}</span>;
      },
    },
    {
      title: '归属达人',
      dataIndex: 'item_aweme_name',
      key: 'item_aweme_name',
      render: (_, row, index) => {
        return <span>{row.item_aweme_name}</span>;
      },
    },
    {
      title: '地区',
      dataIndex: 'address',
      key: 'address',
      render: (_, row, index) => {
        return <span>{row.address === 'Array-Array' ? '-' : row.address}</span>;
      },
    },
    {
      title: '浏览次数',
      dataIndex: 'vist_sum',
      key: 'vist_sum',
    },
    {
      title: '浏览总时长(秒)',
      dataIndex: 'click_time',
      key: 'click_time',
    },
    {
      title: '留资状态',
      dataIndex: 'is_add_message',
      key: 'is_add_message',
      render: (_, { is_add_message }) => (
        <>
          {is_add_message === 0 ? (
            <span>未留资</span>
          ) : is_add_message === 1 ? (
            <span>已留资</span>
          ) : (
            <span></span>
          )}
        </>
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              lookDetail(record);
            }}
          >
            详情
          </Button>
        </Space>
      ),
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  const lookDetail = async item => {
    try {
      let { data } = await window.g_dyFetch({
        url: window.g_api.get_vist_info_url,
        method: 'GET',
        body: {
          history_id: item.id,
        },
      });
      setCurrItemInfo({
        ...data.message,
        active_name: data.active_name,
        vist: data.vist,
        vist_num: data.vist_num,
      });
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  // 获取小程序列表 - 查询参数用
  const getMiniAppData = async () => {
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_app_list_url,
      method: 'GET',
    });
    setMiniAppData(data);
    if (data.length) {
      searchForm.setFieldValue('app_id', data[0].id);
      searchForm.setFieldValue('type', '1');
      await getActivity('1');
    }
  };

  // 获取列表数据
  const getTableData = async () => {
    let searchInfo = searchForm.getFieldValue();

    let { data } = await window.g_dyFetch({
      url: window.g_api.get_vist_url,
      method: 'GET',
      body: {
        page: page,
        pagesize: size,
        app_id: searchInfo.app_id,
        active_id: searchInfo.active_id,
      },
    });

    setTableData(data.list);
    setTotal(Number(data.total));
  };

  // 设置跟进状态
  const setFollowUpStatus = async ({ key }, record) => {
    let formData = new FormData();
    formData.append('message_id', record?.id || currItemInfo.id);
    formData.append('follow_status', key);

    let { msg, code } = await window.g_dyFetch({
      url: window.g_api.dy_message_update_status_url,
      method: 'POST',
      body: formData,
    });

    if (code === 0) {
      setCurrItemInfo({ ...currItemInfo, follow_status: key });
      getTableData();
    }
    message.success(msg);
  };

  // 获取活动列表
  const getActivity = async value => {
    let searchInfo = searchForm.getFieldValue();
    const {
      data: { list },
    } = await window.g_dyFetch({
      url: window.g_api.dy_list_active_url,
      method: 'GET',
      body: {
        pagesize: 999,
        app_id: searchInfo.app_id,
        type: value,
      },
    });
    setActivityList(list);
    if (list.length) {
      searchForm.setFieldValue('active_id', list[0].id);
    }
  };

  const searchTableDataFun = async values => {
    setPage(1);
    setSize(size);
    getTableData();
  };

  const pageChange = event => {
    console.log('event:', event);
    setPage(event.current);
    setSize(event.pageSize);
    getTableData();
  };

  const initSearchForm = () => {
    return (
      <div className="chunk-view">
        <Form
          labelCol={{ span: 6 }}
          form={searchForm}
          onFinish={values => {
            searchTableDataFun(values);
          }}
        >
          <Row gutter={15}>
            <Col span={6}>
              <Form.Item label="小程序" name="app_id">
                <Select placeholder="请选择活动关联小程序">
                  {miniAppData.map(item => {
                    return (
                      <Option value={item.id} key={item.app_id}>
                        {item.app_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="活动类型" name="type">
                <Select placeholder="请选择选择活动类型" onChange={getActivity}>
                  {typeList.map(item => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="活动名称" name="active_id">
                <Select placeholder="请选择活动">
                  {activityList.map(item => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {item.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  const initDrawer = () => {
    return (
      <Drawer width="60%" title="访客详情" placement="right" onClose={onClose} open={open}>
        <Steps
          current={Number(currItemInfo.follow_status)}
          style={{ marginBottom: '40px', marginTop: '30px' }}
          items={followUpStatusList}
        />
        <Descriptions>
          <Descriptions.Item label="线索名称">{currItemInfo.name}</Descriptions.Item>
          <Descriptions.Item label="手机号">{currItemInfo.mobile}</Descriptions.Item>
          <Descriptions.Item label="所属地区">
            {currItemInfo.address !== 'Array-Array' ? currItemInfo.address : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="咨询时间">{currItemInfo.add_time}</Descriptions.Item>
          <Descriptions.Item label="活动来源">{currItemInfo.active_name}</Descriptions.Item>
          <Descriptions.Item label="跟进状态">
            {
              followUpStatusList.filter(item => {
                return item.value === Number(currItemInfo.follow_status);
              })[0]?.title
            }
          </Descriptions.Item>
          <Descriptions.Item label="跟进时间">
            {currItemInfo.update_time === '0' ? '未沟通' : currItemInfo.update_time}
          </Descriptions.Item>
          <Descriptions.Item label="浏览次数">{currItemInfo.vist_num}</Descriptions.Item>
          {currItemInfo.history && (
            <Descriptions.Item label="浏览时长">
              {currItemInfo.history.click_time}秒
            </Descriptions.Item>
          )}
          <Descriptions.Item label="浏览终端">{currItemInfo.device}</Descriptions.Item>
        </Descriptions>
        {currItemInfo.vist && (
          <>
            <div style={{ marginBottom: 20, fontSize: 18, fontWeight: 'bold' }}>访问轨迹:</div>
            <Timeline>
              {currItemInfo.vist.map(item => {
                return (
                  <Timeline.Item>
                    {moment(Number(item.add_time) * 1000).format('YYYY/MM/DD HH:mm')} 访问活动：
                    {item.active_name}｜访问时长：
                    {item.click_time}秒
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </>
        )}
      </Drawer>
    );
  };

  return (
    <div className="clue-consult">
      {initSearchForm()}
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          pageSize: size,
          defaultCurrent: 1,
          current: page,
          total: total,
          showSizeChanger: false,
        }}
        onChange={pageChange}
        rowKey="id"
      />

      {initDrawer()}
    </div>
  );
}

export default ClueVisitor;
