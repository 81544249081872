/*
 * @Author: zhao
 * @Date: 2021-05-19 19:31:22
 * @LastEditTime: 2021-12-13 10:02:18
 * @LastEditors: Please set LastEditors
 * @Description: 
 */
import React, { PureComponent } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin } from 'antd';

import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import comRequest from '../../api/comrequest';
import appbase from '../../js/appbase';
import './index.css'

const FormItem = Form.Item
class Logout extends PureComponent {
  state = {

  }
  componentDidMount() {
    var access_key = ApiConfig.appId + "_pull_msg_token_" + ApiConfig.debug;
    appbase.removeCookie(access_key);
    appbase.removeCookie(ApiConfig.appId + "_pull_msg_user_range_" + ApiConfig.debug);
    localStorage.clear();
    sessionStorage.clear();


    // appbase.removeSessionGlobalItem("__access_smenu","");
    // localStorage.removeItem('openKeys',"")
    // appbase.removeSessionGlobalItem("_access_isExpired");
    // appbase.removeSessionGlobalItem("_access_isAdmin");
    // window.g_cache.removeItem('currentUser')
    // appbase.removeSessionGlobalItem("__access_truename");
    this.props.history.push('/login');
  }

  render() {
    return (
      <div className="login_loading">

        <Spin size="large" />
      </div>)
  }
}

export default Form.create()(Logout);
