import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Form, Select, Row, Col } from 'antd';

import './index.scss';
const { Option } = Select;

function AuthUser() {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [miniprogramList, setMiniprogramList] = useState([]);
  const [authUserList, setAuthUserList] = useState([]);
  const [searchForm] = Form.useForm();

  const initSearchForm = () => {
    return (
      <Form
        layout="inline"
        form={searchForm}
        onFinish={values => {
          searchTableDataFun(values);
        }}
      >
        <Form.Item label="选择小程序" name="authorization_appid">
          <Select
            placeholder="请选择绑定的小程序"
            style={{ width: '200px' }}
            onChange={() => {
              searchForm.setFieldsValue({
                aweme_id: null,
              });
              getAuthUserData();
            }}
          >
            {miniprogramList.map(item => {
              return (
                <Option value={item.app_id} key={item.app_id}>
                  {item.app_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="选择达人" name="aweme_id">
          <Select placeholder="请选择达人" style={{ width: '200px' }}>
            {authUserList.map(item => {
              return (
                <Option value={item.aweme_id} key={item.aweme_id}>
                  {item.user_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={() => {
              searchForm.resetFields();
              getTableData();
            }}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const searchTableDataFun = async values => {
    setPage(1);
    setSize(size);
    getTableData();
  };

  const columns = [
    {
      title: '视频标题',
      dataIndex: 'item_title',
      key: 'item_title',
      width: 540,
    },
    {
      title: '视频封面',
      dataIndex: 'item_cover',
      key: 'item_cover',
      width: 100,
      render: (_, record, index) => (
        <img style={{ width: 60, height: 60 }} src={record.item_cover} />
      ),
    },
    {
      title: '时长',
      width: 100,
      dataIndex: 'item_duration',
      key: 'item_duration',
      render: (_, record, index) => <span>{record.item_duration}秒</span>,
    },

    {
      title: '达人信息',
      dataIndex: 'item_aweme_info',
      key: 'item_aweme_info',
      width: 200,
      render: (_, record, index) => (
        <>
          <div>昵称：{record.item_aweme_name}</div>
          <div>抖音：{record.item_aweme_shortid}</div>
          <div>
            头像： <img style={{ width: 60, height: 60 }} src={record.item_aweme_avatar} />
          </div>
        </>
      ),
    },
    {
      title: '短视频统计',
      dataIndex: 'mp_drainage_pv',
      key: 'mp_drainage_pv',
      render: (_, record, index) => (
        <>
          <div>完播率：{record.item_completion_rate}</div>
          <div>评论次数：{record.item_comment_count}</div>
          <div>点赞次数：{record.item_like_count}</div>
          <div>分享次数：{record.item_share_count}</div>
          <div>播放次数：{record.item_vv}</div>
        </>
      ),
    },
    {
      title: '小程序统计',
      dataIndex: 'mp_drainage_pv',
      key: 'mp_drainage_pv',
      render: (_, record, index) => (
        <>
          <div>曝光次数：{record.mp_show_pv}</div>
          <div>进入次数：{record.mp_drainage_pv}</div>
          <div>进入人数：{record.mp_drainage_uv}</div>
        </>
      ),
    },
    {
      title: '发布时间',
      dataIndex: 'item_create_time',
      key: 'item_create_time',
      width: 160,
      render: (_, record, index) => <span>{record.item_create_time}</span>,
    },
  ];

  useEffect(async () => {
    await getMiniprogramList();
  }, []);

  useEffect(async () => {
    await getTableData();
  }, [page]);

  // 获取达人数据
  const getAuthUserData = async () => {
    let searchInfo = searchForm.getFieldValue();
    const info = searchInfo.authorization_appid
      ? {
          authorization_appid: searchInfo.authorization_appid,
        }
      : {};
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_auth_user_list_url,
      method: 'GET',
      body: {
        page: 1,
        pagesize: 999,
        ...info,
      },
    });
    setAuthUserList(data.list);
  };

  const getTableData = async () => {
    let searchInfo = searchForm.getFieldValue();
    const info = searchInfo.aweme_id
      ? {
          aweme_id: searchInfo.aweme_id,
        }
      : {};
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_auth_video_list_url,
      method: 'GET',
      body: {
        page,
        pagesize: size,
        ...info,
      },
    });
    setTableData(data.list);
    setTotal(Number(data.total_count));
  };

  const getMiniprogramList = async () => {
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_app_list_url,
      method: 'GET',
    });
    setMiniprogramList(data);
  };

  return (
    <div className="auth-user">
      <Row className="chunk-view">
        <Col span={22}>{initSearchForm()}</Col>
      </Row>

      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="id"
        pagination={{
          total,
          onChange: (page, pageSize) => {
            setPage(page);
          },
        }}
      />
    </div>
  );
}

export default AuthUser;
