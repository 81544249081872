/*
 * @Author: zhao
 * @Date: 2021-09-09 17:38:54
 * @LastEditTime: 2022-01-06 11:00:45
 * @LastEditors: Please set LastEditors
 * @Description: 
 */

function cache(namespace) {
  return {
    getItem(key, defaultValue = null) {
      try {
        const valueWithExpire = localStorage.getItem(`${namespace}_${key}`);
        const { value } = JSON.parse(valueWithExpire);
        return value;
      } catch (e) {
        return defaultValue;
      }
    },
    setItem(key, value) {
      return localStorage.setItem(`${namespace}_${key}`, JSON.stringify({ value }));
    },
    removeItem(key) {
      return localStorage.removeItem(`${namespace}_${key}`);
    }
  }
}

/* global APP_SIGN */
window.g_cache = cache('cache')
