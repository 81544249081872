/**
 * 客户列表
 */

import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useUpdateEffect } from 'ahooks'
import { CaretDownOutlined, DownOutlined, SyncOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Table, Dropdown, Menu, Avatar, message, Alert, Tooltip, Select, Modal, Space, Form, Input, PageHeader, Popconfirm, Radio } from 'antd';
import { useSingleState } from 'nice-hooks'
import { Link } from 'react-router-dom'
import qs from 'qs'
import Pagination from '@/component/FormItem/pagination'
import filterSearch from '@/component/FilterProps/filterSearch'
import filterSelectSearch from '@/component/FilterProps/filterSelectSearch'
import Label from '@/component/FormItem/Label'
import LabelAction from '@/component/FormItem/LabelAction'
import filterDateRangeProps from '@/component/FilterProps/filterDateRangeProps';
import Empty from '@/component/Empty'
import UserModal from '@/component/FormItem/UserModal'
import ExportModal from '@/component/Modal/ExportModal'
import Color from '../../component/FormItem/Color';
import DatePickerModule from '@/component/FormItem/DatePicker'
import CustomerInfo from '@/component/tablelist/customerInfo'

import './customer-list.scss'

const { Option } = Select;

function AddFollowUpModal({
  visible,
  list,
  onClose,
  userid
}) {

  function onSubmit() {
    form.validateFields().then((values) => {
      if (!values?.status || values?.status === 1) {
        window.g_request({
          url: 'customer/add_visit',
          body: {
            time: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            content: values.content,
            id: userid
          }
        }, (res) => {
          if (res.code == 0) {
            message.success(res.msg)
            form.resetFields()
            onClose()
          }
        })
      } else {
        if (!dayjs(values.plan_time).isBefore(dayjs(values.plan_end_time))) {
          message.warn('开始时间不能大于结束时间')
          return false
        }
        window.g_request({
          url: '/api/FollowUp/add_plan',
          body: {
            follow_id: userid,
            title: values.title,
            type: values.type,
            plan_time: values.plan_time,
            plan_end_time: values.plan_end_time,
            content: values.content,
            reminder_time: values.reminder_time
          }
        }, (res) => {
          if (res.code == 0) {
            message.success(res.msg)
            form.resetFields()
            onClose()
          } else {
            message.warn(res.msg)
          }
        })
      }
    })
  }

  const [form] = Form.useForm()
  return <Modal
    title='添加跟进'
    onCancel={onClose}
    destroyOnClose
    visible={visible}
    onOk={onSubmit}
    bodyStyle={{
      padding: '24px 24px 24px 0'
    }}
  >
    <Form form={form} className='ant_form' initialValues={{
      status: 1,
    }}>
      {
        list.length > 0 ? <>
          <Form.Item name='status' shouldUpdate label='跟进类型' rules={[{ required: true, message: '跟进类型必填' }]}>
            <Radio.Group>
              <Radio value={1}>跟进记录</Radio>
              <Radio value={2}>跟进计划</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.status !== curValues.status}>
            {({ getFieldValue }) =>
              getFieldValue('status') === 2 ? <>
                <Form.Item name='title' label='计划标题' rules={[{ required: true, message: '计划标题必填' }]}>
                  <Input showCount maxLength={10} placeholder='请输入标题' />
                </Form.Item>
                <Form.Item name='type' label='计划类型' rules={[{ required: true, message: '计划类型必填' }]}>
                  <Select>
                    {
                      list.map(item => (
                        <Option value={item.id}>{item.name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item name='reminder_time' label='计划提醒' rules={[{ required: true, message: '计划提醒必填' }]}>
                  <Select>
                    {
                      [{ key: -1, title: '不提醒' },
                      { key: 0, title: '事件开始时' },
                      { key: 300, title: '事件开始前5分钟' },
                      { key: 900, title: '事件开始前15分钟' },
                      { key: 3600, title: '事件开始前1小时' },
                      { key: 86400, title: '事件开始前1天' }].map(item => (
                        <Option value={item.key}>{item.title}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item name='plan_time' label='开始时间' rules={[{ required: true, message: '开始时间必填' }]}>
                  <DatePickerModule
                    showTime={{
                      minuteStep: 15
                    }}
                    isDisabledDate={false}
                    isDisabledTime={false}
                  />
                </Form.Item>
                <Form.Item name='plan_end_time' label='结束时间' rules={[{ required: true, message: '结束时间必填' }]}>
                  <DatePickerModule
                    showTime={{
                      minuteStep: 15
                    }}
                    isDisabledDate={false}
                    isDisabledTime={false}
                  />
                </Form.Item>
                <Form.Item name='content' label='备注' rules={[{ required: true, message: '备注必填' }]}>
                  <Input.TextArea showCount maxLength={100} placeholder='请输入要添加的备注信息吧，添加成功后将出现在客户动态列表里~' />
                </Form.Item>
              </> : <Form.Item name='content' label='备注' rules={[{ required: true, message: '备注必填' }]}>
                <Input.TextArea showCount maxLength={100} placeholder='请输入要添加的备注信息吧，添加成功后将出现在客户动态列表里~' />
              </Form.Item>
            }
          </Form.Item>
        </> : <Form.Item name='content' label='备注' rules={[{ required: true, message: '备注必填' }]}>
          <Input.TextArea showCount maxLength={100} placeholder='请输入要添加的备注信息吧，添加成功后将出现在客户动态列表里~' />
        </Form.Item>
      }
    </Form>
  </Modal>
}

function CustomerList({
  history
}) {
  const query = qs.parse(history.location?.search?.slice(1));
  const [list, set_list] = useState([])
  const [listTotal, set_listTotal] = useState(0)
  const [loading, set_loading] = useState(false)

  const [filters, set_filters] = useState('')
  const [sortedInfo, set_sortedInfo] = useState(sortedInfo || {})
  const [selectedRowKeys, set_selectedRowKeys] = useState([])
  const [tagAction, set_tagAction] = useState(false)
  const [type, set_type] = useState() //打标签type
  const [chainList, set_chainList] = useState([])
  const [chainId, set_chainId] = useState([])
  const [followUpData, set_followUpData] = useState([])
  const [followUpModalVisible, set_followUpModalVisible] = useState(false)
  const [followUpUserId, set_followUpUserId] = useState(null)


  const [state, setState] = useSingleState({
    allUsersTree: [], // 所有用户的数组
    allUsersMap: {}, // 所有用的的map
    userSelectModalVisible: false, // 弹窗显示状态
    selectedUserIds: [], // 当前弹窗的值
    labels: [],
    labelloading: false,
    scrollPage: 1,
    pageSize: 15,
    userDatas: [],
    page: {
      current: 1,
      pageSize: 15
    },
    spinloading: false,
    alert: {
      status: false,
      msg: '',
      startStatus: false
    },

    exportVisible: false,
    exportLoading: true,
    exportList: [],

    allLabels: [], // 所有标签
    labelSelectVisible: false, // 标签弹窗显示状态
    labelSelectLoading: false, // 标签弹窗加载状态
    labelSelectedData: [], // 当前弹窗选中的值

    followUpConfigModalVisible: false,
    isSelectedAll: false
  });

  useEffect(() => {
    window.g_request({
      url: 'customer/check_secret',
      body: {
        status: 1,
      }
    }, (res) => {
      if (res.code != 0) {
        setState({
          alert: {
            status: true,
            msg: res.msg
          }
        })
      }
    })
    fetchFollowUpConfigList()
  }, [])

  function fetchFollowUpConfigList() {
    window.g_request({
      url: '/api/FollowUp/get_config_list'
    }, (res) => {
      if (res.code == 0) {
        set_followUpData(res.data)
      }
    })
  }

  useEffect(() => {
    fetchData()
    setState({isSelectedAll: false})
    set_selectedRowKeys([])
  }, [filters, state.selectedUserIds, sortedInfo, state.page, state.labelSelectedData, chainId, query?.trenchid])

  useEffect(() => {
    fetchAllUsers()
    getAllCode(state.page.current, state.pageSize)
  }, [])

  useEffect(() => {
    window.g_request({
      url: '/api/Agency/get_list',
      body: {
        pageno: 1,
        limit: 10000,
        mandate: 1
      }
    }, (res) => {
      if (res.code == 0) {
        set_chainList(res.data.data)
      }
    })
  }, [])

  // 渠道类型 获取code
  function getAllCode(current, pageSize) {
    window.g_appbase.get_app_token((token) => {
      let formData = new FormData()
      formData.append('p', current || '1')
      formData.append('per', pageSize || '15')
      formData.append('is_addressbook', 1)
      formData.append('department_id', state.selectedKeys || '')
      formData.append('keyword', filters.code_name || '')
      window.g_postFetch(window.g_api.get_all_code + '?token=' + token, formData, ((res) => {
        setState({
          userDatas: [...state.userDatas, ...res.data.all_code]
        })
      }))
    })
  }

  function fetchAllUsers() {
    window.g_request({
      url: 'user_new/get_department_list',
      body: {
        type: ''
      },
    }, (res) => {
      const data = res.data
      const allUsersMap = {};
      const handleData = (data) => {
        let result = [];
        for (const item of data) {
          let children = [];
          if (item.children && item.children.length > 0) {
            children = handleData(item.children)
          }
          // 
          item.user.forEach(user => {
            if (allUsersMap[user.id]) {
              allUsersMap[user.id].departments.push(item.key)
            } else {
              allUsersMap[user.id] = {
                title: user.title,
                avatar: user.avatar,
                departments: [item.key],
                userid: user.userid
              }
            }
          })
          // 
          result.push({
            title: item.title,
            type: 'department',
            key: `department:${item.key}`,
            id: item.key,
            children: [
              ...children,
              ...item.user.map(user => ({
                title: user.title,
                type: 'user',
                key: `department:${item.key}:user:${user.id}`,
                id: user.id,
              }))
            ]
          })
        }
        return result;
      }
      // 预处理所有用户
      const allUsersTree = handleData(data);
      // console
      setState({ allUsersTree: allUsersTree, allUsersMap: allUsersMap });
    })
  }

  function fetchLabel() {
    setState({
      labelSelectLoading: true
    })
    let formData = new FormData()
    formData.append('type', '')
    window.g_request({
      url: 'label/index_label',
      type: 'old',
      body: formData,
    }, (res) => {
      setState({
        labels: res.data.data,
        labelSelectLoading: false,
        allLabels: res.data.data
      })
    })
  }

  function fetchData(isRefresh) {
    if (loading) return;
    set_loading(true)
    window.g_request({
      url: 'customer/index',
      body: {
        key_word: filters.external_truename,
        user_id: state.selectedUserIds,
        label_array: state.labelSelectedData.map(item => item.code),
        per: state.page.pageSize,
        p: state.page.current,
        refresh: isRefresh ? 1 : '',
        order: sortedInfo.order === "ascend" ? 'asc' : 'desc',
        add_way: filters.add_way,
        create_time: filters.createtime && filters.createtime[0],
        end_time: filters.createtime && filters.createtime[1],
        state: !query?.trenchid ? filters.code_name && `codeid=${filters.code_name || ''}` : `codeid=${query?.trenchid || ''}`,
        agency_id: chainId,
      }
    }, (res) => {
      if (res.code == 0) {
        set_list(res.data.data)
        set_listTotal(res.data.count)
        set_loading(false)
      } else {
        message.error('网络错误或服务器错误')
      }
    })
  }


  const onTableChange = (changePage, changeFilters, sorter) => {
    if (JSON.stringify(filters) !== JSON.stringify(changeFilters)) {
      changePage.current = 1;
    }
    console.log('changePage', changePage)
    setState({
      page: {
        current: changePage.current || 1,
        pageSize: 15
      }
    })
    set_sortedInfo(sorter)
    set_filters(changeFilters)
  }

  function pageChange(page) {
    setState({
      page
    })
  }

  function makeTag(data, callback) {
    console.log(filters)
    window.g_request({
      url: 'customer/customer_label',
      body: {
        follow_user: selectedRowKeys,
        label_array: data.label.map(item => item.code),
        type: type,
        transfer_all: state.isSelectedAll ? 1 : '',
        all_external_user: JSON.stringify({
          key_word: filters?.external_truename || '',
          user_id: state.selectedUserIds.toString() || '',
          label_array: state.labelSelectedData.map(item => item.code).toString() || '',
          add_way: filters?.add_way && filters?.add_way.toString()  || '',
          create_time: filters?.createtime && filters.createtime[0] || '',
          end_time: filters?.createtime && filters.createtime[1] || '',
          state: !query?.trenchid ? filters.code_name && `codeid=${filters.code_name || ''}` || '' : `codeid=${query?.trenchid || ''}`,
          agency_id: chainId.toString() || '',
        })
      }
    }, (res) => {
      if (res.code == 0) {
        set_tagAction(false)
        message.success(res.msg)
        set_selectedRowKeys([])
        fetchData()
        callback(true)
      }
    })
  }

  function tagModal(type) {
    if (selectedRowKeys.length <= 0) {
      message.warning('请先选择一个客户')
      return
    }
    if (!tagAction) {
      set_type(type)
      fetchLabel()
      set_tagAction(true)
    } else {
      set_type()
      set_tagAction(false)
    }
  }


  const showUserSelectModal = () => {
    setState({
      userSelectModalVisible: true, // 弹窗显示状态
    })
  }

  const onUserSelectModalCancel = () => {
    setState({
      userSelectModalVisible: false, // 弹窗显示状态
    })
  }

  const onUserSelectModalSubmit = (selectedUserIds) => {
    setState({ selectedUserIds })
    setState({
      userSelectModalVisible: false, // 弹窗显示状态
      page: {
        current: 1,
        pageSize: 15
      }
    })
  }



  const columns = [
    {
      title: '客户名称',
      width: 300,
      dataIndex: 'external_truename',
      key: 'external_truename',
      arrKey: [0, 1],
      fixed: 'left',
      ...filterSearch({ placeholder: '请输入要搜索的客户名称' }),
      filteredValue: filters.external_truename,
      render: (text, item) => <CustomerInfo
        avatar={item.external_avatar}
        name={item.external_truename}
        type={item.type}
        remark={item.remark}
        corp_name={item.corp_full_name || item.corp_name}
        remark_corp_name={item.remark_corp_name}
      />
    },
    {
      title: '所属员工',
      width: 150,
      dataIndex: 'truename',
      key: 'truename',
      arrKey: [0, 1],
      render: (text, item) => <CustomerInfo
        avatar={item.avatar}
        name={item.truename}
      />
    },
    {
      title: '所属部门',
      width: '320px',
      dataIndex: 'department_arr',
      key: 'department_arr',
      arrKey: [0, 1],
      render: (department_arr) => department_arr ? department_arr.map((item, index) => (
        <div style={{ fontSize: "13px", color: "#333" }}>{item}</div>
      )) : '-'
    },
    {
      title: '标签',
      dataIndex: 'tagname',
      key: 'tagname',
      width: 250,
      arrKey: [0, 1],
      render: (tagname, item, index) => {
        const data = _.split(tagname, '、').filter(Boolean)
        return (
          <div className='tag-content'>
            <div className={`tag-boxs min-height`}>
              {
                data.map((item, index) => (
                  tagname ? <div key={index} className='ant_tag' dangerouslySetInnerHTML={{ __html: item }} /> : '-'
                ))
              }
            </div>
          </div>
        )
      }
    },
    {
      title: '添加时间',
      dataIndex: 'createtime',
      key: 'createtime',
      width: 180,
      arrKey: [0, 1],
      // sortDirections: ['ascend','descend'],
      render: (createtime) => <span>{createtime}</span>,
      sorter: (a, b) => a.createtime - b.createtime,
      sortOrder: sortedInfo.columnKey === 'createtime' && sortedInfo.order,
      ...filterDateRangeProps,
      filteredValue: filters.createtime,
    },
    {
      title: '添加来源',
      dataIndex: 'add_way',
      key: 'add_way',
      width: 200,
      arrKey: [0, 1],
      filters: [
        { value: '0', text: '未知来源' },
        { value: '1', text: '扫描二维码' },
        { value: '2', text: '搜索手机号' },
        { value: '3', text: '名片分享 ' },
        { value: '4', text: '群聊 ' },
        { value: '5', text: '手机通讯录' },
        { value: '6', text: '微信联系人' },
        { value: '7', text: '来自微信的添加好友申请' },
        { value: '8', text: '安装第三方应用时自动添加的客服人员' },
        { value: '9', text: '搜索邮箱' },
        { value: '10', text: '视频号添加' },
        { value: '201', text: '内部成员共享' },
        { value: '202', text: '管理员/负责人分配' },
      ],
      filterMultiple: false,
      filteredValue: filters.add_way || null,
      render: (text) => window.g_source(text),
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
      width: 180,
      arrKey: [0, 1],
      ellipsis: true,
      render: (description) => (
        description ? description : '-'
      )
    },
    {
      title: '渠道来源',
      dataIndex: 'code_name',
      key: 'code_name',
      width: 180,
      arrKey: [0, 1],
      ...filterSelectSearch({ placeholder: '请输入要搜索的渠道来源', userDatas: state.userDatas }),
      filteredValue: filters.code_name,
      render: (code_name) => (
        code_name ? code_name : '-'
        // )<span>{code_name}</span>
      )
    },
    {
      title: '操作',
      key: 'operation',
      fixed: 'right',
      width: 150,
      arrKey: [0],
      render: (text, item) => <div style={{ display: 'flex', alignItems: 'center' }}>
        {
          window.g_checkRole(130) && <Link to={{
            pathname: '/customer-detail',
            state: { title: '客户详情', id: item.id }
          }}>
            <Button type='link'>详情</Button>
          </Link>
        }
        {
          window.g_cache.getItem('currentId') === item.chatuserid && <Button type='link' onClick={() => {
            set_followUpUserId(item.id)
            set_followUpModalVisible(true)
          }}>添加跟进</Button>
        }
      </div>
    },
  ].filter(item => item.arrKey.includes(chainId.length > 0 ? 1 : 0));

  function onSelectChange(selectedRowKeys) {
    const { isSelectedAll, page } = state
    if (isSelectedAll && (selectedRowKeys.length === page.pageSize || selectedRowKeys.length === parseInt(listTotal))) {
      setState({ isSelectedAll: false })
    }
    set_selectedRowKeys(selectedRowKeys)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideDefaultSelections: true,
  }

  function updateCustomer() {
    setState({
      spinloading: true
    })
    window.g_request({
      url: 'customer/add_user_group',
    }, (res) => {
      if (res.code == 0) {
        message.success(res.msg)
        setState({
          spinloading: false
        })
      } else {
        message.warning(res.msg)
        setState({
          spinloading: false
        })
      }
    })
  }

  function onLabelSelectModalCancel() {
    setState({
      labelSelectVisible: false
    })
  }
  function showLabelSelectModal() {
    setState({
      labelSelectVisible: true,
    }, fetchLabel)
  }
  function onLabelSelectModalSubmit(selectedLabels) {
    setState({
      labelSelectedData: selectedLabels,
      page: {
        current: 1,
        pageSize: 15,
      }
    })
    onLabelSelectModalCancel()
  }


  //导出数据
  function exportData() {
    window.g_request({
      url: '/api/CustomerExport/get_field',
    }, (res) => {
      if (res.code == 0) {
        setState({
          exportLoading: false,
          exportList: res.data
        })
      }
    })
  }

  function showExportModal() {
    if (!state.exportVisible) {
      setState({
        exportVisible: true
      }, exportData)
    }
  }

  function onCancelExportModal() {
    setState({
      exportVisible: false
    })
  }

  function onExportSubmit(checkedValues) {
    const token = window.g_appbase.getCookie(window.g_api.appId + "_pull_msg_token_" + window.g_api.debug)
    window.location.href = `${window.g_api.base_url}/api/CustomerExport/download?${qs.stringify({
      token: token,
      key_word: filters.external_truename || '',
      user_id: state.selectedUserIds,
      label_array: state.labelSelectedData.map(item => item.code),
      order: sortedInfo.order === "ascend" ? 'asc' : 'desc',
      add_way: filters.add_way,
      create_time: filters.createtime && filters.createtime[0],
      end_time: filters.createtime && filters.createtime[1],
      state: filters.code_name && `codeid=${filters.code_name || ''}`,
      agency_id: chainId,
      download_field: checkedValues || ''
    }, { arrayFormat: 'comma' })}`
    onCancelExportModal()
  }

  function onFollowUpConfigModal() {
    setState({
      followUpConfigModalVisible: !state.followUpConfigModalVisible
    })
  }

  return (
    <div className='page-content'>
      {
        state.alert.status && <Alert
          message={state.alert.msg}
          type='error'
        />
      }
      <div className='customer-list'>
        <div className='filter' style={{ justifyContent: 'space-between' }}>
          <div className='flex_row'>
            <div className='filter-item'>
              <span>员工：</span>
              <div className='dropdown-wrap' onClick={showUserSelectModal}>
                <span className="label-wrap">
                  <span className="default-label">
                    <div className="servicer-label">
                      {
                        state.selectedUserIds.length > 0 ? <div className='customer-select-show-view'>
                          {state.selectedUserIds.map(userid => (
                            <div className='staff-item' key={userid}>
                              <img src={state.allUsersMap[userid]?.avatar} alt="" />
                              {state.allUsersMap[userid]?.title}
                            </div>
                          ))}
                        </div> : '请选择员工'
                      }  </div>
                  </span>
                  <span className='clear'></span>
                  <DownOutlined />
                </span>
              </div>
              <span style={{ marginLeft: 10, color: '#999' }}>共{state.selectedUserIds.length}个员工</span>
            </div>
            {
              chainList.length > 0 && <div className='filter-item'>
                <span>上下游企业：</span>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder='请选择上下游企业'
                  optionFilterProp="label"
                  style={{
                    width: '230px',
                  }}
                  maxTagCount={1}
                  onChange={(e) => set_chainId(e)}
                >
                  {chainList.map(item => (
                    <Option value={item.id} label={item.corp_name}>{item.corp_name}</Option>
                  ))}
                </Select>
              </div>
            }
          </div>
          {
            window.g_checkRole(367) && <Button type='link' icon={<SettingOutlined />} onClick={onFollowUpConfigModal}>跟进记录类型配置</Button>
          }
        </div>
        <UserModal
          visible={state.userSelectModalVisible}
          onCancel={onUserSelectModalCancel}
          onOk={onUserSelectModalSubmit}
          value={state.selectedUserIds}
          allUsersMap={state.allUsersMap}
          allUsersTree={state.allUsersTree}
        />
        <Card
          className='page-ant-card'
          title={
            <Space>
              <span>{`共${listTotal}个客户`}</span>
              <span style={{ fontSize: 13, color: '#00000073' }}>已选择{state.isSelectedAll ? '全部' : '本页'}
                {state.isSelectedAll ? listTotal : selectedRowKeys.length}名客户 {
                  listTotal > 0 && (selectedRowKeys.length === state.page.pageSize || selectedRowKeys.length === parseInt(listTotal)) && <span onClick={() => setState({ isSelectedAll: !state.isSelectedAll })} style={{ cursor: 'pointer', color: '#1167b7' }}>，{state.isSelectedAll ? '取消选择' : `选择全部${listTotal}客户`}</span>
                }</span>
              <span
                style={{ fontSize: 13, color: '#00000073', cursor: 'pointer' }}
                onClick={() => fetchData(1)}
              >
                <SyncOutlined spin={state.spinloading} />  更新数据
              </span>
            </Space>
          }
          extra={
            <Space>
              {
                chainId.length === 0 ? <>
                  {
                    window.g_checkRole(128) && <Dropdown.Button
                      icon={<CaretDownOutlined />}
                      trigger={['click']}
                      onClick={() => tagModal('add')}
                      style={{ margin: '0 10px' }}
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => tagModal('del')}>批量移除标签</Menu.Item>
                        </Menu>
                      }>
                      批量打标签
                    </Dropdown.Button>
                  }
                  <Button onClick={showLabelSelectModal}>客户标签筛选</Button>
                  {
                    window.g_checkRole(227) && <Button onClick={updateCustomer}>同步数据</Button>
                  }
                </> : null
              }
              <Button type='primary' onClick={showExportModal} style={{ marginLeft: 10 }}>导出数据</Button>
            </Space>
          }
        >
          <Table
            rowKey={item => item.id}
            columns={columns}
            loading={loading}
            dataSource={list}
            pagination={false}
            rowSelection={rowSelection}
            onChange={onTableChange}
            expandable={{
              expandIconAsCell: false,
              expandIconColumnIndex: -1,
              expandRowByClick: true,
              expandedRowRender: record => {
                const data = _.split(record.tagname, '、').filter(Boolean)
                return (
                  data.map((item, index) => (
                    <div key={index} className='ant_tag' dangerouslySetInnerHTML={{ __html: item }} />
                  )))
              },
              rowExpandable: item => item.tagname
            }}
            scroll={{ x: 1300 }}
            locale={{
              emptyText: <div>
                {!loading && list.length <= 0 && <Empty />}
              </div>
            }}
          />
          <Pagination total={listTotal} onSelect={pageChange} page={state.page} />
          <LabelAction loading={state.labelSelectLoading} visible={tagAction} alllabels={state.labels} labelModal={tagModal} title={type === 'del' ? '批量移除标签' : null} type='' onSubmit={makeTag} />
          <Label
            allLabels={state.allLabels}
            visible={state.labelSelectVisible}
            loading={state.labelSelectLoading}
            onOk={onLabelSelectModalSubmit}
            value={state.labelSelectedData}
            onCancel={onLabelSelectModalCancel}
          />
        </Card>

        <ExportModal
          visible={state.exportVisible}
          exportList={state.exportList}
          loading={state.exportLoading}
          onOk={onExportSubmit}
          onCancel={onCancelExportModal}
        />

        {/* 跟进记录类型配置 */}
        <FollowUpConfig
          onClose={onFollowUpConfigModal}
          visible={state.followUpConfigModalVisible}
          updateData={fetchFollowUpConfigList}
        />
        <AddFollowUpModal
          list={followUpData}
          visible={followUpModalVisible}
          onClose={() => set_followUpModalVisible(false)}
          userid={followUpUserId}
        />
      </div>
    </div>
  );
}

function FollowUpConfig({
  visible,
  onClose,
  updateData
}) {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [formVisible, setFormVisible] = useState(false)
  const [dataForUpdate, setDataForUpdate] = useState(null)

  useUpdateEffect(() => {
    if (!visible) return false
    fetchData()
  }, [visible])

  function fetchData() {
    window.g_request({
      url: '/api/FollowUp/get_config_list'
    }, (res) => {
      if (res.code == 0) {
        setData(res.data)
      }
    })
  }

  function fetchDataDetail(id) {
    window.g_request({
      url: '/api/FollowUp/get_config_data',
      body: { id }
    }, (res) => {
      if (res.code == 0) {
        setFormVisible(true)
        setDataForUpdate(res.data)
        form.setFieldsValue(res.data)
      }
    })
  }

  function onDeleteId(id) {
    window.g_request({
      url: '/api/FollowUp/del_config',
      body: { id }
    }, (res) => {
      if (res.code == 0) {
        message.success(res.msg)
        fetchData()
      } else {
        message.warn(res.msg)
      }
    })
  }
  function onCreateOrUpdate(values) {
    window.g_request({
      url: '/api/FollowUp/config_save',
      body: {
        ...values,
        id: dataForUpdate?.id,
      }
    }, (res) => {
      if (res.code == 0) {
        message.success(res.msg)
        form.resetFields()
        setFormVisible(false)
        fetchData()
      } else {
        message.warn(res.msg)
      }
    })
  }

  function onCancel() {
    form.resetFields()
    setFormVisible(false)
    setDataForUpdate(null)
    onClose()
    updateData()
  }

  const columns = [
    {
      title: '类型名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '颜色',
      dataIndex: 'color',
      key: 'color',
      render: (color) => <span style={{ background: color, width: 30, height: 30, display: 'block' }}></span>
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      render: (time) => dayjs.unix(time).format('YYYY-MM-DD HH:mm')
    },
    {
      title: '创建人',
      dataIndex: 'truename',
      key: 'truename'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (_, item) => <Space>
        <Button type='link' onClick={() => fetchDataDetail(item.id)}>修改</Button>
        <Popconfirm
          title="确定删除嘛?"
          onConfirm={() => onDeleteId(item.id)}
          okText="确定"
          cancelText="取消"
        >
          <Button type='link'>删除</Button>
        </Popconfirm>
      </Space>
    },
  ]
  return <Modal
    visible={visible}
    title='跟进记录类型配置'
    bodyStyle={{
      padding: !formVisible ? '10px' : '24px 24px 24px 0'
    }}
    footer={null}
    onCancel={onCancel}
  >
    {
      !formVisible ? <Button type='primary' onClick={() => setFormVisible(true)} style={{ margin: '10px' }}>新增</Button> :
        <PageHeader
          style={{ padding: '0 24px 16px' }}
          className="site-page-header"
          onBack={() => {
            form.resetFields()
            setFormVisible(false)
          }}
          subTitle="返回列表"
        />
    }
    {
      !formVisible ? <Table dataSource={data} columns={columns} pagination={false} /> :
        <Form
          className='ant_form'
          initialValues={{
            color: '#1890ff'
          }}
          form={form}
          onFinish={onCreateOrUpdate}
        >
          <Form.Item name='name' label='名称' rules={[{ required: true, message: '名称必填' }]}>
            <Input placeholder='请输入名称' showCount maxLength={10} />
          </Form.Item>
          <Form.Item name='color' label='颜色' extra='日程模块颜色'>
            <Color />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type='primary' htmlType="submit">保存</Button>
          </Form.Item>
        </Form>
    }
  </Modal>
}

export default CustomerList