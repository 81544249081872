import React, { useState, useEffect } from 'react';
import {
  Space,
  Table,
  Button,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  Steps,
  Select,
  Dropdown,
  message,
  Row,
  Col,
  Timeline,
} from 'antd';
import moment from 'moment';

import './index.scss';

const { Option } = Select;

const { RangePicker } = DatePicker;

function ClueConsult() {
  const [open, setOpen] = useState(false);
  const [currItemInfo, setCurrItemInfo] = useState({});
  const [miniAppData, setMiniAppData] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(async () => {
    setPage(1);
    await getMiniAppData();
  }, []);

  useEffect(async () => {
    if (page) {
      await getTableData();
    }
  }, [page]);

  const [followUpStatusList] = useState([
    { title: '未沟通', label: '未沟通', key: 0, value: 0 },
    { title: '可跟进', label: '可跟进', key: 1, value: 1 },
    { title: '有意向', label: '有意向', key: 2, value: 2 },
    { title: '无意向', label: '无意向', key: 3, value: 3 },
    { title: '已签约', label: '已签约', key: 4, value: 4 },
  ]);

  const [searchForm] = Form.useForm();

  const columns = [
    {
      title: '线索名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '手机号',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: '地区',
      dataIndex: 'address',
      key: 'address',
      render: (_, row, index) => {
        return <span>{row.address === 'Array-Array' ? '-' : row.address}</span>;
      },
    },
    {
      title: '咨询时间',
      dataIndex: 'add_time',
      key: 'add_time',
    },
    {
      title: '归属达人',
      dataIndex: 'item_aweme_name',
      key: 'item_aweme_name',
      render: (_, row, index) => {
        return <span>{row.item_aweme_name}</span>;
      },
    },
    {
      title: '浏览次数',
      dataIndex: 'vist_num',
      key: 'vist_num',
    },
    {
      title: '浏览时长(秒)',
      render: (_, row, index) => {
        if (row.history) return <span>{row.history.click_time}</span>;
      },
    },
    {
      title: '活动来源',
      dataIndex: 'active_name',
      key: 'active_name',
    },
    {
      title: '跟进状态',
      dataIndex: 'follow_status',
      key: 'follow_status',
      render: (_, { follow_status }) => (
        <>
          {follow_status === '0' ? (
            <span>未沟通</span>
          ) : follow_status === '1' ? (
            <span>可跟进</span>
          ) : follow_status === '2' ? (
            <span>有意向</span>
          ) : follow_status === '3' ? (
            <span>无意向</span>
          ) : follow_status === '4' ? (
            <span>已签约</span>
          ) : (
            <span></span>
          )}
        </>
      ),
    },
    {
      title: '跟进时间',
      dataIndex: 'update_time',
      key: 'update_time',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <Space>
            {window.g_checkRole(21) && (
              <Dropdown
                menu={{
                  items: followUpStatusList,
                  onClick: event => {
                    setFollowUpStatus(event, record);
                  },
                }}
                arrow={{ pointAtCenter: true }}
              >
                <Button type="link">跟进</Button>
              </Dropdown>
            )}
          </Space>
          <Button
            type="link"
            onClick={() => {
              lookDetail(record);
            }}
          >
            详情
          </Button>
        </Space>
      ),
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  const lookDetail = async item => {
    try {
      let { data } = await window.g_dyFetch({
        url: window.g_api.dy_vist_list_url,
        method: 'GET',
        body: {
          message_id: item.id,
        },
      });
      if (item.postdata !== '' && typeof item.postdata === 'string') {
        item.postdata = JSON.parse(item.postdata.replaceAll('&quot;', '"'));
      }
      setCurrItemInfo({ ...item, vist: data.vist, vist_num: data.vist_num });
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  // 获取小程序列表 - 查询参数用
  const getMiniAppData = async () => {
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_app_list_url,
      method: 'GET',
    });
    setMiniAppData(data);
  };

  // 获取列表数据
  const getTableData = async () => {
    let searchInfo = JSON.parse(JSON.stringify(searchForm.getFieldValue()));

    let formData = new FormData();
    for (const key in searchInfo) {
      if (
        searchInfo.hasOwnProperty(key) &&
        searchInfo[key] !== null &&
        typeof searchInfo[key] !== 'undefined'
      ) {
        const element = searchInfo[key];
        if (key === 'range_time') {
          let range_time = searchInfo[key].map(item => {
            return moment(item).format('YYYY-MM-DD');
          });
          console.log('range_time:', range_time);
          formData.append('range_time', range_time.join(','));
        } else {
          formData.append(key, element);
        }
      } else {
        delete searchInfo[key];
      }
    }
    formData.append('page', page);
    formData.append('pagesize', size);

    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_message_list_url,
      method: 'POST',
      body: formData,
    });

    setTableData(data.list);
    setTotal(Number(data.total));
  };

  // 设置跟进状态
  const setFollowUpStatus = async ({ key }, record) => {
    let formData = new FormData();
    formData.append('message_id', record?.id || currItemInfo.id);
    formData.append('follow_status', key);

    let { msg, code } = await window.g_dyFetch({
      url: window.g_api.dy_message_update_status_url,
      method: 'POST',
      body: formData,
    });

    if (code === 0) {
      setCurrItemInfo({ ...currItemInfo, follow_status: key });
      getTableData();
    }
    message.success(msg);
  };

  // 获取活动列表
  const getActivity = async value => {
    const {
      data: { list },
    } = await window.g_dyFetch({
      url: window.g_api.dy_list_active_url,
      method: 'GET',
      body: {
        pagesize: 999,
        app_id: value,
        type: 1,
      },
    });
    setActivityList(list);
  };

  const searchTableDataFun = async values => {
    setPage(1);
    setSize(size);
    getTableData();
  };

  const initSearchForm = () => {
    return (
      <div className="chunk-view">
        <Form
          labelCol={{ span: 5 }}
          form={searchForm}
          onFinish={values => {
            searchTableDataFun(values);
          }}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label="线索名称" name="name">
                <Input placeholder="请填写线索名称" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="选择小程序" name="app_id">
                <Select placeholder="请选择活动关联小程序" allowClear onChange={getActivity}>
                  {miniAppData.map(item => {
                    return (
                      <Option value={item.id} key={item.app_id}>
                        {item.app_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="活动名称" name="active_id">
                <Select
                  placeholder={searchForm.getFieldValue('app_id') ? '请选择活动' : '请先选择小程序'}
                  allowClear
                >
                  {activityList.map(item => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {item.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label="留资时间" name="range_time">
                <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="跟进状态" name="follow_status">
                <Select placeholder="请选择跟进状态" allowClear>
                  {followUpStatusList.map(item => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="操作">
                <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>
                  查询
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    searchForm.resetFields();
                  }}
                >
                  重置
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  const initDrawer = () => {
    return (
      <Drawer
        width="60%"
        title="线索详情"
        placement="right"
        onClose={onClose}
        open={open}
        extra={
          <Space>
            {window.g_checkRole(21) && (
              <Dropdown
                menu={{ items: followUpStatusList, onClick: setFollowUpStatus }}
                arrow={{ pointAtCenter: true }}
              >
                <Button>跟进</Button>
              </Dropdown>
            )}
          </Space>
        }
      >
        <Steps
          current={Number(currItemInfo.follow_status)}
          style={{ marginBottom: '40px', marginTop: '30px' }}
          items={followUpStatusList}
        />
        <Descriptions>
          <Descriptions.Item label="线索名称">{currItemInfo.name}</Descriptions.Item>
          <Descriptions.Item label="手机号">{currItemInfo.mobile}</Descriptions.Item>
          {currItemInfo.postdata &&
            currItemInfo.postdata.length &&
            currItemInfo.postdata.map(item => {
              return <Descriptions.Item label={item.label}>{item.value}</Descriptions.Item>;
            })}
          <Descriptions.Item label="所属地区">
            {currItemInfo.address !== 'Array-Array' ? currItemInfo.address : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="咨询时间">{currItemInfo.add_time}</Descriptions.Item>
          <Descriptions.Item label="活动来源">{currItemInfo.active_name}</Descriptions.Item>
          <Descriptions.Item label="跟进状态">
            {
              followUpStatusList.filter(item => {
                return item.value === Number(currItemInfo.follow_status);
              })[0]?.title
            }
          </Descriptions.Item>
          <Descriptions.Item label="跟进时间">{currItemInfo.update_time || '-'}</Descriptions.Item>
          <Descriptions.Item label="浏览次数">{currItemInfo.vist_num}</Descriptions.Item>
          {currItemInfo.history && (
            <Descriptions.Item label="浏览时长">
              {currItemInfo.history.click_time}秒
            </Descriptions.Item>
          )}
          <Descriptions.Item label="浏览终端">{currItemInfo.device}</Descriptions.Item>
        </Descriptions>
        {currItemInfo.vist && (
          <>
            <div style={{ marginBottom: 20, fontSize: 18, fontWeight: 'bold' }}>访问轨迹:</div>
            <Timeline>
              {currItemInfo.vist.map(item => {
                return (
                  <Timeline.Item>
                    {moment(Number(item.add_time) * 1000).format('YYYY/MM/DD HH:mm')} 访问活动：
                    {item.active_name}｜访问时长：
                    {item.click_time}秒
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </>
        )}
      </Drawer>
    );
  };

  return (
    <div className="clue-consult">
      {initSearchForm()}
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          pageSize: size,
          defaultCurrent: 1,
          current: page,
          total: total,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setPage(page);
            setSize(pageSize);
          },
        }}
        rowKey="id"
      />
      {initDrawer()}
    </div>
  );
}

export default ClueConsult;
