import WidgetImage from '../../asset/image/decorate/widget-image.png';
import WidgetText from '../../asset/image/decorate/widget-text.png';
import WidgetVideo from '../../asset/image/decorate/widget-video.png';
import WidgetAudio from '../../asset/image/decorate/widget-audio.png';
import WidgetForm from '../../asset/image/decorate/widget-input.png';
import WidgetDivide from '../../asset/image/decorate/widget-divide.png';

export const widgets = [
  {
    type: 'image',
    label: '图片',
    img: WidgetImage,
    imageList: [],
    style: {
      objectFit: 'fill',
      width: '375',
      maxWidth: '100%',
    },
  },
  {
    type: 'text',
    label: '文本',
    img: WidgetText,
    text: '这里是文本内容',
    form: '',
    textType: 'normal',
    style: {
      fontSize: '16',
      color: 'rgba(0, 0, 0, 1)',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      textAlign: 'center',
      paddingTop: '10',
      paddingRight: '10',
      paddingBottom: '10',
      paddingLeft: '10',
    },
  },
  {
    type: 'video',
    label: '视频',
    img: WidgetVideo,
    style: {
      objectFit: 'cover',
      width: '100%',
      height: '200',
    },
    coverImg: '',
    src: '',
  },
  // {
  //   type: 'audio',
  //   label: '音频',
  //   img: WidgetAudio,
  //   style: { objectFit: 'cover', width: '40', height: '40' },
  //   coverImg: '',
  //   src: '',
  // },
  {
    type: 'form',
    label: '表单',
    img: WidgetForm,
    formList: [
      {
        label: '用户名',
        input: '请输入用户名',
        key: 'name',
      },
      {
        label: '手机号',
        input: '请输入手机号',
        key: 'phone',
      },
    ],
    formSubmitText: '立即预约',
    ifShowPrivace: true,
    privacyAgreementText: '用户协议',
    style: {
      width: '355',
      height: '220',
      fontSize: '16',
      color: '#4A4A4A',
      backgroundColor: '#ffffff',
      padding: '15',
      margin: '0 auto',
      zIndex: '0',
      borderWidth: '0',
      borderColor: '#fff',
      borderStyle: 'solid',
      borderRadius: '10',
      labelColor: '#4A4A4A',
      inputColor: '#B2B2B2',
      labelWidth: '100',
      itemMarginBottom: '20',
      formSubmitColor: '#FFFFFF',
      formSubmitBackgroundColor: '#F5A623',
      formSubmitWidth: '300',
      formSubmitHeight: '40',
      formSubmitBorderRadius: '30',
    },
  },
  {
    type: 'divide',
    label: '方块',
    img: WidgetDivide,
    style: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      width: '375',
      height: '40',
    },
  },
  {
    type: 'button',
    label: '按钮组件',
    img: WidgetText,
    text: '按钮组件',
    form: '',
    textType: 'consult',
    style: {
      fontSize: '16',
      color: '#000',
      backgroundColor: '#fff',
      textAlign: 'center',
      width: '375',
      height: '40',
      paddingTop: '',
      paddingRight: '',
      paddingBottom: '',
      paddingLeft: '',
      zIndex: '0',
    },
  },
  {
    type: 'suspension',
    label: '悬浮按钮组',
    img: WidgetText,
    position: 'bottom',
    functionBtnList: [
      {
        text: '人工客服',
        openType: 'contact',
        open: false,
      },
      {
        text: '在线客服',
        openType: 'im',
        open: false,
      },
      {
        text: '授权手机号',
        openType: 'getPhoneNumber',
        open: false,
      },
    ],
    style: {
      position: 'absolute',
      bottom: '0',
      width: '375',
      height: '50',
      fontSize: '16',
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
  // {
  //   type: 'submit',
  //   label: '表单提交',
  //   img: WidgetText,
  //   text: '这里是表单提交',
  //   form: '',
  //   textType: 'submit',
  //   style: {
  //     fontSize: '16',
  //     color: 'rgba(0, 0, 0, 1)',
  //     backgroundColor: 'rgba(255, 255, 255, 1)',
  //     textAlign: 'center',
  //     paddingTop: '10',
  //     paddingRight: '10',
  //     paddingBottom: '10',
  //     paddingLeft: '10',
  //   },
  // },
];
