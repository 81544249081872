import React, { PureComponent, Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Divider, Input, Checkbox, Col, Spin, message, Modal } from 'antd';

import { Scrollbars } from 'react-custom-scrollbars';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import appbase from '../../js/appbase';
import Register from './register';
import loginBgImg from '@/asset/image/login-bg.gif';
import './index.scss';

const FormItem = Form.Item;

class Login extends PureComponent {
  constructor(props) {
    super(props);
  }
  state = {
    autoLogin: true,
    time: 60,
    visible: false,
    type: 0,
    mobile: '',
    code: '',
    openId: 'openApi',
    disabled: false,
    isbind: 0,
    enterpriseList: [],
    height: 436,
    appid: '',
    agentid: '',
    code_url: '',
    showModal: false,
    corp_id: '',
    agent_id: '',
    agent_secret: '',
    username: '',
    set_mobile: '',
    showCodeSet: false,
    enterprise_name: '',
    showRegister: false,
  };

  componentDidMount() {
    var access_key = ApiConfig.appId + '_pull_msg_token_' + ApiConfig.debug;
    var access_token = appbase.getCookie(access_key);
    if (access_token != '') {
      this.props.history.push('/home');
      return;
    }
    this.getLoginInfo();
  }

  changeAutoLogin = () => {
    this.setState({ autoLogin: !this.state.autoLogin });
  };

  //获取验证码
  handCountdown = e => {
    // e.stopPropagation()
    // e.preventDefault()
    // console.log(e.preventDefault());

    const that = this;
    // if(that.state.isbind==1){
    //   message.error('请勿频繁发送');
    //   return
    // }
    that.setState({ isbind: 1 });
    var mobile = that.state.mobile;
    if (mobile == '') {
      message.error('请输入手机号', 2);
      return;
    }
    var checkMObile = /^1[3456789]\d{9}$/;
    if (!checkMObile.test(mobile)) {
      message.error('手机号码格式错误', 2);
      return;
    }

    // comRequest.get_apptoken(this.state.openId,function(token,code){
    // if(code == 0){
    message.warning('验证码发送中...');
    var formData = new FormData();
    formData.append('mobile', mobile);
    postFetch(ApiConfig.get_code_url, formData, res => {
      message.destroy();
      if (res.code == 0) {
        message.success('验证码发送成功，请注意查收');
        const time = setInterval(() => {
          if (that.state.time === 1) {
            window.clearInterval(time, that.setState({ time: 60 }));
          } else {
            let num = that.state.time;
            num--;
            that.setState({ time: num, isbind: 0 });
          }
        }, 1000);
      } else {
        that.setState({ isbind: 0 });
        message.error(res.info);
      }
    });
    // }
    // })
  };

  handSetCode = e => {
    this.setState({
      showModal: true,
    });
  };

  handOther = type => {
    this.setState({ type });
    if (type == 1) {
      this.setState({
        height: 636,
      });
    } else {
      this.setState({
        height: 436,
      });
    }
  };
  //输入手机号
  inputMobile = e => {
    var that = this;
    that.setState({
      mobile: e.target.value,
    });
  };
  //输入验证码
  inputCode = e => {
    var that = this;
    that.setState({
      code: e.target.value,
    });
  };

  getLoginInfo = () => {
    var that = this;
    // appbase.get_app_token(function (token, code) {
    // var formData = new FormData();
    // let code_url = '';
    // var searchParams = new URLSearchParams(this.props.location.search);
    // var chain_id = searchParams.get('chain_id');
    // var agent_id = searchParams.get('agent_id');
    // var eid = searchParams.get('eid'); //私有化才存在

    // if (ApiConfig.use_edition == 'saas') {
    //   if (chain_id != undefined && chain_id.length >= 18 && agent_id) {
    //     //下游企业登录
    //     // https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=CORPID&agentid=AGENTID&redirect_uri=REDIRECT_URI&state=STATE
    //     code_url =
    //       'https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=' +
    //       chain_id +
    //       '&&agentid=' +
    //       agent_id +
    //       '&redirect_uri=' +
    //       window.g_api.host +
    //       '/pc/' +
    //       'qrcode&state=wininsales@' +
    //       chain_id +
    //       '#&usertype=member';
    //   } else {
    //     code_url =
    //       'https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=wx1ca3b136b8594823&redirect_uri=' +
    //       window.g_api.host +
    //       '/pc/' +
    //       'qrcode&state=web_login@huilanonline&usertype=member';
    //   }
    //   that.setState({
    //     code_url: code_url,
    //   });
    //   return;
    // }
    // formData.append('eid', eid || '');
    // postFetch(ApiConfig.get_login_info_url, formData, res => {
    //   if (res.code == 0) {
    //     code_url =
    //       'https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=' +
    //       res.data.corp_id +
    //       '&agentid=' +
    //       res.data.agent_id +
    //       '&redirect_uri=' +
    //       window.g_api.host +
    //       '/pc/' +
    //       'qrcode&state=web_login@huilanonline';
    //     that.setState({
    //       appid: res.data.corp_id,
    //       agentid: res.data.agent_id,
    //       code_url: code_url,
    //     });
    //     if (
    //       (res.data.corp_id == null || res.data.agent_id == null) &&
    //       ApiConfig.use_edition == 'private'
    //     ) {
    //       that.setState({
    //         showCodeSet: true,
    //         showModal: true,
    //       });
    //     }
    //   } else {
    //     that.setState({ isbind: 0 });
    //     message.error(res.info);
    //   }
    // });
    // })
  };

  //点击登录
  handleok = () => {
    var that = this;
    this.props.form.validateFields((error, value) => {
      if (error) {
        return;
      } else {
        message.loading({ content: '登录中...', duration: 0 });
        that.setState({ disabled: true });
        var formData = new FormData();
        formData.append('mobile', this.state.mobile);
        formData.append('code', this.state.code);
        postFetch(ApiConfig.mobile_login_url, formData, res => {
          if (res.code == 0) {
            message.destroy();
            const menu = res.menu.filter(item => item.name != '首页');
            if (res.is_expired) {
              appbase.setSessionGlobalItem('_access_isExpired', res.is_expired);
            }
            const openKeys = menu.map(item => item.id);
            var access_key = ApiConfig.appId + '_pull_msg_token_' + ApiConfig.debug;
            var user_range = ApiConfig.appId + '_pull_msg_user_range_' + ApiConfig.debug;
            window.g_cache.setItem('scrm_menu', menu);
            window.g_cache.setItem('scrm_auth', res.auth);
            localStorage.setItem('allowJump', 1);
            appbase.setSessionGlobalItem('__access_sauth', JSON.stringify(res.auth));
            localStorage.setItem('openKeys', openKeys);
            localStorage.setItem('system', res.system);
            window.g_cache.setItem(
              'currentUser',
              res.truename !== ''
                ? res.truename
                : res.enterprise.enterprise_name
                ? res.enterprise.enterprise_name
                : '请完善用户信息'
            );
            window.g_cache.setItem('company', res.enterprise.enterprise_name);
            window.g_cache.setItem('enterprise', res.enterprise);
            appbase.setCookie(access_key, res.access_token, 29000);
            appbase.setCookie(user_range, JSON.stringify(res.user_range), 29000);
            setTimeout(() => {
              if (res.system == 0) {
                window.location.href = '/home';
              } else {
                this.props.history.push('/home');
              }
            }, 100);
          } else {
            message.destroy();
            that.setState({ disabled: false });
            message.error(res.msg || res.info);
          }
        });
        // this.props.history.push('/home')
      }
    });
  };

  handleGetInputValue(event, key) {
    this.setState({
      [key]: event.target.value,
    });
  }

  handleModalOk = e => {
    var that = this;
    if (that.state.corp_id == '') {
      message.error('请填写企业id');
      return;
    }
    if (that.state.agent_id == '') {
      message.error('请填写应用id');
      return;
    }
    if (that.state.agent_secret == '') {
      message.error('请填写应用secret');
      return;
    }
    if (that.state.username == '') {
      message.error('请填写企业用户账号');
      return;
    }
    if (that.state.set_mobile == '') {
      message.error('请填写手机号');
      return;
    }
    if (that.state.enterprise_name == '') {
      message.error('请填写企业名称');
      return;
    }
    that.setState({ confirmLoading: true });
    var formData = new FormData();
    formData.append('corp_id', this.state.corp_id);
    formData.append('agent_id', this.state.agent_id);
    formData.append('agent_secret', this.state.agent_secret);
    formData.append('username', this.state.username);
    formData.append('mobile', this.state.set_mobile);
    formData.append('enterprise_name', this.state.enterprise_name);
    postFetch(ApiConfig.setting_login_code_url, formData, res => {
      if (res.code == 0) {
        message.success('保存成功');
        this.getLoginInfo();
        that.setState({
          showModal: false,
          showCodeSet: false,
        });
      } else {
        that.setState({ confirmLoading: false });
        message.error(res.info);
      }
    });
  };

  handleCancel = e => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const logo = {
      textAlign: 'center',
      cursor: 'pointer',
      marginBottom: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '22px',
      fontWeight: 'bold',
    };

    return (
      <Fragment>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundImage: `url(${loginBgImg})`,
            backgroundSize: 'cover',
          }}
        ></div>
        {!this.state.showRegister ? (
          <div
            style={{
              position: 'absolute',
              height: this.state.height,
              top: this.state.type === 0 ? '50%' : '36%',
              left: '50%',
              width: '380px',
              padding: '36px',
              boxShadow: '0 0 100px rgba(0, 0, 0, 0.08)',
              background: '#fff',
              marginTop: '-218px',
              marginLeft: '-190px',
              borderRadius: '10px',
            }}
            className="login"
          >
            <div style={logo}>
              {this.state.type === 0 ? (
                <span>手机动态码登录</span>
              ) : this.state.type === 1 ? (
                <span>企业微信扫码登录</span>
              ) : this.state.type === 2 ? (
                <span>微信账号扫码登录</span>
              ) : this.state.type === 3 ? (
                <span>选择登录的企业</span>
              ) : this.state.type === 4 ? (
                <span>扫码登录失败</span>
              ) : null}
            </div>
            {this.state.type === 0 ? (
              <>
                <Form className="login_form">
                  <FormItem
                    help={
                      getFieldError('username') ? (
                        <div style={{ color: 'red' }}>{getFieldError('username').join(',')}</div>
                      ) : null
                    }
                  >
                    {getFieldDecorator('username', {
                      rules: [
                        {
                          required: true,
                          message: '请正确填写手机号',
                          pattern: /^1[3456789]\d{9}$/,
                        },
                      ],
                    })(
                      <Input
                        style={{
                          borderColor: '#ebeff2',
                          backgroundColor: '#F1F2F4',
                          height: 44,
                          borderRadius: 4,
                        }}
                        onChange={this.inputMobile}
                        placeholder="请输入手机号码"
                      />
                    )}
                  </FormItem>
                  <FormItem
                    help={
                      getFieldError('verification_code') ? (
                        <div style={{ color: 'red' }}>
                          {getFieldError('verification_code').join(',')}
                        </div>
                      ) : null
                    }
                    style={{
                      backgroundColor: '#F1F2F4',
                      height: 44,
                      borderRadius: 4,
                    }}
                  >
                    {getFieldDecorator('verification_code', {
                      rules: [
                        {
                          required: true,
                          message: '请输入验证码',
                          len: 4,
                        },
                      ],
                    })(
                      <Row style={{ display: 'flex' }}>
                        <Col span={18}>
                          <Input
                            className="login_password"
                            style={{
                              borderColor: '#ebeff2',
                              backgroundColor: '#F1F2F4',
                              height: 44,
                              borderRadius: 4,
                            }}
                            onChange={this.inputCode}
                            placeholder="请输入验证码"
                          />
                        </Col>
                        <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                          {this.state.time < 60 ? (
                            <span
                              style={{
                                fontSize: 12,
                                color: '#afafaf',
                                cursor: 'pointer',
                                width: '100%',
                                textAlign: 'center',
                                display: 'block',
                                fontWeight: 'bold',
                              }}
                            >
                              {this.state.time}
                            </span>
                          ) : (
                            <span
                              style={{
                                fontSize: 12,
                                color: '#afafaf',
                                cursor: 'pointer',
                                width: '100%',
                                textAlign: 'center',
                                display: 'block',
                                fontWeight: 'bold',
                              }}
                              onClick={e => this.handCountdown(e)}
                            >
                              {this.state.time < 60 ? this.state.time : '获取验证码'}
                            </span>
                          )}
                        </Col>
                      </Row>
                    )}
                  </FormItem>

                  <div style={{ marginBottom: 10, marginTop: 20 }}>
                    <Checkbox
                      className="remember-auto"
                      checked={this.state.autoLogin}
                      onChange={this.changeAutoLogin}
                      style={{ fontSize: 12, color: '#afafaf' }}
                    >
                      记住账号
                    </Checkbox>
                  </div>
                  <Row style={{ marginTop: 34, marginBottom: 24 }}>
                    <Button
                      disabled={this.state.disabled}
                      style={{
                        width: '100%',
                        height: 50,
                        borderRadius: 6,
                        backgroundColor: '#D4515D',
                        outline: 'none',
                      }}
                      type="primary"
                      onClick={() => {
                        this.handleok();
                      }}
                    >
                      登录
                    </Button>
                  </Row>

                  {/* <Divider>
                    <span style={{ fontSize: 12, color: 'rgb(175, 175, 175)' }}>其他登录方式</span>
                  </Divider>
                  <Row>
                    <Button
                      onClick={() => this.handOther(1)}
                      style={{ width: '100%', borderColor: '#ebeff2', fontSize: '12px' }}
                    >
                      <img
                        style={{ width: 12, height: 12, marginRight: 8 }}
                        src="./images/wechart.png"
                        alt=""
                      />
                      企业微信扫码登录
                    </Button>
                  </Row> */}
                </Form>
                <Divider>
                  <button
                    type="text"
                    style={{
                      cursor: 'pointer',
                      fontSize: 12,
                      color: 'rgb(175, 175, 175)',
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => {
                      this.setState({
                        showRegister: true,
                      });
                    }}
                  >
                    没有账号？<span style={{ textDecoration: 'underline' }}>立即去注册</span>
                  </button>
                </Divider>
              </>
            ) : this.state.type === 1 ? (
              <div>
                <div style={{ width: 308, height: 400, textAlign: 'center' }}>
                  <iframe
                    id="iframe"
                    name="iframe"
                    src={this.state.code_url}
                    frameBorder="0"
                    width="280px"
                    height="380px"
                  ></iframe>
                </div>
                <Divider>
                  <span style={{ fontSize: 12, color: 'rgb(175, 175, 175)' }}>其他登录方式</span>
                </Divider>
                <Row>
                  <Button
                    onClick={() => this.handOther(0)}
                    style={{ width: '100%', borderColor: '#ebeff2', fontSize: 12 }}
                  >
                    账号密码登录
                  </Button>
                </Row>
                {this.state.showCodeSet && (
                  <Row>
                    <Button
                      onClick={() => this.handSetCode()}
                      style={{ width: '100%', border: 0, fontSize: 12, marginTop: 10 }}
                    >
                      扫码登录参数配置
                    </Button>
                  </Row>
                )}
              </div>
            ) : this.state.type === 2 ? (
              <div>
                <div style={{ width: 308, height: 191, textAlign: 'center' }}>
                  <img style={{ width: 191, height: 191 }} src="./images/qr_code.png" alt="" />
                </div>
                <Divider>
                  <span style={{ fontSize: 12, color: 'rgb(175, 175, 175)' }}>其他登录方式</span>
                </Divider>
                <Row>
                  <Button
                    onClick={() => this.handOther(0)}
                    style={{ width: '100%', borderColor: '#ebeff2' }}
                  >
                    账号密码登录
                  </Button>
                </Row>
                <Row>
                  <Button
                    onClick={() => this.handOther(1)}
                    style={{ width: '100%', borderColor: '#ebeff2', marginTop: 15 }}
                  >
                    <img
                      style={{ width: 14, height: 14, marginRight: 8 }}
                      src="./images/wechart.png"
                      alt=""
                    />
                    企业微信扫码登录
                  </Button>
                </Row>
              </div>
            ) : this.state.type === 3 ? (
              <Spin spinning={false}>
                <Scrollbars
                  style={{ height: 318 }}
                  autoHideTimeout={0}
                  autoHideDuration={500}
                  thumbSize={100}
                >
                  <div
                    style={{ height: 318, paddingLeft: '8px', display: 'flex', flexWrap: 'wrap' }}
                  >
                    {this.state.enterpriseList.map((items, n) => {
                      return (
                        <Row
                          gutter={16}
                          key={n}
                          onClick={e => this.chooseEnterprise(items.id)}
                          style={{
                            padding: '20px 8px',
                            height: 82,
                            width: '100%',
                            marginBottom: 36,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          className="border_ebeff2"
                        >
                          <Col className="gutter-row" span={6}>
                            <img style={{ width: 42, height: 42 }} src={items.logo} alt="" />
                          </Col>
                          <Col className="gutter-row world_line_2" span={18}>
                            {items.enterprise_name}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </Scrollbars>
              </Spin>
            ) : (
              <div>
                <p
                  style={{
                    textAlign: 'center',
                    marginTop: '-18px',
                    fontSize: 12,
                    color: 'rgb(175, 175, 175)',
                  }}
                >
                  请先走“赢在名片”中创建企业名片
                </p>
                <div style={{ width: 308, height: 289, textAlign: 'center', marginTop: 18 }}>
                  <img style={{ width: 289, height: 289 }} src="./images/qr_code.png" alt="" />
                </div>
                <p style={{ textAlign: 'center', marginTop: '18px', fontSize: 12, color: '' }}>
                  微信扫一扫 快速创建企业名片
                </p>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              height: this.state.height,
              top: this.state.type === 0 ? '50%' : '36%',
              left: '50%',
              width: '380px',
              padding: '36px',
              boxShadow: '0 0 100px rgba(0, 0, 0, 0.08)',
              background: '#fff',
              marginTop: '-218px',
              marginLeft: '-190px',
              borderRadius: '10px',
            }}
            className="login"
          >
            <div style={logo}>
              <span>注册账号</span>
            </div>
            <Register
              toLogin={() => {
                this.setState({
                  showRegister: false,
                });
              }}
            ></Register>
          </div>
        )}

        <Modal
          title="初始参数设置"
          visible={this.state.showModal}
          onOk={this.handleModalOk}
          onCancel={this.handleCancel}
          confirmLoading={this.state.confirmLoading}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="企业ID">
              <Input
                key="corp_id"
                value={this.state.corp_id}
                onChange={value => this.handleGetInputValue(value, 'corp_id')}
              />
            </Form.Item>
            <Form.Item label="企业名称">
              <Input
                key="enterprise_name"
                value={this.state.enterprise_name}
                onChange={value => this.handleGetInputValue(value, 'enterprise_name')}
              />
            </Form.Item>
            <Form.Item label="企业用户账号">
              <Input
                key="username"
                value={this.state.username}
                onChange={value => this.handleGetInputValue(value, 'username')}
              />
            </Form.Item>
            <Form.Item label="应用AgentId">
              <Input
                key="agent_id"
                value={this.state.agent_id}
                onChange={value => this.handleGetInputValue(value, 'agent_id')}
              />
            </Form.Item>
            <Form.Item label="应用Secret">
              <Input
                key="agent_secret"
                value={this.state.agent_secret}
                onChange={value => this.handleGetInputValue(value, 'agent_secret')}
              />
            </Form.Item>

            <Form.Item label="登录手机号">
              <Input
                key="set_mobile"
                value={this.state.set_mobile}
                onChange={value => this.handleGetInputValue(value, 'set_mobile')}
              />
            </Form.Item>
          </Form>
        </Modal>
        {/* <Modal
          title="立即注册"
          mask={true}
          maskClosable={true}
          open={this.state.showRegister}
          onCancel={() => {
            this.setState({
              showRegister: false,
            });
          }}
          footer={null}
        >
          <Register></Register>
        </Modal> */}
      </Fragment>
    );
  }
}

export default Form.create()(Login);
