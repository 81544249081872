/**
 * 选择素材modal
 */
import React, { useEffect, useState } from 'react';
import { Modal, Input, List, Skeleton, Divider, Pagination, Spin, Empty, Radio } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSingleState } from 'nice-hooks';
import classNames from 'classnames';
import { FolderFilled, CheckCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import WxappFooter from '../../asset/image/wxapp-footer.png';
import './MaterialModal.scss';
import { useUpdateEffect } from 'ahooks';

const defaultValue = {
  name: '全部',
  id: '',
};

function MaterialModal({ materialType, onChange, visible = false, onCancel }) {
  const [state, setState] = useSingleState({
    group: [
      {
        name: '全部',
        id: '',
      },
    ],
    groupCount: 0,
    groupPage: 1,
    groupLoading: false,
    groupSearchValue: '',
    selectedGroupItem: defaultValue,
    materialList: [],
    materialCount: 0,
    materialPage: 1,
    materialLoading: false,
    materialSearchValue: '',
    materialSelectedKey: 'link',
  });
  const [selectedMaterialItem, set_selectedMaterialItem] = useState({});

  useUpdateEffect(() => {
    if (!visible) return false;
    setState(
      {
        materialSelectedKey: materialType,
      },
      async () => {
        await fetchGroupList();
        await fetchMaterial();
      }
    );
  }, [visible, materialType]);

  async function fetchMaterial() {
    if (state.materialLoading) return false;
    setState({
      materialLoading: true,
    });
    try {
      let res = await window.g_dyFetch({
        url: `${window.g_api.dy_media_center_index_url}?action=${state.materialSelectedKey}`,
        body: {
          p: state.materialPage,
          per: 15,
          keyword: state.materialSearchValue,
          get_list_data: 'get_list_data',
          action: state.materialSelectedKey,
          group_id: state.selectedGroupItem.id,
          is_components: 1,
        },
      });
      console.log('res:', res);

      setState({
        materialList: res.data.data,
        materialCount: parseInt(res.data.count),
        materialLoading: false,
      });
    } catch (error) {
      setState({
        materialList: [],
        materialCount: 0,
        materialLoading: false,
      });
    }
  }

  // 查询素材分组
  async function fetchGroupList() {
    if (state.groupPage === 1) {
      setState({
        group: [{ ...defaultValue }],
      });
    }
    try {
      let res = await window.g_dyFetch({
        url: window.g_api.dy_media_center_group_url,
        body: {
          per: 15,
          p: state.groupPage,
          keyword: state.groupSearchValue,
        },
      });
      console.log('res:', res);
      setState({
        group: [...state.group, ...res.data.data],
        groupCount: parseInt(res.data.count),
        groupLoading: false,
      });
    } catch (error) {
      setState({
        group: [],
        groupCount: 0,
        groupLoading: false,
      });
    }
  }

  function loadMoreData() {
    if (state.groupLoading) return false;
    setState({
      groupLoading: true,
    });
    if (state.groupPage * 15 < state.groupCount) {
      setState(
        {
          groupPage: state.groupPage + 1,
        },
        () => {
          fetchGroupList();
        }
      );
    }
  }

  function onSearch(key, value) {
    if (key == 'group') {
      setState(
        {
          groupPage: 1,
          group: [],
          groupSearchValue: value,
        },
        fetchGroupList
      );
    } else {
      setState(
        {
          materialPage: 1,
          materialList: [],
          materialSearchValue: value,
        },
        fetchMaterial
      );
    }
  }
  function onSelectGroup(item) {
    setState(
      {
        selectedGroupItem: item,
      },
      fetchMaterial
    );
  }

  function onOk() {
    switch (materialType) {
      case 'image':
        onChange(selectedMaterialItem.img);
        break;
      case 'link':
        onChange({
          url: selectedMaterialItem.link_url,
          title: selectedMaterialItem.title,
          desc: selectedMaterialItem.describe,
          file_url: selectedMaterialItem.file_url,
        });
        break;
      case 'miniprogram':
        onChange({
          appid: selectedMaterialItem.appid,
          title: selectedMaterialItem.title,
          page: selectedMaterialItem.page,
          cover: selectedMaterialItem.file_url,
        });
        break;
    }
    onCloseModal();
  }

  function onCloseModal() {
    set_selectedMaterialItem({});
    setState({
      materialLoading: false,
    });
    onCancel();
  }

  function onPaginationChange(page) {
    setState(
      {
        materialPage: page,
      },
      fetchMaterial
    );
  }

  const hasMore = state.groupPage * 15 < state.groupCount;
  return (
    <Modal
      zIndex={2000}
      onCancel={onCloseModal}
      visible={visible}
      width={1000}
      title="选择素材"
      className="MyMaterialModal"
      onOk={onOk}
    >
      <div className="container">
        <div className="left">
          <Input.Search onSearch={value => onSearch('group', value)} />
          <div className="scrollableDiv" id="scrollableDiv">
            <InfiniteScroll
              dataLength={state.group.length}
              next={loadMoreData}
              hasMore={state.group.length < state.groupCount}
              loader={<Skeleton paragraph={{ rows: 1 }} active />}
              endMessage={
                !state.groupLoading &&
                state.group.length > 0 &&
                !hasMore && <Divider plain>没有更多了~</Divider>
              }
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={state.group}
                renderItem={item => (
                  <List.Item
                    key={item.id}
                    onClick={() => onSelectGroup(item)}
                    className={`${state.selectedGroupItem.id == item.id ? 'groupActive' : null}`}
                  >
                    <div className="flex_row">
                      <FolderFilled />
                      <span className="uniline">{item.name}</span>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        </div>
        <div className="right">
          <div className="head">
            <div className="total-box">
              <div className="border-left" />
              <div className="total-txt">共{state.materialCount}个素材</div>
              {materialType === 'link' && (
                <Radio.Group
                  style={{ marginLeft: 15 }}
                  value={state.materialSelectedKey}
                  onChange={e => setState({ materialSelectedKey: e.target.value }, fetchMaterial)}
                >
                  <Radio value="link">链接</Radio>
                  <Radio value="article">图文</Radio>
                  <Radio value="video">视频</Radio>
                  <Radio value="file">文件</Radio>
                </Radio.Group>
              )}
            </div>
            <Input.Search placeholder="搜索" onSearch={value => onSearch(null, value)} />
          </div>
          <div className="list">
            {state.materialList.map(item => (
              <div
                className={classNames('item', { active: item.id === selectedMaterialItem?.id })}
                onClick={() => set_selectedMaterialItem(item)}
                key={item.id}
              >
                {materialType === 'image' && (
                  <div className="image">
                    <div className="top top_image">
                      <img src={window.g_isCos(item.cover_url || item.img)} />
                      <div className="check-box">
                        <CheckCircleFilled />
                        <span>{item.name}</span>
                      </div>
                    </div>
                    <div className="bottom">
                      <span className="hb_tit">{item.title}</span>
                    </div>
                  </div>
                )}
                {materialType === 'link' && (
                  <div className="link">
                    <div className="check-box">
                      <CheckCircleFilled />
                    </div>
                    <div className="out-titl uniline">{item.title}</div>
                    <div className="radar-detail flex_row">
                      <div className="desc uniline">{item.describe}</div>
                      <img src={window.g_isCos(item.file_url)} />
                    </div>
                  </div>
                )}
                {materialType === 'miniprogram' && (
                  <div className="miniprogram">
                    <div className="check-box">
                      <CheckCircleFilled />
                    </div>
                    <div className="title uniline">{item.title}</div>
                    <img src={window.g_isCos(item.cover_url)} alt="" className="miniprogram_img" />
                    <p>
                      <img style={{ width: '100%' }} src={WxappFooter} alt="" />
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
          {state.materialLoading && <Spin />}
          {!state.materialLoading && state.materialList.length === 0 && <Empty />}
          <Pagination
            size="small"
            pageSize={15}
            total={state.materialCount}
            current={state.materialPage}
            onChange={onPaginationChange}
          />
        </div>
      </div>
    </Modal>
  );
}

export default MaterialModal;
