import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { Button, message } from 'antd';
import 'react-quill/dist/quill.snow.css';
import './user-privace.scss';

function UserPrivace() {
  const [userPrivaceContent, setUserPrivaceContent] = useState('');
  useEffect(async () => {
    await getData();
  }, []);
  function escape2Html(str) {
    var temp = document.createElement('div');
    temp.innerHTML = str;
    var output = temp.innerText || temp.textContent;
    temp = null;
    return output;
  }

  const getData = async () => {
    try {
      const res = await window.g_dyFetch({
        url: window.g_api.get_user_privace_url,
        method: 'GET',
      });
      console.log('res:', res);
      setUserPrivaceContent(escape2Html(res.data.content));
    } catch (error) {
      console.log(error);
      message.error(error.msg);
    }
  };
  const saveUserPrivace = async () => {
    try {
      await window.g_dyFetch({
        url: window.g_api.save_user_privace_url,
        method: 'POST',
        bodyType: 'obj',
        body: {
          content: userPrivaceContent,
        },
      });
      message.success('保存成功');
      getData();
    } catch (error) {
      message.error(error.msg);
    }
  };
  return (
    <div className="user-privace">
      <ReactQuill
        className="react-quill-el"
        style={{ height: '600px' }}
        value={userPrivaceContent}
        onChange={setUserPrivaceContent}
      />
      <Button type="primary" onClick={saveUserPrivace} style={{ marginTop: 15 }}>
        保存
      </Button>
    </div>
  );
}

export default UserPrivace;
