import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import {Link, withRouter} from 'react-router-dom'
import {  } from 'react-router-dom';
import './index.scss'

class CreateHeader extends React.Component {
  back = () => {
    this.props.history.goBack()
  }
  render() {
    const { title } = this.props;
    return (
      <div className='header create'>
        <div className='info' style={{cursor: 'pointer'}} onClick={this.back}><LeftOutlined /> {title}</div>
      </div>
    );
  }
}
export default withRouter(CreateHeader)