/**
 * 用户管理
 */
import React, { useEffect, useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import _ from 'lodash';
import {
  Button,
  Table,
  Tree,
  Tooltip,
  message,
  Tag,
  Input,
  Drawer,
  Select,
  TreeSelect,
  Tabs,
} from 'antd';
import { useSingleState } from 'nice-hooks';
import Pagination from '@/component/FormItem/pagination';
import filterSearch from '@/component/FilterProps/filterSearch';
import Empty from '@/component/Empty';
import './user.scss';
import dayjs from 'dayjs';

const { TreeNode, DirectoryTree } = Tree;
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const { TabPane } = Tabs;

const tabs = [
  // { key: '1', title: '内部通讯录' },
  { key: '2', title: '自建用户' },
];

function User({ form }) {
  const [department, set_department] = useState([]);
  const [list, set_list] = useState([]);
  const [total, set_total] = useState(0);
  const [filters, set_filters] = useState({});
  const [status, set_status] = useState();
  const [visible, set_visible] = useState(false);
  const [userTotal, set_userTotal] = useState(0);
  const [tabkey, set_tabkey] = useState('2');
  const [loading, set_loading] = useState(false);
  const [userid, set_userid] = useState();

  const [state, setState] = useSingleState({
    page: {
      current: 1,
      pageSize: 15,
    },
    selectedKeys: '',
    load: false,
    roles: [],
    scrollPage: 1,
    dataForUpdate: null,
    modalVisible: false,
    expandedKeys: [],
    btnloading: false,
    keyword: '',
    userDatas: [],
    topKey: [],
  });

  useEffect(() => {
    window.g_request(
      {
        url: 'user_new/get_department_list',
      },
      res => {
        setState({
          selectedKeys: res.data.map(item => item.key),
          load: true,
          expandedKeys: res.data.map(item => item.key),
          topKey: res.data.map(item => item.key),
        });
        set_department(res.data);
      }
    );
  }, []);

  useEffect(() => {
    window.g_appbase.get_app_token(token => {
      let formData = new FormData();
      window.g_postFetch(window.g_api.list_role_url + '?token=' + token, formData, res => {
        setState({
          roles: res.data,
        });
      });
    });
  }, []);

  useEffect(() => {
    if (state.load) {
      fetchData();
      set_total(0);
    }
  }, [state.page, filters, state.load, tabkey]);

  function fetchData() {
    if (loading) return false;
    set_loading(true);
    if (tabkey === '1') {
      window.g_appbase.get_app_token(token => {
        let formData = new FormData();
        formData.append('pageno', state.page.current);
        formData.append('limit', state.page.pageSize);
        formData.append('is_addressbook', 1);
        formData.append('department_id', state.selectedKeys);
        formData.append('keyword', filters?.truename || '');
        formData.append('status', filters?.status || '');
        window.g_postFetch(window.g_api.permit_member_list + '?token=' + token, formData, res => {
          set_list(res.data);
          set_total(res.total);
          set_loading(false);
        });
      });
    } else {
      window.g_appbase.get_app_token(token => {
        let formData = new FormData();
        formData.append('keyword', filters?.truename || '');
        formData.append('status', filters?.status || '');
        window.g_postFetch(window.g_api.list_user_url + '?token=' + token, formData, res => {
          set_list(res.data);
          set_total(res.data.length);
          set_loading(false);
        });
      });
    }
  }

  function updateStatus(id, status) {
    if (!id) {
      message.warning('禁用失败，请先设为系统用户');
      return false;
    }
    window.g_appbase.get_app_token(token => {
      let formData = new FormData();
      formData.append('id', id);
      formData.append('status', status);
      window.g_postFetch(window.g_api.update_user_status_url + '?token=' + token, formData, res => {
        if (res.code == 0) {
          message.success(res.msg);
          fetchData();
          if (status == -2) {
            set_status(1);
          } else {
            set_status(-2);
          }
        } else {
          message.warn(res.msg);
        }
      });
    });
  }

  function onSelect(selectedKeys, info) {
    setState(
      {
        selectedKeys,
        page: {
          current: 1,
          pageSize: 15,
        },
      },
      () => {
        fetchData();
      }
    );
  }

  function onTableChange(changePage, changeFilters) {
    if (JSON.stringify(filters) !== JSON.stringify(changeFilters)) {
      changePage.current = 1;
    }
    setState({
      page: {
        ...state.page,
        current: changePage.current,
      },
    });
    set_filters(changeFilters);
  }

  function modal() {
    set_visible(!visible);
    if (!visible) {
      // userData()
      fetchStaffData();
    } else {
      setState({
        dataForUpdate: null,
        scrollPage: 1,
        userDatas: [],
      });
    }
  }

  function createUser() {
    form.validateFields((err, data) => {
      if (state.dataForUpdate) {
        data.id = state.dataForUpdate.id;
      }
      setState({
        btnloading: true,
      });
      if (!err) {
        window.g_appbase.get_app_token(token => {
          let formData = new FormData();
          formData.append('id', data.id || '');
          formData.append('truename', data.truename || '');
          formData.append('username', data.username || userid);
          formData.append('mobile', data.mobile || '');
          formData.append('sex', data.sex || '');
          formData.append('position', data.position || '');
          formData.append('user_range', data.user_range || '');
          formData.append('department_range', data.department_range || '');
          formData.append('role_id', data.role_id || '');
          formData.append('allow_login_ip', data.allow_login_ip || '');
          window.g_postFetch(window.g_api.add_user_url + '?token=' + token, formData, res => {
            if (res.code == 0) {
              message.success(res.msg);
              set_visible(false);
              fetchData(state.page, state.pageSize);
              setState({
                btnloading: false,
              });
            } else {
              message.error(res.msg);
              setState({
                btnloading: false,
              });
            }
          });
        });
      } else {
        setState({
          btnloading: false,
        });
      }
    });
  }

  function fetchStaffData() {
    window.g_request(
      {
        url: '/api/user_new/permit_member_list',
        body: {
          pageno: state.scrollPage,
          limit: 200,
          is_addressbook: 1,
          department_id: state.topKey,
          keyword: state.keyword,
        },
      },
      res => {
        setState({
          userDatas: [...state.userDatas, ...res.data],
        });
        set_userTotal(res.total);
      }
    );
  }

  // const userData = _.debounce((value) => {
  //   window.g_appbase.get_app_token((token) => {
  //     let formData = new FormData()
  //     formData.append('pageno', state.scrollPage)
  //     formData.append('limit', 15)
  //     formData.append('is_addressbook', 1)
  //     formData.append('department_id', state.topKey)
  //     formData.append('keyword', value || '')
  //     window.g_postFetch(window.g_api.permit_member_list + '?token=' + token, formData, ((res) => {
  //       setState({
  //         userDatas: [...state.userDatas, ...res.data]
  //       })
  //       set_userTotal(res.total)
  //     }))
  //   })
  // }, 500)

  function onSelectScroll(e) {
    e.persist();
    if (state.scrollPage * 200 >= userTotal) return false;
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      const nextScrollPage = state.scrollPage + 1;
      setState(
        {
          scrollPage: nextScrollPage,
        },
        () => {
          fetchStaffData();
        }
      );
    }
  }

  function fetchDataForUpdate(id, user_id, chatid, is_sys_user) {
    if (is_sys_user == 0) {
      message.warn('请先设为系统用户');
      return false;
    }
    set_userid(user_id || null);
    window.g_appbase.get_app_token(token => {
      let formData = new FormData();
      formData.append('id', id ? id : chatid);
      formData.append('type', id ? 0 : 1);
      window.g_postFetch(window.g_api.user_detail_url + '?token=' + token, formData, res => {
        setState({
          dataForUpdate: res.data,
          userDatas: res.data.auth_users || [],
        });
        modal();
      });
    });
  }

  function tabsChange(key) {
    if (loading) {
      return false;
    }
    set_tabkey(key);
  }

  function syncClick() {
    window.g_appbase.get_app_token(token => {
      let formData = new FormData();
      window.g_postFetch(window.g_api.sync_address_book_url + '?token=' + token, formData, res => {
        if (res.code == 0) {
          message.success(res.msg);
        } else {
          message.error(res.msg);
        }
      });
    });
  }

  function showmodal(data) {
    setState({
      modalVisible: true,
      dataForUpdate: data,
    });
  }

  function onExpand(expandedKeys) {
    setState({
      expandedKeys,
      // autoExpandParent: false,
    });
  }

  function pageChange(page) {
    setState({
      page,
    });
  }

  const columns = [
    {
      title: '成员',
      dataIndex: 'truename',
      key: 'truename',
      fixed: 'left',
      width: 250,
      // ellipsis: true,
      render: (text, item) => (
        <div className="truename">
          <img src={item.avatar} alt="" />
          <div className="trueright">
            <p>{item.truename}</p>
            {/* <p>共{item.customer_count}个客户</p> */}
          </div>
        </div>
      ),
      ...filterSearch({ placeholder: '请输入成员名称' }),
    },
    {
      title: '所在部门',
      width: 320,
      dataIndex: 'department_name',
      key: 'department_name',
      render: department_arr =>
        department_arr
          ? department_arr.map((item, index) => (
              <Tooltip key={index} placement="right" title={item.is_leader === 1 ? '领导' : '员工'}>
                <div style={{ fontSize: '13px', color: '#333' }}>{item.name}</div>
              </Tooltip>
            ))
          : '-',
    },
    {
      title: '角色',
      dataIndex: 'title',
      key: 'title',
      width: 100,
      render: role => <span>{role || '-'}</span>,
    },
    {
      title: '授权状态',
      dataIndex: 'is_sys_user',
      key: 'is_sys_user',
      width: 100,
      render: is_sys_user =>
        is_sys_user > 0 ? (
          <div>
            <CheckCircleFilled style={{ color: '#87d068', paddingRight: 5 }} />
            <span>已授权</span>
          </div>
        ) : (
          <div>
            <CheckCircleFilled style={{ paddingRight: 5 }} />
            <span>未授权</span>
          </div>
        ),
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { value: '0', text: '正常' },
        { value: '2', text: '禁用' },
      ],
      width: 100,
      filterMultiple: false,
      render: status =>
        status == '0' ? <Tag color="#87d068">正常</Tag> : <Tag color="red">禁用</Tag>,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: 240,
      fixed: 'right',
      render: (text, item) => (
        <div>
          <Button
            disabled={item.is_sys_user != 0 ? true : false}
            type="link"
            onClick={() => fetchDataForUpdate(item.suid, item.userid, item.chatid)}
          >
            设为系统用户
          </Button>
          <Button
            type="link"
            onClick={() =>
              fetchDataForUpdate(item.suid, item.userid, item.chatid, item.is_sys_user)
            }
          >
            修改
          </Button>
          {item.status == 2 ? (
            <Button type="link" onClick={() => updateStatus(item.suid, '0')}>
              启用
            </Button>
          ) : (
            <Button type="link" onClick={() => updateStatus(item.suid, '2')}>
              禁用
            </Button>
          )}
        </div>
      ),
    },
  ];

  const usercolumns = [
    {
      title: '用户名称',
      dataIndex: 'truename',
      key: 'truename',
      ...filterSearch({ placeholder: '请输入成员名称' }),
    },
    {
      title: '用户帐号',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '性别',
      dataIndex: 'sex',
      render: (text, record, index) => {
        if (text == '1') {
          return '男';
        } else if (text == '0') {
          return '女';
        } else {
          return '未知';
        }
      },
      key: 'sex',
      width: 100,
    },
    {
      title: '职务',
      dataIndex: 'position',
      key: 'position',
      render: (text, record, index) => {
        return <span dangerouslySetInnerHTML={{ __html: text ? text : '-' }}></span>;
      },
      width: 100,
    },
    {
      title: '角色',
      dataIndex: 'role',
      key: 'role',
      render: role => <span>{role || '-'}</span>,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { value: '0', text: '正常' },
        { value: '2', text: '禁用' },
      ],
      filterMultiple: false,
      render: status =>
        status == '0' ? <Tag color="#87d068">正常</Tag> : <Tag color="red">禁用</Tag>,
    },
    {
      title: '最后登录时间',
      dataIndex: 'last_login_time',
      key: 'last_login_time',
      render: (text, record, index) =>
        text != '0' ? dayjs.unix(text).format('YYYY-MM-DD HH:mm') : '-',
      width: '200px',
    },
    {
      title: '最后登录IP',
      dataIndex: 'last_login_ip',
      key: 'last_login_ip',
      render: (text, record, index) => {
        return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
      },
      width: '200px',
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text, item) => (
        <div>
          {window.g_checkRole(27) && (
            <Button type="link" onClick={() => fetchDataForUpdate(item.id)}>
              修改
            </Button>
          )}
          {window.g_checkRole(28) ? (
            item.status == -2 ? (
              <Button type="link" onClick={() => updateStatus(item.id, '0')}>
                启用
              </Button>
            ) : (
              <Button type="link" onClick={() => updateStatus(item.id, '-2')}>
                禁用
              </Button>
            )
          ) : null}
          {/* <Button type='link'>查看详情</Button> */}
        </div>
      ),
    },
  ];

  const { getFieldDecorator } = form;
  const tProps = {
    treeData: department,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    searchPlaceholder: '请选择部门',
    style: {
      width: '100%',
    },
  };
  const { dataForUpdate } = state;
  return (
    <div className="page-content">
      <div className="user">
        <div className="action">
          {window.g_checkRole(26) && (
            <Button type="primary" onClick={() => modal()}>
              添加成员
            </Button>
          )}
          {/* <Button style={{ margin: '0 10px' }} onClick={syncClick}>
            同步
          </Button> */}
          <span style={{ margin: '0 10px' }}>共{total}个成员</span>
        </div>
        <div className="container">
          <Tabs type="card" defaultActiveKey={tabkey} onChange={tabsChange} animated={true}>
            {tabs.map(item => (
              <TabPane tab={item.title} key={item.key}></TabPane>
            ))}
          </Tabs>
          <div style={{ display: 'flex' }}>
            {/* {tabkey === '1' && (
              <div className="left">
                <p className="title">部门信息</p>
                <DirectoryTree
                  multiple
                  expandedKeys={state.expandedKeys}
                  onSelect={onSelect}
                  onExpand={onExpand}
                  treeData={department}
                ></DirectoryTree>
              </div>
            )} */}

            <div className="right">
              <Table
                rowKey={item => item.suid}
                columns={tabkey === '1' ? columns : usercolumns}
                dataSource={list}
                loading={loading}
                locale={{
                  emptyText: <div>{!loading && list.length <= 0 && <Empty />}</div>,
                }}
                pagination={false}
                scroll={{ y: 650 }}
                onChange={onTableChange}
              />
              {tabkey === '1' && (
                <Pagination page={state.page} total={total} onSelect={pageChange} />
              )}
            </div>
          </div>
        </div>
        <Drawer
          title={dataForUpdate ? '修改' : '新增'}
          placement="right"
          closable={false}
          visible={visible}
          onClose={modal}
          width={650}
          destroyOnClose
        >
          <Form wrapperCol={{ span: 16 }} layout="vertical">
            <Form.Item label="员工姓名">
              {getFieldDecorator('truename', {
                rules: [
                  {
                    required: true,
                    message: '请输入员工姓名!',
                  },
                ],
                initialValue: dataForUpdate ? dataForUpdate.truename : '',
              })(<Input maxLength={20} placeholder="请输入员工姓名" />)}
            </Form.Item>
            <Form.Item label="手机号">
              {getFieldDecorator('mobile', {
                initialValue: dataForUpdate ? dataForUpdate.mobile : '',
              })(<Input maxLength={20} placeholder="请输入用户手机号" />)}
            </Form.Item>
            <Form.Item label="角色">
              {getFieldDecorator('role_id', {
                rules: [
                  {
                    required: true,
                    message: '请选择角色!',
                  },
                ],
                initialValue: dataForUpdate
                  ? state.roles.findIndex(item => dataForUpdate.role_id) != -1
                    ? dataForUpdate.role_id
                    : dataForUpdate.role_name
                  : '',
              })(
                <Select>
                  {state.roles.map(item => (
                    <Option value={item.id}>{item.title}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {/* {
            tabkey != '1' && 
          } */}
            {console.log('state.roles', state.userDatas)}
            <Form.Item label="账号">
              {getFieldDecorator('username', {
                initialValue: dataForUpdate ? dataForUpdate.username : userid,
              })(
                <Input
                  disabled={userid ? true : false}
                  maxLength={20}
                  placeholder="请输入用户账号"
                />
              )}
            </Form.Item>
            <Form.Item label="性别">
              {getFieldDecorator('sex', {
                initialValue: dataForUpdate ? dataForUpdate.sex : '',
              })(
                <Select>
                  <Option value="1">男</Option>
                  <Option value="0">女</Option>
                  <Option value="2">未知</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="职务">
              {getFieldDecorator('position', {
                initialValue: dataForUpdate ? dataForUpdate.position : '',
              })(<Input maxLength={20} placeholder="请输入用户职务" />)}
            </Form.Item>
            <Form.Item label="允许登录ip(英文逗号分隔，不填不限制)">
              {getFieldDecorator('allow_login_ip', {
                initialValue: dataForUpdate ? dataForUpdate.allow_login_ip : '',
              })(<Input.TextArea placeholder="请输入允许登录ip" />)}
            </Form.Item>
            {/* <Form.Item label="可查看部门">
              {getFieldDecorator('department_range', {
                initialValue: dataForUpdate ? dataForUpdate.department_range : undefined,
              })(<TreeSelect {...tProps} />)}
            </Form.Item>
            <Form.Item label="可查看员工">
              {getFieldDecorator('user_range', {
                initialValue: dataForUpdate ? dataForUpdate.user_range : undefined,
              })(
                <Select
                  mode="multiple"
                  // labelInValue
                  onSearch={value => setState({ keyword: value, userDatas: [], scrollPage: 1 })}
                  filterOption={false}
                  onInputKeyDown={e => {
                    if (e.code === 'Enter') {
                      fetchStaffData();
                    }
                  }}
                  showSearch
                  placeholder="回车搜索员工"
                  optionFilterProp="label"
                  onPopupScroll={e => onSelectScroll(e)}
                >
                  {state.userDatas.map(item => (
                    <Option key={item.chatid} label={item.truename}>
                      {item.truename}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item> */}
            <Form.Item>
              <Button type="primary" loading={state.btnloading} block onClick={createUser}>
                提交
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
        {/* <Modal
        visible={state.modalVisible}
        width={700}
      >
        <Descriptions title="用户详情" bordered>
          <Descriptions.Item label="用户名称">{dataForUpdate && dataForUpdate.truename}</Descriptions.Item>
          <Descriptions.Item label="性别">{dataForUpdate.six}</Descriptions.Item>
          <Descriptions.Item label="手机号">YES</Descriptions.Item>
          <Descriptions.Item label="账号">2018-04-24 18:00:00</Descriptions.Item>
          <Descriptions.Item label="职务">
            2019-04-24 18:00:00
          </Descriptions.Item>
          <Descriptions.Item label="授权部门" span={3}>
          </Descriptions.Item>
          <Descriptions.Item label="授权范围">$80.00</Descriptions.Item>
          <Descriptions.Item label="最后登录时间">$20.00</Descriptions.Item>
          <Descriptions.Item label="最后登录ip">$60.00</Descriptions.Item>
          <Descriptions.Item label="允许登录ip">$60.00</Descriptions.Item>
        </Descriptions>
      </Modal> */}
      </div>
    </div>
  );
}
export default Form.create()(User);
