import React from 'react';
import {Divider} from 'antd';
import './index.scss'

class Header extends React.Component {
  render() {
    return (
      <div className='header'>
        <div className='info'>
          <Divider type='vertical' />{this.props.title}
        </div>
      </div>
    )
  }
}
export default Header