import React, { useState, useEffect } from 'react';
import './index.scss';
import {
  PlusOutlined,
  CloudUploadOutlined,
  DoubleRightOutlined,
  SendOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Select,
  Cascader,
  Upload,
  Drawer,
  Row,
  Col,
  message,
  Steps,
  Space,
  Table,
  Popconfirm,
  Divider,
  Alert,
  Modal,
} from 'antd';
import { getAllCategory } from './util';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

function Miniprogram() {
  const steps = [
    {
      title: '创建抖音开发者账号',
      key: '创建抖音开发者账号',
      icon: <SendOutlined />,
    },
    {
      title: '创建小程序并授权',
      key: '创建小程序并授权',
      icon: <DoubleRightOutlined />,
    },
    {
      title: '创建营销活动(如：标准留资)',
      key: '创建营销活动(如：标准留资)',
      icon: <DoubleRightOutlined />,
    },
    {
      title: '提交代码并送审',
      key: '提交代码并送审',
      icon: <DoubleRightOutlined />,
    },
    {
      title: '发布小程序',
      key: '发布小程序',
    },
  ];

  const columns = [
    {
      title: '一级类目',
      dataIndex: 'level1',
      key: 'level1',
    },
    {
      title: '二级类目',
      dataIndex: 'level2',
      key: 'level2',
    },
    {
      title: '三级类目',
      dataIndex: 'level3',
      key: 'level3',
    },
    {
      title: '状态',
      dataIndex: 'app_category_audit_state',
      key: 'app_category_audit_state',
      render: (_, record, index) => (
        <div>
          {record.app_category_audit_state === '1'
            ? '审核中'
            : record.app_category_audit_state === '2'
            ? '审核通过'
            : record.app_category_audit_state === '3'
            ? '审核拒绝'
            : ''}
        </div>
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <Popconfirm
            title="确认删除此条数据?"
            onConfirm={() => {
              deleteCategory(record, index);
            }}
            okText="确认"
            cancelText="取消"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const codeSubmitColumns = [
    {
      title: '小程序名称',
      dataIndex: 'app_name',
      key: 'app_name',
    },
    {
      title: '模版ID',
      dataIndex: 'template_id',
      key: 'template_id',
    },
    {
      title: '版本信息',
      key: 'version_info',
      render: (_, record, index) => (
        <>
          <div>版本编号：{record.template.user_version}</div>
          <div>版本描述：{record.template.user_desc}</div>
          <div>
            版本时间：{moment(record.template.create_time * 1000).format('YYYY-MM-DD HH:mm')}
          </div>
        </>
      ),
    },
    {
      title: '操作类型',
      key: 'type',
      render: (_, record, index) => (
        <span>
          {record.type === '1' ? '提交代码' : record.type === '2' ? '提审代码' : '发布代码'}
        </span>
      ),
    },
    {
      title: '状态',
      key: 'status',
      render: (_, record, index) => {
        if (record.type === '1' || record.type === '3') {
          return (
            <span>{record.status === '1' ? '成功' : record.status === '2' ? '失败' : ''}</span>
          );
        } else if (record.type === '2') {
          return (
            <span>
              {record.status === '1'
                ? '审核中'
                : record.status === '2'
                ? '提审失败'
                : record.status === '3'
                ? '审核通过'
                : record.status === '4'
                ? '审核拒绝'
                : ''}
            </span>
          );
        }
      },
    },
    {
      title: '执行信息',
      key: 'reason',
      render: (_, record, index) => <div>{record.reason ? record.reason : '-'}</div>,
    },
    {
      title: '操作人',
      key: 'truename',
      render: (_, record, index) => <span>{record.user.truename}</span>,
    },
    {
      title: '操作时间',
      dataIndex: 'postdate',
      key: 'postdate',
    },
  ];

  const statusObj = {
    0: '未发布',
    1: '已发布',
    2: '已下线',
  };

  const [open, setOpen] = useState(false);
  const [openCodeList, setOpenCodeList] = useState(false);
  const [data, setData] = useState([]);
  const [mini, setMini] = useState(null);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [options, setOptions] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [currentMiniAppInfo, setCurrentMiniAppInfo] = useState(null);
  const [codeSubmitData, setCodeSubmitData] = useState([]);
  const [codeSubmitTotal, setCodeSubmitTotal] = useState(0);
  const [currentCodeSubmitPage, setCurrentCodeSubmitPage] = useState(0);
  const [openPrevidwModal, setOpenPrevidwModal] = useState(false);
  const [isAuditTypePreview, setIsAuditTypePreview] = useState(false);
  const [previewQrCodeUrl, setPreviewQrCodeUrl] = useState('');
  const [uploadCertificationInfo, setUploadCertificationInfo] = useState({});
  const [uploadCertification, setUploadCertification] = useState(null);
  const [uploadCertificationFilesInfo, setUploadCertificationFilesInfo] = useState({});

  useEffect(() => {
    loadData();
    const interval = setInterval(async () => {
      await loadData();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(async () => {
    if (currentCodeSubmitPage) {
      lockCodeSubmitList();
    }
  }, [currentCodeSubmitPage]);

  const loadData = async () => {
    try {
      let res = await window.g_dyFetch({
        url: window.g_api.dy_get_app_list_url,
        method: 'GET',
      });
      setData(res.data);
    } catch (error) {
      message.error(error.msg);
    }
  };

  const getAuthUrl = async type => {
    let formData = new FormData();
    formData.append('pre_auth_code_type', type);
    if (type === 2) {
      if (form.getFieldValue('app_name')) {
        formData.append('app_name', form.getFieldValue('app_name'));
      }
      if (form.getFieldValue('app_icon')) {
        formData.append('app_icon', form.getFieldValue('app_icon'));
      }
    }
    try {
      const { data } = await window.g_dyFetch({
        url: window.g_api.dy_get_authorization_url,
        method: 'POST',
        body: formData,
      });
      return data;
    } catch (error) {
      message.error(error.msg);
    }
  };

  const getMiniInfo = async (item, isGetTemplte = true) => {
    try {
      const { data } = await window.g_dyFetch({
        url: window.g_api.dy_get_app_info_url,
        method: 'GET',
        body: {
          authorization_appid: item.app_id,
        },
      });
      // logo
      if (data.app_icon) {
        setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: data.app_icon,
          },
        ]);
      }
      // 类目
      if (data.category?.length) {
        data.category.forEach(c => {
          let list = c.app_category_name.split('-');
          c.level1 = list[0];
          c.level2 = list[1];
          c.level3 = list[2];
        });
      }
      setMini(data);
      setUploadCertification(null);
      setUploadCertificationFilesInfo({});
      form.setFieldsValue(data);
      if (data.submit_list?.template_id) {
        form.setFieldValue('template', Number(data.submit_list.template_id));
      }

      // 获取所有类目
      let categories = await getAllCategory(data);
      setOptions(categories);
      if (isGetTemplte) {
        getTemplateList();
      }
    } catch (error) {
      console.log(error);
      message.error(error.msg);
    }
  };

  // 获取模板
  const getTemplateList = async () => {
    try {
      let { data } = await window.g_dyFetch({
        url: window.g_api.dy_get_tpl_list_url,
        method: 'GET',
      });
      setTemplates(
        data.map(item => {
          return {
            label: item.user_desc,
            value: item.template_id,
            ...item,
          };
        })
      );
    } catch (error) {
      message.error(error.msg);
    }
  };
  // 校验小程序名称
  const verify = async () => {
    if (mini.app_name === form.getFieldValue('app_name')) {
      message.warning('当前小程序名称没有更改');
      return;
    }
    try {
      let { msg } = await window.g_dyFetch({
        url: window.g_api.dy_check_app_name_url,
        method: 'GET',
        body: {
          authorization_appid: mini.app_id,
          app_name: form.getFieldValue('app_name'),
        },
      });
      message.success(msg);
    } catch (error) {
      message.error(error.msg);
    }
  };

  // 上传小程序图标
  const uploadItem = () => {
    const beforeUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('请选择图片文件!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('图片大小不能超过2MB!');
      }
      return isJpgOrPng && isLt2M;
    };
    const customRequest = async options => {
      let formData = new FormData();
      if (mini && mini.app_id) {
        formData.append('authorization_appid', mini.app_id);
      }
      formData.append('material_type', 1);
      formData.append('material_file', options.file);

      try {
        let res = await window.g_dyFetch({
          url: window.g_api.dy_upload_pic_material_url,
          method: 'POST',
          body: formData,
        });
        options.onSuccess(res, options.file);
      } catch (error) {
        message.error(error.msg);
      }
    };
    return (
      <>
        <Upload
          listType="picture-card"
          maxCount={1}
          fileList={fileList}
          beforeUpload={beforeUpload}
          customRequest={customRequest}
          onChange={({ fileList: newFileList }) => {
            console.log('newFileList:', newFileList);
            setFileList(newFileList);
            if (newFileList.length === 1 && newFileList[0].response?.data.path) {
              form.setFieldValue('app_icon', newFileList[0].response.data.path);
            }
          }}
        >
          <div>
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              点击上传
            </div>
          </div>
        </Upload>
        <div style={{ margin: '10px 0' }}>图片大小需要小于2M，尺寸建议200px*200px</div>
        <Button type="primary" onClick={editAppIcon}>
          确认修改图标
        </Button>
      </>
    );
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOpenCodeListClose = () => {
    setCurrentCodeSubmitPage(0);
    setOpenCodeList(false);
  };

  const lockCodeSubmitList = async () => {
    if (currentMiniAppInfo?.app_id) {
      // let formData = new FormData();
      // formData.append('appid', item?.app_id);
      // formData.append('page', currentCodeSubmitPage);

      try {
        let { data } = await window.g_dyFetch({
          url: window.g_api.code_submit_list_url,
          method: 'GET',
          body: {
            page: currentCodeSubmitPage,
            pagesize: 10,
            appid: currentMiniAppInfo.app_id,
          },
        });
        setCodeSubmitData(data.list);
        setCodeSubmitTotal(Number(data.total));
      } catch (error) {
        message.error(error.msg);
      }
      setOpenCodeList(true);
    }
  };

  // 创建/授权
  const onFinish = async () => {
    // 创建
    let url = await getAuthUrl(2);
    if (url) {
      window.open(url);
      setOpen(false);
    }
  };

  const addCategory = async (value, selectedOptions) => {
    setUploadCertificationFilesInfo({});
    setUploadCertification(null);
    const certification = selectedOptions[selectedOptions.length - 1].certification;
    if (certification.list.length) {
      // console.log('需要上传资质文件：', certification);
      setUploadCertificationInfo({
        value,
        selectedOptions,
      });
      setUploadCertification(certification);
      let tempInfo = {};
      certification.list.forEach(item => {
        tempInfo = {
          [item.id]: {},
        };
      });
      // console.log('tempInfo:', tempInfo);
      setUploadCertificationFilesInfo(tempInfo);
    } else {
      confirm({
        title: '确定要新增这个类目吗？',
        icon: <ExclamationCircleOutlined />,
        content: '',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let formData = new FormData();
            formData.append('authorization_appid', mini.app_id);
            formData.append(
              'categoryInfo',
              JSON.stringify({
                categoryInfo: [
                  {
                    category: value.join(','),
                    category_name: selectedOptions
                      .map(item => {
                        return item.name;
                      })
                      .join('-'),
                  },
                ],
              })
            );

            try {
              let { code, data, msg } = await window.g_dyFetch({
                url: window.g_api.dy_add_categories_url,
                method: 'POST',
                body: formData,
              });
              console.log(code, data, msg);
              getMiniInfo(mini, false);
              message.success('新增成功');
              resolve();
            } catch (error) {
              console.log('error:', error);
              message.error(error.msg);
              reject();
            }
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    }
  };

  // 新增需要上传资质文件的类目
  const confirmAddCategory = async () => {
    console.log('uploadCertificationFilesInfo:', uploadCertificationFilesInfo);
    let flag = true;
    let tempList = [];
    for (const key in uploadCertificationFilesInfo) {
      if (Object.hasOwnProperty.call(uploadCertificationFilesInfo, key)) {
        const item = JSON.parse(JSON.stringify(uploadCertificationFilesInfo[key]));
        delete item.files;
        tempList.push(item);
      }
    }
    console.log('tempList:', tempList);
    tempList.forEach(item => {
      if (item.path === '') {
        flag = false;
      }
    });
    if (!flag) {
      message.warning('请先上传类目必要的资质文件');
      return;
    }
    let formData = new FormData();
    formData.append('authorization_appid', mini.app_id);
    formData.append(
      'categoryInfo',
      JSON.stringify({
        categoryInfo: [
          {
            category: uploadCertificationInfo.value.join(','),
            category_name: uploadCertificationInfo.selectedOptions
              .map(item => {
                return item.name;
              })
              .join('-'),
            categoryCert: tempList,
          },
        ],
      })
    );

    try {
      let { code, data, msg } = await window.g_dyFetch({
        url: window.g_api.dy_add_categories_url,
        method: 'POST',
        body: formData,
      });
      console.log(code, data, msg);
      getMiniInfo(mini, false);
      message.success('新增成功');
    } catch (error) {
      console.log('error:', error);
      message.error(error.msg);
    }
  };

  // 删除类目
  const deleteCategory = async (record, index) => {
    let formData = new FormData();
    formData.append('authorization_appid', mini.app_id);
    formData.append('categories', record.app_category);

    let { code, data, msg } = await window.g_dyFetch({
      url: window.g_api.dy_del_categories_url,
      method: 'POST',
      body: formData,
    });

    if (code === 0) {
      let temp = { ...mini };
      temp.category.splice(index, 1);
      setMini(null);
      setTimeout(() => {
        setMini(temp);
      });

      message.success('删除成功');
    } else {
      message.error(msg);
    }
  };

  const submitCode = async () => {
    const template = form.getFieldValue('template');
    if (template) {
      let formData = new FormData();
      formData.append('authorization_appid', mini.app_id);
      formData.append('template_id', template);
      templates.forEach(item => {
        if (item.template_id === template) {
          formData.append('user_desc', item.user_desc);
          formData.append('user_version', item.user_version);
        }
      });
      try {
        await window.g_dyFetch({
          url: window.g_api.dy_submit_code_url,
          method: 'POST',
          body: formData,
        });
        let temp = { ...mini };
        temp.status = '1';
        temp.upload_status = '1';
        setMini(null);
        setTimeout(() => {
          setMini(temp);
        });
        message.success('提交成功');
      } catch (error) {
        message.error(error.msg);
      }
    } else {
      message.warning('请选择模板');
    }
  };

  // 获取预览二维码
  const getPreviewQrCode = async info => {
    let formData = new FormData();
    formData.append('authorization_appid', mini.app_id);
    formData.append('version', info.version);
    formData.append('path', info.path);
    let res = await window.g_dyFetch({
      url: window.g_api.get_preview_qrcode_url,
      method: 'POST',
      body: formData,
    });
    setPreviewQrCodeUrl(res.data);
  };

  // 确认提审
  const affirmAudit = async () => {
    let formData = new FormData();
    formData.append('authorization_appid', mini.app_id);
    try {
      await window.g_dyFetch({
        url: window.g_api.dy_audit_code_url,
        method: 'POST',
        body: formData,
      });
      let temp = { ...mini };
      temp.status = '2';
      temp.audit_status = '1';
      setMini(null);
      setTimeout(() => {
        setMini(temp);
      });
      message.success('提审成功');
      setOpenPrevidwModal(false);
    } catch (error) {
      message.error(error.msg);
    }
  };

  // 取消提审
  const cancelAudit = () => {
    setOpenPrevidwModal(false);
  };

  // 发布代码
  const publishApp = async () => {
    let formData = new FormData();
    formData.append('authorization_appid', mini.app_id);

    try {
      await window.g_dyFetch({
        url: window.g_api.dy_release_code_url,
        method: 'POST',
        body: formData,
      });

      let temp = { ...mini };
      temp.status = '3';
      setMini(null);
      setTimeout(() => {
        setMini(temp);
      });
      message.success('发布成功');
    } catch (error) {
      message.error(error.msg);
    }
  };
  // 修改名称
  const editAppName = async () => {
    if (mini.app_name === form.getFieldValue('app_name')) {
      message.warning('当前小程序名称没有更改');
      return;
    }
    let formData = new FormData();
    formData.append('authorization_appid', mini.app_id);
    formData.append('new_name', form.getFieldValue('app_name'));
    try {
      let { msg } = await window.g_dyFetch({
        url: window.g_api.dy_modify_app_url,
        method: 'POST',
        body: formData,
      });
      message.success(msg);
    } catch (error) {
      message.error(error.msg);
    }
  };
  // 修改简介
  const editAppIntro = async () => {
    if (mini.app_intro === form.getFieldValue('app_intro')) {
      message.warning('当前小程序简介没有更改');
      return;
    }
    let formData = new FormData();
    formData.append('authorization_appid', mini.app_id);
    formData.append('new_intro', form.getFieldValue('app_intro'));
    // formData.append('new_icon_path', form.getFieldValue('app_icon'));
    try {
      let { msg } = await window.g_dyFetch({
        url: window.g_api.dy_modify_app_url,
        method: 'POST',
        body: formData,
      });
      message.success(msg);
    } catch (error) {
      message.error(error.msg);
    }
  };

  // 修改图标
  const editAppIcon = async () => {
    if (mini.app_icon === form.getFieldValue('app_icon')) {
      message.warning('当前小程序图标没有更改');
      return;
    }
    let formData = new FormData();
    formData.append('authorization_appid', mini.app_id);
    formData.append('new_icon_path', form.getFieldValue('app_icon'));
    try {
      let { msg } = await window.g_dyFetch({
        url: window.g_api.dy_modify_app_url,
        method: 'POST',
        body: formData,
      });
      message.success(msg);
    } catch (error) {
      message.error(error.msg);
    }
  };

  // 类目资质上传之前的钩子
  const categoryCertBeforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请选择图片文件!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('图片大小不能超过2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  // 类目资质上传
  const categoryCertCustomRequest = async options => {
    let formData = new FormData();
    if (mini && mini.app_id) {
      formData.append('authorization_appid', mini.app_id);
    }
    formData.append('material_type', 2);
    formData.append('material_file', options.file);

    try {
      let res = await window.g_dyFetch({
        url: window.g_api.dy_upload_pic_material_url,
        method: 'POST',
        body: formData,
      });
      options.onSuccess(res, options.file);
    } catch (error) {
      message.error(error.msg);
    }
  };

  return (
    <div className="miniprogram">
      <div className="header">
        <div className="title">小程序总计{data.length || 0}个</div>
      </div>
      <div className="step-wrap">
        <Steps status="finish" current={4} items={steps} />
      </div>
      <div className="flex-wrap">
        {data.map(item => {
          return (
            <div
              className="flex-item"
              key={item.id}
              onClick={() => {
                if (window.g_checkRole(37)) {
                  setOpen(true);
                  getMiniInfo(item);
                } else {
                  message.error('当前账号没有编辑权限');
                }
              }}
            >
              <img className="app-icon" src={item.app_icon} />
              <div className="item-info">
                <div className="item-info-title">{item.app_name}</div>
                <div className="item-info-title">AppID:{item.app_id}</div>
                <div className="item-info-time">当前状态: {statusObj[item.app_state]}</div>
                <div className="item-info-time">
                  {item.update_time !== '0' ? '发布' : '添加'}时间:
                  {item.update_time !== '0'
                    ? window.g_getDayTime(item.update_time)
                    : window.g_getDayTime(Number(item.add_time) * 1000)}
                </div>
              </div>
              <div
                className="upload-icon"
                onClick={e => {
                  e.stopPropagation();
                  setCurrentCodeSubmitPage(1);
                  setCurrentMiniAppInfo(item);
                }}
              >
                <CloudUploadOutlined style={{ fontSize: 20, color: '#2B7EFB' }} />
              </div>
            </div>
          );
        })}
        {window.g_checkRole(36) && (
          <div
            className="flex-item-new"
            onClick={() => {
              setFileList([]);
              setMini(null);
              form.resetFields();
              setOpen(true);
            }}
          >
            <PlusOutlined />
          </div>
        )}
      </div>
      <Drawer
        title="代码日志"
        placement="right"
        width={1200}
        onClose={onOpenCodeListClose}
        open={openCodeList}
      >
        <Table
          rowKey="id"
          columns={codeSubmitColumns}
          dataSource={codeSubmitData}
          pagination={{
            total: codeSubmitTotal,
            position: ['bottomLeft'],
            pageSize: 10,
            onChange: (page, pageSize) => {
              setCurrentCodeSubmitPage(page);
            },
          }}
        />
      </Drawer>
      <Drawer
        title={mini ? '小程序详情' : '新增小程序'}
        placement="right"
        width={1000}
        onClose={onClose}
        open={open}
      >
        {mini && mini.submit_list && (
          <>
            <h3 style={{ marginBottom: 24 }}>小程序最新操作状态</h3>
            {mini?.submit_list.type === '1' && (
              <h2>
                提交：
                {mini.submit_list.status === '1'
                  ? '提交成功'
                  : mini.submit_list.status === '2'
                  ? '提交失败'
                  : ''}
              </h2>
            )}
            {mini?.submit_list.type === '2' && (
              <h2>
                提审：
                {mini.submit_list.status === '1'
                  ? '审核中'
                  : mini.submit_list.status === '2'
                  ? '提审失败'
                  : mini.submit_list.status === '3'
                  ? '审核通过'
                  : mini.submit_list.status === '4'
                  ? '审核拒绝'
                  : ''}
              </h2>
            )}
            {mini?.submit_list.type === '3' && (
              <h2>
                发布：
                {mini.submit_list.status === '1'
                  ? '发布成功'
                  : mini.submit_list.status === '2'
                  ? '发布失败'
                  : ''}
              </h2>
            )}
            <Divider />
          </>
        )}
        {mini?.audit_status === '2' && (
          <>
            <h3 style={{ marginBottom: 24 }}>
              新版本审核已通过
              <Button type="primary" style={{ marginLeft: 10 }} onClick={publishApp}>
                立即发布
              </Button>
            </h3>
            <Divider />
          </>
        )}
        {mini?.audit_status === '3' && (
          <>
            <h3 style={{ marginBottom: 24 }}>新版本审核未通过，拒绝原因为：</h3>
            <p>{mini.reason}</p>
            <Divider />
          </>
        )}
        {mini && (
          <div>
            <h3>小程序的能力状态</h3>
            <p style={{ fontSize: 14, color: '#999', marginBottom: 24 }}>
              代码审核通过时会自动申请
            </p>
            <Row gutter={16}>
              <Col span={6}>
                <Alert
                  style={{ marginBottom: '10px' }}
                  message="短视频自主挂载能力"
                  type={
                    mini.video_status === '0'
                      ? 'info'
                      : mini.video_status === '1'
                      ? 'warning'
                      : mini.video_status === '2'
                      ? 'error'
                      : mini.video_status === '3'
                      ? 'success'
                      : ''
                  }
                  description={`当前状态：${
                    mini.video_status === '0'
                      ? '未开通'
                      : mini.video_status === '1'
                      ? '审核中'
                      : mini.video_status === '2'
                      ? '被拒绝'
                      : mini.video_status === '3'
                      ? '已开通'
                      : ''
                  }`}
                />
              </Col>
              <Col span={6}>
                <Alert
                  style={{ marginBottom: '10px' }}
                  message="直播自主挂载能力"
                  type={
                    mini.live_status === '0'
                      ? 'info'
                      : mini.live_status === '1'
                      ? 'warning'
                      : mini.live_status === '2'
                      ? 'error'
                      : mini.live_status === '3'
                      ? 'success'
                      : ''
                  }
                  description={`当前状态：${
                    mini.live_status === '0'
                      ? '未开通'
                      : mini.live_status === '1'
                      ? '审核中'
                      : mini.live_status === '2'
                      ? '被拒绝'
                      : mini.live_status === '3'
                      ? '已开通'
                      : ''
                  }`}
                />
              </Col>
              <Col span={6}>
                <Alert
                  style={{ marginBottom: '10px' }}
                  message="获取用户手机号能力"
                  type={
                    mini.phone_status === '0'
                      ? 'error'
                      : mini.phone_status === '1'
                      ? 'success'
                      : mini.phone_status === '2'
                      ? 'error'
                      : mini.phone_status === '3'
                      ? 'info'
                      : mini.phone_status === '4'
                      ? 'warning'
                      : ''
                  }
                  description={`当前状态：${
                    mini.phone_status === '0'
                      ? '被拒绝'
                      : mini.phone_status === '1'
                      ? '已开通'
                      : mini.phone_status === '2'
                      ? '已关闭'
                      : mini.phone_status === '3'
                      ? '未开通'
                      : mini.phone_status === '4'
                      ? '审核中'
                      : ''
                  }`}
                />
              </Col>
            </Row>
          </div>
        )}
        <Divider />
        <h3 style={{ marginBottom: 24 }}>小程序基本信息</h3>
        <Form
          form={form}
          labelCol={{
            span: 3,
          }}
          wrapperCol={{
            span: 21,
          }}
          layout="horizontal"
          onFinish={onFinish}
        >
          <Form.Item
            label="小程序名称"
            name="app_name"
            rules={[
              {
                required: true,
                message: '请输入小程序名称',
              },
            ]}
          >
            <Row gutter={8}>
              <Col span={16}>
                <Form.Item name="app_name">
                  <Input />
                </Form.Item>
              </Col>
              {mini && (
                <>
                  <Col span={4}>
                    <Button type="primary" style={{ width: '100%' }} onClick={verify}>
                      验证名称
                    </Button>
                  </Col>
                  <Col span={4}>
                    <Button type="primary" style={{ width: '100%' }} onClick={editAppName}>
                      修改名称
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Form.Item>
          {mini && (
            <Row style={{ marginBottom: 20 }} gutter={8}>
              <Col span={24}>
                <Form.Item label="小程序简介" name="app_intro">
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
              <Col offset={3} span={4}>
                <Button type="primary" style={{ width: '100%' }} onClick={editAppIntro}>
                  修改简介
                </Button>
              </Col>
            </Row>
          )}
          <Form.Item
            label="小程序logo"
            name="app_icon"
            rules={[
              {
                required: true,
                message: '请上传小程序logo',
              },
            ]}
          >
            {uploadItem()}
          </Form.Item>
          {!mini && (
            <Form.Item wrapperCol={{ offset: 3, span: 6 }}>
              <span>已有小程序,</span>
              <a
                onClick={async () => {
                  let url = await getAuthUrl(1);
                  window.open(url);
                  setOpen(false);
                }}
              >
                前往授权
              </a>
            </Form.Item>
          )}
          {!mini && (
            <Form.Item wrapperCol={{ offset: 3, span: 4 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          )}

          {mini && (
            <>
              <Divider />
              <Form.Item label="经营类目" name="category">
                <>
                  <Cascader options={options} onChange={addCategory}>
                    <Button type="primary" style={{ marginBottom: 15 }}>
                      新增经营类目
                    </Button>
                  </Cascader>
                  {uploadCertification && (
                    <>
                      <h3>{uploadCertification.explanation}</h3>
                      <div>
                        {uploadCertification.list.map((item, index) => {
                          return (
                            <>
                              <div key={index} style={{ marginBottom: 15 }}>
                                <p style={{ marginBottom: 8 }}>
                                  {item.id}：{item.name}
                                </p>
                                <Upload
                                  maxCount={1}
                                  listType="picture-card"
                                  showUploadList={true}
                                  fileList={
                                    uploadCertificationFilesInfo[Number(item.id)]?.files?.length
                                      ? uploadCertificationFilesInfo[Number(item.id)].files
                                      : []
                                  }
                                  beforeUpload={categoryCertBeforeUpload}
                                  customRequest={categoryCertCustomRequest}
                                  onChange={event => {
                                    let temp = JSON.parse(
                                      JSON.stringify(uploadCertificationFilesInfo)
                                    );
                                    temp[item.id] = {
                                      files: event.fileList,
                                      path: '',
                                      id: item.id,
                                      name: item.name,
                                    };
                                    if (
                                      event.fileList.length === 1 &&
                                      event.fileList[0].response?.data.path
                                    ) {
                                      temp[item.id].path = event.fileList[0].response.data.path;
                                    }
                                    setUploadCertificationFilesInfo(temp);
                                    // setFileList(newFileList);
                                    // newFileList[0].response?.data.path
                                  }}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      点击上传
                                    </div>
                                  </div>
                                </Upload>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <Button type="primary" onClick={confirmAddCategory}>
                        确定新增类目
                      </Button>
                      <Divider />
                    </>
                  )}
                  <Table columns={columns} dataSource={mini.category} rowKey="app_category" />
                </>
              </Form.Item>
              <Divider />
              <h3 style={{ marginBottom: 24 }}>代码管理</h3>
              <Form.Item label="代码模板" name="template">
                <Select placeholder="请选择代码模板" allowClear>
                  {templates.map(item => {
                    return (
                      <Option value={item.value} key={item.value}>
                        <div>
                          版本号：{item.user_version} - {item.label} - 更新时间：
                          {moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm')}
                        </div>
                        <div>版本描述：{item.user_desc}</div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="代码提交" wrapperCol={{ offset: 0, span: 6 }}>
                <Button type="primary" onClick={submitCode}>
                  提交代码
                </Button>
              </Form.Item>
              {(mini.status === '1' || (mini.status === '2' && mini.audit_status === '0')) && (
                <Form.Item label="代码提审" wrapperCol={{ offset: 0, span: 6 }}>
                  <Button
                    type="primary"
                    onClick={async () => {
                      setIsAuditTypePreview(true);
                      await getPreviewQrCode({
                        version: 'latest',
                        path: 'pages/preview/preview',
                      });
                      setOpenPrevidwModal(true);
                    }}
                  >
                    提审代码
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Drawer>
      <Modal
        title="请在抖音中扫码预览"
        open={openPrevidwModal}
        onOk={affirmAudit}
        onCancel={cancelAudit}
      >
        {isAuditTypePreview && (
          <h3 style={{ textAlign: 'center' }}>请预览无误后再点击'确认'按钮进行提审</h3>
        )}
        <div style={{ margin: '20px auto', textAlign: 'center' }}>
          <img src={previewQrCodeUrl} alt="" style={{ width: 200, height: 200 }} />
        </div>
      </Modal>
    </div>
  );
}

export default Miniprogram;
