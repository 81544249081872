//import VConsole from 'vconsole' //import vconsole

//import GlobalErrorReport from '../js/reporterror'

//环境切换  dev 开发环境 ，test：测试环境 pro：正式环境
var debug_env = 'pro';
//使用版本  saas saas版本  private私有化版本
var use_edition = 'saas';
// var use_edition = 'private';
var jsapi_debug = false;
var host = 'https://dyapi.wininsales.com';
var debug_host = 'https://dy-hl.1lkj.top';
// 本地测试环境
var debug_host_url = debug_host + '/api';
// 线上测试环境
var test_host_url = host + '/api';
// 线上正式环境
var pro_host_url = host + '/api';
var rewrite = true;
var base_host = '';

//测试
var debug_appId = 'wx21694e1d418a2ed9';
var debug_appSecret = '3498jd0gk2j5m4ghsfert5hgd';
var debug_suitId = 'wxd04052b4689a502f';

//正式
var pro_appId = 'wx21694e1d418a2ed9';
var pro_appSecret = '3498jd0gk2j5m4ghsfert5hgd';
var pro_suitId = 'wxd04052b4689a502f';

var qywx_appid = 'wx1ca3b136b8594823';
var qywx_agentid = '1000290';

var allow_ip = '123.207.226.226';

if (debug_env == 'pro') {
  base_host = pro_host_url;
} else if (debug_env == 'dev') {
  // let vConsole = new VConsole() // 初始化
  base_host = debug_host_url;
} else {
  // let vConsole = new VConsole() // 初始化
  base_host = test_host_url;
}
//注册监听错误日志上报
//GlobalErrorReport.regist_error_event();

var ApiConfig = {
  menu_list: [],
  host: host,
  base_url: base_host,
  //appId               :  debug_env "win653af68b9a692a8e", //appid= cxpt234702343oj23nh
  //appSecret           :   "C7CAFCF9653AF68B9A692A8ecBC2Bs64",// 3498jd0gk2j5m4ghsfert5hgd
  //suitId              :   "wxd04052b4689a502f",//wx1c7ed18dcb6c93ee
  //如下为 debug
  appId: debug_env == 'pro' ? pro_appId : debug_appId, //appid= cxpt234702343oj23nh
  appSecret: debug_env == 'pro' ? pro_appSecret : debug_appSecret, //appid= cxpt234702343oj23nh
  suitId: debug_env == 'pro' ? pro_suitId : debug_suitId, //appid= cxpt234702343oj23nh
  debug: debug_env,
  qw_appid: qywx_appid,
  qw_agentid: qywx_agentid,
  allow_ip: allow_ip,
  use_edition: use_edition,

  //地图key
  tx_map_key: 'G3FBZ-BOX3D-X6U4F-P7K5D-FZ4C6-YXB3J',
  jsapi_debug: jsapi_debug,
  start_year: 50,
  expires_time: 1.8, //1个半小时

  //扫码登录获取app 秘钥接口
  qywechat_login_url: base_host + (!rewrite ? '/index.php' : '') + '/api/api_new/qywechat_login',

  request_action: base_host + (!rewrite ? '/index.php' : '') + '/api/api_new/request_action',

  //获取验证码
  get_code_url: base_host + (!rewrite ? '/index.php' : '') + '/api/api_new/send_msg',

  //手机号验证码登录接口
  mobile_login_url: base_host + (!rewrite ? '/index.php' : '') + '/api/api_new/mobile_login',

  // 注册
  reg_mobile_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/reg_mobile',

  // 注册获取验证码接口
  comm_send_sms_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/send_sms',

  //首页统计接口
  get_home_data: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/home_data',

  //获取菜单
  get_menu_url: base_host + (!rewrite ? '/index.php' : '') + '/api/api_new/get_menu',

  //---会话存档的权限的用户---
  list_permit_member_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/chat_new/list_permit_member',

  //获取关联聊天会话
  list_rel_chat: base_host + (!rewrite ? '/index.php' : '') + '/api/chat_new/list_rel_chat',

  //获取会话的具体内容
  list_chat_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/chat_new/list_chat_info',

  //查询客户
  list_customer_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/list_customers',

  //我的企业
  enterprise_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/enterprise_info',
  //参数设置
  param_setting_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/config',

  //聊天超时参数设置
  msg_out_setting_url: base_host + (!rewrite ? '/index.php' : '') + '/api/client/set_settings',
  // 获取聊天超时配置
  save_out_setting_url: base_host + (!rewrite ? '/index.php' : '') + '/api/client/get_settings',

  //参数保存
  save_param_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/save_config',

  //员工列表
  list_user_url: base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/list_member',
  //员工新增
  add_user_url: base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/add_user',
  //员工修改
  update_user_url: base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/update_user',
  //员工删除
  del_user_url: base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/delte_user',
  //员工详情
  user_detail_url: base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/get_user_info',
  //员工登录日志
  user_login_log_url: base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/list_logininfo',
  // 更新用户的角色
  update_rule: base_host + (!rewrite ? '/index.php' : '') + '/api/role_new/update_rule',
  // 禁用用户
  update_user_status_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/update_user_status',

  //消息审计条数
  audit_msgs_count_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/chat_new/audit_msgs_count',

  //消息审计
  audit_msgs_url: base_host + (!rewrite ? '/index.php' : '') + '/api/chat_new/audit_msgs',
  //客户信息
  customer_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/customer_info',

  // 数据统计 点对点 朋友圈
  single_staff_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/conversation_msg',

  // 获取角色列表，原文件中不存在的接口，但是在页面中却使用了这个接口 根据后端分析新增的项
  list_role_url: base_host + (!rewrite ? '/index.php' : '') + '/api/role_new/list_role_info',
  // 获取角色下所属成员，原文件中不存在的接口，但是在页面中却使用了这个接口 根据后端分析新增的项，但是调用接口报错404
  get_pole_user: base_host + (!rewrite ? '/index.php' : '') + '/api/role_new/pole_user',
  // 不知道这个接口干嘛用的，看上去是新增角色，但是在一进入页面就调用了，而且原文件中不存在的接口，但是在页面中却使用了这个接口，而且报错404
  add_rule_group: base_host + (!rewrite ? '/index.php' : '') + '/api/role_new/add_rule_group',

  // 查询角色详情
  role_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/role_new/role_info',

  // 新增角色保存
  save_role_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/role_new/add_role',
  // 修改角色----这个api好像没有用到了
  // edit_role_url: base_host + (!rewrite ? '/index.php' : '') + '/api/role_new/edit_role',
  // 更新角色的权限
  edit_rule_group: base_host + (!rewrite ? '/index.php' : '') + '/api/role_new/edit_rule_group',
  // 禁用角色
  update_role_status_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/role_new/update_role_status',

  // 会话统计
  conversation_msg_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/conversation_msg',

  // 超时统计
  msg_out_url: base_host + (!rewrite ? '/index.php' : '/api/client/get_chat_out'),

  //订阅列表
  keyword_list_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/keyword_list',

  //新增编辑敏感词订阅
  edit_keyword_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/edit_keyword',

  //敏感词详情
  keyword_detail_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/get_keyword_detail',

  //秘钥列表
  secret_key_list_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/secret_key_list',

  //秘钥详情
  secret_key_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/secret_key_info',

  //编辑秘钥
  edit_secret_key_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/save_privatekey',

  //禁用启用秘钥
  update_keyword_status_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/update_keyword_status',

  //存档人员列表
  permit_member_list_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/chat_new/permit_member_list',

  //聊天上下文
  list_chat_context_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/chat_new/list_chat_context',

  //授权存档人员列表
  auth_permit_member_list_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/auth_permit_member_list',

  //操作日志
  log_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/log_info',

  //敏感词类型
  keyword_type_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/get_keyword_type',

  //预警统计
  keyword_statistics_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/keyword_statistics',

  //预警统计详情
  keyword_notify_detail_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/keyword_notify_detail',

  //上传文件
  upload_file_url: base_host + (!rewrite ? '/index.php' : '') + '/api/file_new/upload',
  file_upload: base_host + (!rewrite ? '/index.php' : '') + '/api/file_new/file_upload',

  //同步组织架构
  sync_address_book_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/get_address_book',

  //获取扫码登录参数
  get_login_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/api_new/get_login_info',

  //群管理列表

  //首页保存参数
  code_setting_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/code_setting',

  //获取一对公钥私钥
  before_add_key_create_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/before_add_key_create',

  //初始参数设置生成公钥私钥入库
  code_setting_state1_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/code_setting_state1',

  //设置登录参数
  setting_login_code_url:
    base_host + (!rewrite ? '/index.php' : '') + '/Api/data_new/setting_login_code',

  //语音识别设置列表
  voice_engine_list_url:
    base_host + (!rewrite ? '/index.php' : '') + '/Api/data_new/voice_engine_list',

  //修改、新增语音识别参数
  edit_voice_engine_url:
    base_host + (!rewrite ? '/index.php' : '') + '/Api/data_new/edit_voice_engine',

  //语音识别详情
  voice_engine_detail_url:
    base_host + (!rewrite ? '/index.php' : '') + '/Api/data_new/voice_engine_detail',

  //开启语音识别
  update_voice_engine_status_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/update_voice_engine_status',

  //文件列表
  file_list_url: base_host + (!rewrite ? '/index.php' : '') + '/api/data_new/file_list',

  shop_enter_url: host + '/mock/shop.json',
  shop_custom_enter_url: host + '/mock/custom.json',
  shop_common_goods_url: host + '/mock/commonGoods.json',
  shop_miaosha_url: host + '/mock/miaoshaGoods.json',
  shop_pintuan_url: host + '/mock/pintuanGoods.json',
  shop_cate_url: host + '/mock/cateGoods.json',
  shop_yingxiao_url: host + '/mock/yingxiaoGoods.json',
  shop_luck_url: host + '/mock/luckGoods.json',

  shop_kanjia_url: host + '/mock/kanjiaGoods.json',

  // 用户列表
  permit_member_list:
    base_host + (!rewrite ? '/index.php' : '') + '/api/user_new/permit_member_list',

  // 抖音开发相关api
  // 代入驻预审
  dy_substitute_pre_check_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/pre_check',
  // 提交代入驻
  dy_substitute_apply_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/apply',
  // 查询代入驻状态
  dy_get_apply_status_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/get_apply_status',
  // 完善企业信息（目前只有appid和appsecret）
  dy_perfect_enterprise_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/perfect_enterprise',
  // 获取短信模板列表
  dy_get_sms_template_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/get_template',

  // 获取小程序列表
  dy_get_app_list_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/app_list',
  // 获取模板列表
  dy_get_tpl_list_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/get_tpl_list',
  // 获取授权url
  dy_get_authorization_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/get_authorization',
  // 获取app信息
  dy_get_app_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/app_infos',
  // 校验小程序名称是否可用
  dy_check_app_name_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/check_app_name',
  // 上传 1、营业执照 或 2、小程序图标
  dy_upload_pic_material_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/upload_pic_material',

  // 获取行业经营类目
  dy_industry_category_code_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/category_code',
  // 修改小程序信息
  dy_modify_app_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/modify_app',
  // 获取小程序经营类目
  dy_get_all_categories_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/all_categories',
  // 新增经营类目
  dy_add_categories_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/add_categories',
  // 删除经营类目
  dy_del_categories_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/del_categories',
  // 获取地区列表
  dy_get_all_area_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/all_area',
  // 提交代码
  dy_submit_code_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/submit',
  // 提审代码
  dy_audit_code_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/audit',
  // 发布代码
  dy_release_code_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/release',
  // 留资列表
  dy_list_active_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/list_active',
  // 删除留资
  dy_del_active_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/del_active',
  // 编辑留资
  dy_edit_active_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/edit_active',
  // 新增留资
  dy_add_active_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/add_active',
  // 素材分组
  dy_media_center_group_url: base_host + (!rewrite ? '/index.php' : '') + '/api/media_center/group',
  // 素材
  dy_media_center_index_url: base_host + (!rewrite ? '/index.php' : '') + '/api/media_center/index',
  // 获取留资信息列表
  dy_message_list_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/lists',
  // 更新留资活动
  dy_update_active_hidden_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/update_active',
  // 获取留资详情
  dy_vist_list_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/vist_list',
  // 留资跟进状态
  dy_message_update_status_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/updata_status',
  // 查询短视频/直播自主挂载绑定的抖音号列表
  dy_get_auth_user_list_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/query_self_mount_user_list',
  // 绑定短视频/直播自主挂载抖音号
  dy_bind_self_mount_user_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/bind_self_mount_user',
  // 解绑短视频/直播自主挂载抖音号
  dy_unbind_self_mount_user_url:
    base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/unbind_self_mount_user',
  // 隐私协议相关
  // 查询隐私协议
  get_user_privace_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/provicy_info',
  // 保存隐私协议
  save_user_privace_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/provicy',
  // 实时访客
  get_vist_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/vist',
  // 实时访客详情
  get_vist_info_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/vist_info',
  // 查看代码提交提审发布记录
  code_submit_list_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/submit_list',
  // 首页统计
  home_statisc_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/statisc',
  // 首页数据分析
  home_analysis_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/analysis',
  // 获取抖音小程序预览二维码
  get_preview_qrcode_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/qrcode',
  // 获取达人视频列表
  dy_get_auth_video_list_url: base_host + (!rewrite ? '/index.php' : '') + '/api/douyin/video_list',
};

window.g_api = ApiConfig;

export default ApiConfig;
