/**
 * 角色管理
 */
import React from 'react';
import Header from '@/component/Header';
import { MoreOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Table,
  Tree,
  Menu,
  Dropdown,
  Popconfirm,
  message,
  Drawer,
  Input,
  Modal,
  Select,
  Tag,
} from 'antd';
import _ from 'lodash';
import { useSingleState } from 'nice-hooks';
import update from 'immutability-helper';
import Empty from '@/component/Empty';
import filterSearch from '@/component/FilterProps/filterSearch';
import './role.scss';

const { TreeNode } = Tree;
const { Option } = Select;

class Role extends React.Component {
  state = {
    roleList: [],
    status: false,
    visible: false,
    treeRole: [],
    selectedKeys: [],
    checkedKeys: [],
    dataForUpdate: null,
    ACTIVE: '',
    load: false,
    users: [],
    modalVisible: false,
    modalData: {},
    modalValue: '',
    loading: false,
    filters: null,
    page: 1,
    listTotal: 0,
  };

  componentDidMount() {
    this.feachRoleList();
    this.roleData();
  }

  feachRoleList = () => {
    this.setState({
      loading: true,
    });
    window.g_appbase.get_app_token(token => {
      let formData = new FormData();
      window.g_postFetch(window.g_api.list_role_url + '?token=' + token, formData, res => {
        this.setState(
          {
            roleList: res.data,
            ACTIVE: res.data ? _.head(res.data) : {},
            load: true,
            loading: false,
          },
          () => {
            if (this.state.load) {
              this.users();
            }
          }
        );
      });
    });
  };

  users = () => {
    const { ACTIVE } = this.state;
    this.setState({
      loading: true,
    });
    window.g_appbase.get_app_token(token => {
      let formData = new FormData();
      formData.append('id', (ACTIVE && ACTIVE.id) || '');
      formData.append('keyword', this.state.filters?.truename || '');
      formData.append('p', this.state.page);
      window.g_postFetch(window.g_api.get_pole_user + '?token=' + token, formData, res => {
        if (res.status) {
          this.setState({ users: res.data, loading: false, listTotal: res.count });
        } else {
          this.setState({ users: [], loading: false });
        }
      });
    });
  };
  updateStatus = item => {
    // status 1: 启用 2: 禁用
    const { roleList } = this.state;
    window.g_appbase.get_app_token(token => {
      let formData = new FormData();
      formData.append('id', item.id);
      formData.append('status', item.status == 1 ? '2' : '1');
      formData.append('action', 'update_status');
      window.g_postFetch(window.g_api.update_role_status_url + '?token=' + token, formData, res => {
        if (res.code == 0) {
          message.success(res.msg);
          const index = roleList.findIndex(data => data.id === item.id);
          item.status = item.status == 1 ? '2' : '1';
          this.setState({
            status: true,
            roleList: update(roleList, { $splice: [[index, 1, item]] }),
          });
        }
      });
    });
  };
  DrawerAction = data => {
    if (data) {
      const newRules = data.rules.split(',');
      this.setState({
        visible: !this.state.visible,
        dataForUpdate: data,
        checkedKeys: newRules,
      });
    } else {
      this.setState({
        visible: !this.state.visible,
        dataForUpdate: null,
        checkedKeys: [],
      });
    }
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };

  roleData = () => {
    window.g_appbase.get_app_token(token => {
      let formData = new FormData();
      window.g_postFetch(window.g_api.add_rule_group + '?token=' + token, formData, res => {
        const newdata = res.data.rule_data2.sort(item => item._data.length > 0);
        this.setState({
          treeRole: res.data.rule_data2,
        });
      });
    });
  };

  onSubmit = () => {
    const { dataForUpdate } = this.state;
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        if (dataForUpdate) {
          window.g_appbase.get_app_token(token => {
            let formData = new FormData();
            formData.append('action', 'save_edit');
            formData.append('rule_ids', this.state.checkedKeys);
            formData.append('id', dataForUpdate.id || '');
            formData.append('title', values.title);
            window.g_postFetch(window.g_api.edit_rule_group + '?token=' + token, formData, res => {
              if (res.status) {
                message.success(res.info);
                this.feachRoleList();
                this.onClose();
              } else {
                message.error(res.info);
              }
            });
          });
        } else {
          window.g_appbase.get_app_token(token => {
            let formData = new FormData();
            formData.append('action', 'save');
            formData.append('title', values.title);
            formData.append('rule_ids', this.state.checkedKeys);
            window.g_postFetch(window.g_api.add_rule_group + '?token=' + token, formData, res => {
              if (res.status) {
                message.success(res.info);
                this.feachRoleList();
                this.setState({
                  visible: false,
                });
              } else {
                message.error(res.info);
              }
            });
          });
        }
      }
    });
  };

  renderTreeNodes = data =>
    data.map(item => {
      if (item._data) {
        return (
          <TreeNode title={item.title} key={item.id} dataRef={item}>
            {this.renderTreeNodes(item._data)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.id} {...item} />;
    });

  onCheck = checkedKeys => {
    this.setState({ checkedKeys });
  };

  onSelect = (selectedKeys, info) => {
    this.setState({ selectedKeys });
  };

  onClickGroup = item => {
    this.setState(
      {
        ACTIVE: item,
        page: 1,
      },
      () => {
        this.users();
      }
    );
  };

  modalAction = data => {
    this.setState({
      modalVisible: true,
      modalData: data,
    });
  };

  onCancel = () => {
    this.setState({
      modalVisible: false,
      modalData: null,
    });
  };

  onModalSubmit = () => {
    const { modalData, modalValue } = this.state;
    window.g_appbase.get_app_token(token => {
      let formData = new FormData();
      formData.append('id', modalData.id);
      formData.append('role_id', modalValue);
      window.g_postFetch(window.g_api.update_rule + '?token=' + token, formData, res => {
        if (res.code === 0) {
          message.success(res.msg);
          this.onCancel();
          this.users();
        } else {
          message.error(res.msg);
        }
      });
    });
  };

  onTableChange = (changePage, changeFilters, sorter, extra) => {
    const { filters } = this.state;
    if (extra.action === 'paginate') {
      this.setState({ page: changePage.current, filters: changeFilters }, () => {
        this.users();
      });
      return false;
    }
    if (JSON.stringify(filters) !== JSON.stringify(changeFilters)) {
      changePage.current = 1;
    }
    this.setState({ page: changePage.current, filters: changeFilters }, () => {
      this.users();
    });
  };

  render() {
    const {
      location: {
        state: { title },
      },
      form,
    } = this.props;
    const { getFieldDecorator, setFieldsValue } = form;
    const {
      filters,
      selectedKeys,
      checkedKeys,
      dataForUpdate,
      users,
      ACTIVE,
      modalVisible,
      listTotal,
      roleList,
    } = this.state;
    const columns = [
      {
        title: '成员列表',
        dataIndex: 'truename',
        key: 'truename',
        render: text => <a>{text}</a>,
        ...filterSearch({ placeholder: '请输入成员名称' }),
        filteredValue: filters?.truename,
      },
      {
        title: '所在部门',
        dataIndex: 'department_name',
        key: 'department_name',
        render: data => (data ? data.map(item => <Tag>{item.name}</Tag>) : '-'),
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, item) => (
          <div>
            {window.g_checkRole(31) && (
              <Button type="link" onClick={() => this.modalAction(item)}>
                更改权限
              </Button>
            )}
          </div>
        ),
      },
    ];

    const pagePros =
      listTotal > 10
        ? {
            current: this.state.page,
            total: parseInt(listTotal),
            pageSize: 15,
            showQuickJumper: true,
          }
        : false;
    return (
      <div className="page-content">
        <div className="setting-role">
          <div className="action">
            {window.g_checkRole(30) && (
              <Button type="primary" onClick={() => this.DrawerAction()}>
                添加角色
              </Button>
            )}
          </div>
          <div className="container">
            <div className="left">
              <ul>
                {this.state.roleList.map((item, index) => (
                  <li
                    className={ACTIVE.id === item.id ? 'active' : ''}
                    key={index}
                    onClick={() => this.onClickGroup(item)}
                  >
                    <p>{item.title}</p>
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => this.DrawerAction(item)}>修改</Menu.Item>
                          <Menu.Item>
                            <Popconfirm
                              title={`是否确认${item.status == 2 ? '启用' : '禁用'}${item.title}？`}
                              onConfirm={() => this.updateStatus(item)}
                              okText="确定"
                              cancelText="取消"
                            >
                              {item.status == 2 ? '启用' : '禁用'}
                            </Popconfirm>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <MoreOutlined className="icon_action" />
                    </Dropdown>
                  </li>
                ))}
              </ul>
            </div>
            <div className="right">
              <Table
                columns={columns}
                pagination={pagePros}
                locale={{
                  emptyText: <div>{!this.state.loading && users.length <= 0 && <Empty />}</div>,
                }}
                loading={this.state.loading}
                dataSource={users}
                onChange={this.onTableChange}
              />
            </div>
          </div>
          <Drawer
            title={dataForUpdate ? '修改角色' : '新建角色'}
            placement="right"
            destroyOnClose
            onClose={this.onClose}
            visible={this.state.visible}
            width={500}
          >
            <Form>
              <Form.Item label="角色名称">
                {getFieldDecorator('title', {
                  rules: [
                    {
                      message: '请输入角色名称!',
                    },
                    {
                      required: true,
                      message: '请输入角色名称!',
                    },
                  ],
                  initialValue: dataForUpdate ? dataForUpdate.title : '',
                })(<Input />)}
              </Form.Item>
              <Form.Item label="角色权限">
                {getFieldDecorator('rule_ids', {
                  rules: [
                    {
                      message: '请选择角色权限!',
                    },
                  ],
                })(
                  <Tree
                    setFieldsValue={this.state.checkedKeys}
                    checkable
                    // onExpand={this.onExpand}
                    // expandedKeys={this.state.expandedKeys}
                    autoExpandParent={true}
                    onCheck={this.onCheck}
                    checkedKeys={checkedKeys}
                    onSelect={this.onSelect}
                    selectedKeys={selectedKeys}
                  >
                    {this.renderTreeNodes(this.state.treeRole)}
                  </Tree>
                )}
              </Form.Item>
              <Form.Item>
                <div style={{ display: 'flex', width: '100%' }}>
                  <Button style={{ width: '50%', marginRight: 10 }} onClick={this.onClose}>
                    取消
                  </Button>
                  <Button style={{ width: '50%' }} type="primary" onClick={this.onSubmit}>
                    保存
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Drawer>
          <Modal
            title="设置权限"
            visible={modalVisible}
            destroyOnClose
            onCancel={this.onCancel}
            onOk={this.onModalSubmit}
          >
            <div>
              <span style={{ marginRight: 10 }}>设置角色</span>
              <Select
                style={{ width: 180 }}
                defaultValue={ACTIVE && ACTIVE.title}
                onChange={value =>
                  this.setState({
                    modalValue: value,
                  })
                }
              >
                {roleList.map((item, index) => (
                  <Option key={item.id} value={item.id} title={item.title}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
export default Form.create()(Role);
