import React from 'react';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';

class FilterDropdown extends React.Component {
  render() {
    const { setSelectedKeys, selectedKeys, clearFilters, confirm } = this.props;
    return <div style={{ padding: 8 }}>
      <DatePicker.RangePicker
        allowClear={false}
        value={(selectedKeys || []).map(item => moment(item))}
        onChange={(date, dateStrings) => setSelectedKeys(date.length > 0 ? dateStrings : [])}
        style={{ width: 250, marginBottom: 8,}}
      />
      <div className='btn_group' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="button" onClick={confirm}>确定</div>
        <div className="button" onClick={clearFilters}>重置</div>
      </div>
    </div>
  }
}

export default {
  filterDropdown: (props) => <FilterDropdown {...props} />,
  filterIcon: filtered => <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
}
