/*
 * @Author: zhao
 * @Date: 2021-05-19 19:31:22
 * @LastEditTime: 2021-11-10 10:58:44
 * @LastEditors: Please set LastEditors
 * @Description:
 */
export default {
  'menu.home': 'home',
  'menu.引流获客': 'slack',
  'menu.客户管理': 'CRM',
  'menu.客户群管理': 'Group',
  'menu.运营中心': 'Operation Center',
  'menu.营销中心': 'Marketing Center',
  'menu.朋友圈运营': 'Friends Operation',
  'menu.会话存档': 'Message',
  'menu.系统设置': 'Setting',
  'menu.数据统计': 'STATS',
  'menu.客户和群管理': 'Client Group Manage',
  'menu.渠道活码': 'Trench code',
  'menu.群活码': 'Group code',
  'menu.客户列表': 'Customer',
  'menu.标签管理': 'Tag',
  'menu.流失客户': 'Loss Customer',
  'menu.客户统计': 'Customer Statistics',
  'menu.客户交接': 'Transfer Customer',
  'menu.客户群列表': 'Group List',
  'menu.群标签': 'Group Tag',
  'menu.群统计': 'Group Chat',
  'menu.客户群发': 'Customer Send',
  'menu.客户群群发': 'Group Send',
  'menu.企业朋友圈': 'Moment',
  'menu.聊天消息': 'Chat',
  'menu.违规记录': 'Out Record',
  'menu.聊天统计': 'Msg Chart',
  'menu.风控预警': 'Risk control warning',

  'menu.素材分组': 'Material Group',
  'menu.素材统计': 'Material Statistics',
  'menu.素材库': 'Material',
  'menu.侧边栏配置': 'Side Window',
  'menu.聊天存档': 'Chart Archive',
  'menu.聊天记录': 'Chart ',
  'menu.监控统计': 'Monitoring Statistics',
  'menu.词频分析': 'Word Frequency Analysis',
  'menu.用户管理': 'User',
  'menu.角色管理': 'Role',
  'menu.隐私协议': 'Privacy Agreement',
  'menu.参数设置': 'Setting',
  'menu.朋友圈': 'Moments',
  'menu.企微任务宝': 'Task',
  'menu.客户群统计': 'Group Stat',
  'menu.群裂变': 'Group Task',
  'menu.上下游': 'Chain',
  'menu.入驻信息': '入驻信息',
  'menu.小程序': '小程序',
  'menu.小程序列表': '小程序列表',
  'menu.营销': '营销',
  'menu.活动站点': '活动站点',
  'menu.大转盘': '大转盘',
  'menu.优惠券': '优惠券',
  'menu.小额支付': '小额支付',
  'menu.授权': '授权',
  'menu.授权达人': '授权达人',
  'menu.达人视频': '达人视频',
  'menu.授权视频': '授权视频',
  'menu.线索': '线索',
  'menu.实时访客': '实时访客',
  'menu.留资信息': '留资信息',
  'menu.私信信息': '私信信息',
  'menu.设置': '设置',
};
