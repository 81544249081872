/*
 * @Author: zhao
 * @Date: 2021-05-19 19:31:22
 * @LastEditTime: 2021-11-24 19:05:15
 * @LastEditors: Please set LastEditors
 * @Description: 
 */
import React, { useEffect, useState } from 'react'
import { useSingleState } from 'nice-hooks'
import { Pagination } from 'antd'


const PAGE_SIZE = 15

export default function Page({
  page,
  total,
  onSelect
}) {

  const onChange = (page, pageSize) => {
    const PagingOnChangeEvent = { current: page, pageSize: pageSize }
    onSelect(PagingOnChangeEvent)
  }

  const onShowSizeChange = (current, size) => {
    const PagingOnChangeEvent = { current: current, pageSize: size }
    onSelect(PagingOnChangeEvent)
  }

  const newTotal = parseInt(total)
  const pageSizeOptions = ['15', '20', '30', '40']
  const hideOnSinglePage = newTotal <= PAGE_SIZE ? true : false

  return <Pagination
    hideOnSinglePage={hideOnSinglePage}
    style={{ textAlign: 'right', margin: '20px 20px', height: 50 }}
    current={page.current}
    pageSize={page.pageSize}
    pageSizeOptions={pageSizeOptions}
    total={newTotal}
    showSizeChanger
    showQuickJumper
    onChange={onChange}
    onShowSizeChange={onShowSizeChange}
  />
}


