import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Divider, Input, Checkbox, Col, Spin, message, Modal } from 'antd';
import postFetch from '../../js/fetch';

function Register(props) {
  const [form] = Form.useForm();
  let [time, setTime] = useState(0);
  const [isSending, setIsSending] = useState(false);

  const startTimer = () => {
    const timer = setInterval(() => {
      setTime(prevTime => {
        if (prevTime === 1) {
          setIsSending(false);
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  //获取验证码
  const handRegisterCountdown = e => {
    var mobile = form.getFieldValue('set_mobile');
    if (mobile == '') {
      message.error('请输入手机号', 2);
      return;
    }
    var checkMObile = /^1[3456789]\d{9}$/;
    if (!checkMObile.test(mobile)) {
      message.error('手机号码格式错误', 2);
      return;
    }

    // 这里写注册验证码的接口，调完接口这里写倒数的逻辑

    message.warning('验证码发送中...');
    setIsSending(true);
    postFetch(
      window.g_api.comm_send_sms_url,
      {
        mobile: mobile,
      },
      res => {
        message.destroy();
        if (res.code == 0) {
          message.success('验证码发送成功，请注意查收');
          setTime(60);
          startTimer();
        } else {
          setIsSending(false);
          message.error(res.msg);
        }
      },
      'GET'
    );
  };

  // 注册提交
  const handleRegisterok = () => {
    form.validateFields().then(async () => {
      var formData = new FormData();
      formData.append('reg_mobile', form.getFieldValue('set_mobile'));
      formData.append('reg_code', form.getFieldValue('code'));

      // 这里写注册提交的接口
      postFetch(
        window.g_api.reg_mobile_url,
        formData,
        res => {
          console.log('res:', res);
          if (res.code == 0) {
            message.success('注册成功');
            window.location.href = '/logout';
          } else {
            message.error(res.msg);
          }
        },
        'POST'
      );
    });
  };

  return (
    <>
      <Form form={form}>
        <Form.Item
          label=""
          name="set_mobile"
          rules={[
            {
              required: true,
              message: '请输入手机号码',
            },
          ]}
        >
          <Input
            placeholder="请输入手机号码"
            style={{
              borderColor: '#ebeff2',
              backgroundColor: '#F1F2F4',
              height: 44,
              borderRadius: 4,
            }}
          />
        </Form.Item>
        <Form.Item
          label=""
          name="code"
          rules={[
            {
              required: true,
              message: '请输入验证码',
            },
          ]}
          style={{
            backgroundColor: '#F1F2F4',
            height: 44,
            borderRadius: 4,
          }}
        >
          <Row style={{ display: 'flex' }}>
            <Col span={18}>
              <Input
                className="login_password"
                style={{
                  borderColor: '#ebeff2',
                  backgroundColor: '#F1F2F4',
                  height: 44,
                  borderRadius: 4,
                }}
                onPressEnter={() => {
                  handleRegisterok();
                }}
                placeholder="请输入验证码"
              />
            </Col>
            <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
              {isSending && time < 60 ? (
                <span
                  style={{
                    fontSize: 12,
                    color: '#afafaf',
                    cursor: 'pointer',
                    width: '100%',
                    textAlign: 'center',
                    display: 'block',
                    fontWeight: 'bold',
                  }}
                >
                  {time}
                </span>
              ) : (
                <span
                  style={{
                    fontSize: 12,
                    color: '#afafaf',
                    cursor: 'pointer',
                    width: '100%',
                    textAlign: 'center',
                    display: 'block',
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    handRegisterCountdown();
                  }}
                >
                  {isSending ? time : '获取验证码'}
                </span>
              )}
            </Col>
          </Row>
        </Form.Item>
        <Row style={{ marginTop: 80, marginBottom: 24 }}>
          <Button
            style={{
              width: '100%',
              height: 50,
              borderRadius: 6,
              backgroundColor: '#D4515D',
              outline: 'none',
            }}
            type="primary"
            onClick={() => {
              handleRegisterok();
            }}
          >
            注册
          </Button>
        </Row>
      </Form>
      <Divider>
        <button
          type="text"
          style={{
            cursor: 'pointer',
            fontSize: 12,
            color: 'rgb(175, 175, 175)',
            backgroundColor: 'transparent',
          }}
          onClick={() => {
            props.toLogin();
          }}
        >
          已有账号？<span style={{ textDecoration: 'underline' }}>立即去登录</span>
        </button>
      </Divider>
    </>
  );
}

export default Register;
