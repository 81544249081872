/**
 * 打标签
 */
import React from 'react'
import { Modal, Button, Input, Tag, Radio } from 'antd';
import classnames from 'classnames'
import update from 'immutability-helper'
import Loading from '../Loading'
import _ from 'lodash'


class Label extends React.Component {
  state = {
    tags: [],
    tagSelected: [],
    data: {},
    show: false,
    newList: [],
    loading: false,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedLabels !== this.props.selectedLabels) {
      this.setState({
        tagSelected: nextProps.selectedLabels,
      });
    }
  }

  labelArr = (item) => {
    const codes = this.state.tagSelected.map(item => item.code)
    if (codes.includes(item.code)) {
      const oldList = this.state.tagSelected;
      const index = oldList.findIndex(list => list.code === item.code);
      this.setState({
        tagSelected: update(this.state.tagSelected, { $splice: [[index, 1]] })
      })
    } else {
      this.setState({
        tagSelected: update(this.state.tagSelected, { $push: [{ name: item.names, code: item.code, tag_id: item.tag_id }] })
      })
    }
  }

  onsubmit = () => {
    this.setState({
      loading: true
    })
    const { onSubmit } = this.props
    const { data, tagSelected } = this.state
    data.label = tagSelected
    this.setState({
      data: data
    }, () => {
      onSubmit(data, (res) => {
        if(res){
          this.setState({
            loading: false
          })
        }
      })
      this.setState({
        tagSelected: [],
        data: [],
        // loading: false
      })
    })
  }

  onRest = () => {
    this.setState({
      tagSelected: [],
      code_type: '1'
    })
  }

  search = (e) => {
    const { tags } = this.state
    const { value } = e.target;
    const {alllabels} = this.props
    let bb = []
    if (value) {
      for (const a of alllabels) {
        const item = { ...a, label_array: a.label_array.filter(aDataItem => aDataItem.names.includes(value)) }
        if (item.label_array.length > 0) {
          bb.push(item)
        }
      }
      this.setState({
        show: true,
        newList: bb
      })
    } else {
      this.setState({
        show: false,
        newList: []
      })
    }
  }

  render() {
    const { visible, labelModal, title, alllabels, loading } = this.props;
    const { tags, tagSelected, show, newList } = this.state;
    return <div>
      <Modal
        title={title || '批量打标签'}
        visible={visible}
        className='label-modal'
        onOk={this.onsubmit}
        onCancel={labelModal}
        destroyOnClose
        footer={
          <div className='foot'>
            <span onClick={this.onRest}>重置</span>
            <div className='right'>
              <Button onClick={labelModal}>取消</Button>
              <Button type='primary' loading={this.state.loading} onClick={this.onsubmit}>确定</Button>
            </div>
          </div>
        }
      >
        {
          loading ? <Loading /> :
            <div>
              <Input placeholder='请输入标签' onPressEnter={this.search} />
              <div className='list'>
                <ul>
                  {
                    show ?
                      newList.map((item, index) => (
                        <li key={item.id}>
                          <div className='parent'>{item.name}:</div>
                          <div className='tags'>
                            {item.label_array.map((itemChildren,index) => (
                              <div className={classnames('tag_item', { active: tagSelected.map(item => item.code).includes(itemChildren.code) })}
                                onClick={() => this.labelArr(itemChildren)} key={index}>
                                {itemChildren.names}
                              </div>
                            ))}
                          </div>
                        </li>
                      )) :
                      alllabels.map((item, index) => (
                        <li key={item.id}>
                          <div className='parent'>{item.name}:</div>
                          <div className='tags'>
                            {item.label_array.map((itemChildren, index) => (
                              <div key={index} className={classnames('tag_item', { active: tagSelected.map(item => item.code).includes(itemChildren.code) })}
                                onClick={() => this.labelArr(itemChildren)} dangerouslySetInnerHTML={{ __html: itemChildren.names }} />
                            ))}
                          </div>
                        </li>
                      ))
                  }
                </ul>
              </div>
            </div>
        }
      </Modal>
    </div>

  }
}



export default Label
