/*
 * @Author: zhao
 * @Date: 2021-07-16 09:41:00
 * @LastEditTime: 2022-02-22 15:40:26
 * @LastEditors: Please set LastEditors
 * @Description:
 */
export default {
  'menu.home': '首页',
  'menu.引流获客': '引流获客',
  'menu.客户管理': '客户管理',
  'menu.客户群管理': '客户群管理',
  'menu.运营中心': '运营中心',
  'menu.营销中心': '营销中心',
  'menu.朋友圈运营': '朋友圈运营',
  'menu.会话存档': '会话存档',
  'menu.系统设置': '系统设置',
  'menu.数据统计': '数据统计',
  'menu.客户和群管理': '客户和群管理',
  'menu.渠道活码': '渠道活码',
  'menu.客户列表': '客户列表',
  'menu.标签管理': '标签管理',
  'menu.流失客户': '流失客户',
  'menu.客户统计': '客户统计',
  'menu.客户交接': '客户交接',
  'menu.客户群列表': '客户群列表',
  'menu.群标签': '群标签',
  'menu.群统计': '群统计',
  'menu.客户群发': '客户群发',
  'menu.客户群群发': '客户群群发',
  'menu.企业朋友圈': '企业朋友圈',
  'menu.聊天消息': '聊天消息',
  'menu.违规记录': '违规记录',
  'menu.聊天统计': '聊天统计',
  'menu.私钥管理': '私钥管理',
  'menu.用户管理': '用户管理',
  'menu.企业信息': '企业信息',
  'menu.角色管理': '角色管理',
  'menu.隐私协议': '隐私协议',
  'menu.订阅管理': '订阅管理',
  'menu.语音识别设置': '语音识别设置',
  'menu.登录日志': '登录日志',
  'menu.文件列表': '文件列表',
  'menu.删客风控': '删客风控',
  'menu.聊天超时设置': '聊天超时设置',
  'menu.操作日志': '操作日志',
  'menu.组织架构': '组织架构',
  'menu.会话消息': '会话消息',
  'menu.消息统计': '消息统计',
  'menu.员工统计': '员工统计',
  'menu.预警统计': '预警统计',
  'menu.聊天超时统计': '聊天超时统计',
  'menu.宣传内容发送统计': '宣传内容发送统计',
  'menu.内容宣传标签统计表': '内容宣传标签统计表',
  'menu.内容宣传关键词回复统计表': '内容宣传关键词回复统计表',
  'menu.朋友圈发送统计表': '朋友圈发送统计表',
  'menu.朋友圈发送明细表': '朋友圈发送明细表',
  'menu.群管理': '群管理',
  'menu.参数设置': '参数设置',
  'menu.微群活码': '微群活码',
  'menu.素材库': '素材库',
  'menu.素材分组': '素材分组',
  'menu.素材统计': '素材统计',
  'menu.聊天存档': '聊天存档',
  'menu.聊天记录': '聊天记录',
  'menu.监控统计': '监控统计',
  'menu.词频分析': '词频分析',
  'menu.侧边栏配置': '侧边栏配置',
  'menu.风控预警': '风控预警',
  'menu.群发朋友圈': '群发朋友圈',
  'menu.朋友圈统计': '朋友圈统计',
  'menu.企微任务宝': '企微任务宝',
  'menu.聊天敏感词': '聊天敏感词',
  'menu.客户迁移': '客户迁移',
  'menu.入群欢迎语': '入群欢迎语',
  'menu.客户群统计': '客户群统计',
  'menu.任务宝': '任务宝',
  'menu.客户SOP': '客户SOP',
  'menu.朋友圈SOP': '朋友圈SOP',
  'menu.客户欢迎语': '客户欢迎语',
  'menu.地域群活码': '地域群活码',
  'menu.微信客服': '微信客服',
  'menu.API管理': 'API管理',
  'menu.个人SOP': '个人SOP',
  'menu.客服管理': '客服管理',
  'menu.客服机器人': '客服机器人',
  'menu.服务分析': '服务分析',
  'menu.群裂变': '群裂变',
  'menu.上下游': '上下游',
  'menu.批量加好友': '批量加好友',
  'menu.接待记录': '接待记录',
  'menu.客服统计': '客服统计',
  'menu.员工接待': '员工接待',
  'menu.回复超时统计': '回复超时统计',
  'menu.存量客户转化': '存量客户转化',
  'menu.引流组件': '引流组件',
  'menu.万能推广码': '万能推广码',
  'menu.评价记录': '评价记录',
  'menu.企微群活码': '企微群活码',
  'menu.业务对接码': '业务对接码',
  'menu.表单收集': '表单收集',
  'menu.业务数据': '业务数据',
  'menu.跟进日程': '跟进日程',
  'menu.安全管理': '安全管理',
  'menu.自动标签': '自动标签',
  'menu.许可帐号管理': '许可帐号管理',
  'menu.素材中心': '素材中心',
  'menu.获客链接': '获客链接',
  'menu.入驻信息': '入驻信息',
  'menu.小程序': '小程序',
  'menu.小程序列表': '小程序列表',
  'menu.营销': '营销',
  'menu.活动站点': '活动站点',
  'menu.大转盘': '大转盘',
  'menu.优惠券': '优惠券',
  'menu.小额支付': '小额支付',
  'menu.授权': '授权',
  'menu.授权达人': '授权达人',
  'menu.达人视频': '达人视频',
  'menu.线索': '线索',
  'menu.实时访客': '实时访客',
  'menu.留资信息': '留资信息',
  'menu.私信信息': '私信信息',
  'menu.设置': '设置',
};
