import React, { useState, useEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Checkbox, Modal } from 'antd';
import { VideoCameraTwoTone, DeleteOutlined } from '@ant-design/icons';
function DecorateCenter(props) {
  const [items, setItems] = useState([]);
  const [active, setActive] = useState('0');

  let fixedItems = [];

  useEffect(() => {
    const { items, activeIndex } = props;
    setItems(items);
    if (activeIndex == -1) {
      setActive('0');
    } else {
      setActive(String(activeIndex));
    }
    fixedItems = [];
  }, [props.items]);

  const deleteWidget = () => {
    props.deleteItem();
  };

  const widgetImage = item => {
    const { draggableId, info } = item;
    return (
      <div>
        {info.imageList[0] && (
          <div className={`${draggableId === active ? 'active' : ''}`}>
            <img
              src={window.g_api.base_url + info.imageList[0]}
              style={getStyle(info)}
              id={draggableId}
              className="widget-image"
            />
          </div>
        )}
        {!info.imageList[0] && (
          <div
            style={{
              backgroundColor: '#fff',
              color: '#666',
              textAlign: 'center',
              height: '100px',
              lineHeight: '100px',
              border: '1px solid #efefef',
            }}
          >
            <h4>点击上传图片</h4>
          </div>
        )}
      </div>
    );
  };

  const widgetText = item => {
    const { draggableId, info } = item;
    return (
      <div>
        <div
          className={`${draggableId === active ? 'active widget-text' : 'widget-text'}`}
          style={{
            ...getStyle(info),
            lineHeight: info.type === 'button' ? info.style.height + 'px' : 'unset',
          }}
          id={draggableId}
        >
          {info.src && (
            <img src={info.src} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
          )}
          {info.text}
        </div>
      </div>
    );
  };

  const widgetVideo = item => {
    const { draggableId, info } = item;
    return (
      <div>
        {info.coverImg !== '' && (
          <img
            src={window.g_api.base_url + info.coverImg}
            style={getStyle(info)}
            id={draggableId}
            className={`${draggableId === active ? 'active widget-video' : 'widget-video'}`}
          />
        )}
        {info.coverImg === '' && (
          <div
            style={{
              height: 200,
              backgroundColor: '#fff',
              border: '1px solid #dedede',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <VideoCameraTwoTone style={{ fontSize: 100 }} />
          </div>
        )}
      </div>
    );
  };

  const widgetAudio = item => {
    const { draggableId, info } = item;
    return (
      <div>
        <img
          src={window.g_api.base_url + info.coverImg}
          style={getStyle(info)}
          id={draggableId}
          className={`${draggableId === active ? 'active widget-audio' : 'widget-audio'}`}
        />
      </div>
    );
  };

  const widgetForm = item => {
    const { draggableId, info } = JSON.parse(JSON.stringify(item));
    if (info.style.labelWidth) {
      if (info.style.labelWidth.indexOf('%') === -1) {
        info.style.labelWidth = info.style.labelWidth + 'px';
      }
    }
    if (info.style.itemMarginBottom) {
      if (info.style.itemMarginBottom.indexOf('%') === -1) {
        info.style.itemMarginBottom = info.style.itemMarginBottom + 'px';
      }
    }
    return (
      <div>
        <div
          className={`${draggableId === active ? 'active widget-form' : 'widget-form'}`}
          style={getStyle(info)}
          id={draggableId}
        >
          {info.formList &&
            info.formList.map((form, index) => {
              return (
                <div
                  className="widget-form-item"
                  key={index}
                  style={{ marginBottom: info.style.itemMarginBottom }}
                >
                  <div style={{ width: info.style.labelWidth, color: info.style.labelColor }}>
                    {form.label}
                  </div>
                  <div className="widget-form-item-input" style={{ color: info.style.inputColor }}>
                    {form.input}
                  </div>
                </div>
              );
            })}
          {info.ifShowPrivace && (
            <div
              className="privacy-agreement"
              style={{ marginBottom: info.style.itemMarginBottom }}
            >
              <Checkbox>{info.privacyAgreementText}</Checkbox>
            </div>
          )}
          <div
            style={{
              color: info.style.formSubmitColor,
              backgroundColor: info.style.formSubmitBackgroundColor,
              width: info.style.formSubmitWidth + 'px',
              height: info.style.formSubmitHeight + 'px',
              lineHeight: info.style.formSubmitHeight + 'px',
              borderRadius: info.style.formSubmitBorderRadius + 'px',
              textAlign: 'center',
              fontSize: '14px',
              margin: 'auto',
            }}
          >
            {info.formSubmitText}
          </div>
        </div>
      </div>
    );
  };

  const widgetDivide = item => {
    const { draggableId, info } = item;
    return (
      <div>
        <div
          className={`${draggableId === active ? 'active widget-divide' : 'widget-divide'}`}
          style={getStyle(info)}
          id={draggableId}
        ></div>
      </div>
    );
  };

  const widgetSuspension = item => {
    const { draggableId, info } = item;
    return (
      <div>
        <div
          className={`${
            draggableId === active ? 'active widget-suspension' : 'widget-suspension'
          } ${info.position}`}
          style={getStyle(info)}
          id={draggableId}
        >
          <div className="function-btn-list">
            {info['functionBtnList'].map((item, index) => {
              if (item.open) {
                return (
                  <div
                    className="function-btn"
                    style={{
                      backgroundColor: info.style.functionButtonBackgroundColor,
                      color: info.style.functionButtonTextColor || '#000',
                    }}
                  >
                    <span>{item.text}</span>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  const widgetEmpty = () => {
    return (
      <Draggable key={'empty'} draggableId={'empty'} index={-1}>
        {(provided, snapshot) => (
          <div
            className="empty"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            拖动左侧组件添加到此处
          </div>
        )}
      </Draggable>
    );
  };

  const getStyle = info => {
    if (info && info.style) {
      let style = JSON.parse(JSON.stringify(info.style));
      if (style.position) {
        if (style.position === 'fixed') {
          // style.position = 'absolute';
          // fixedItems.push(info.draggableId);
        }
      }
      if (style.top) {
        if (style.top.indexOf('%') === -1) {
          style.top = style.top + 'px';
        }
      }
      if (style.right) {
        // if (style.position !== 'fixed') {
        //   if (style.right.indexOf('%') === -1) {
        //     style.right = style.right + 'px';
        //   }
        // } else {
        //   style.right = '';
        // }
        if (style.right.indexOf('%') === -1) {
          style.right = style.right + 'px';
        }
      }
      if (style.bottom) {
        if (style.bottom.indexOf('%') === -1) {
          style.bottom = style.bottom + 'px';
        }
      }
      if (style.left) {
        // if (style.position !== 'fixed') {
        //   if (style.left.indexOf('%') === -1) {
        //     style.left = style.left + 'px';
        //   }
        // } else {
        //   style.left = '';
        // }
        if (style.left.indexOf('%') === -1) {
          style.left = style.left + 'px';
        }
      }
      if (style.width) {
        if (style.width.indexOf('%') === -1) {
          style.width = style.width + 'px';
        }
      }
      if (style.height) {
        if (style.height.indexOf('%') === -1) {
          style.height = style.height + 'px';
        }
      }
      if (style.zIndex) {
        if (typeof style.zIndex === 'number' && !isNaN(style.zIndex)) {
          style.zIndex = style.zIndex;
        }
      }
      if (style.fontSize) {
        style.fontSize = style.fontSize + 'px';
      }
      if (style.borderRadius) {
        style.borderRadius = style.borderRadius + 'px';
      }
      if (style.padding) {
        let tempStr = '';
        style.padding.split(',').forEach(item => {
          if (typeof Number(item) === 'number' && !isNaN(item)) {
            tempStr += item + 'px ';
          } else {
            tempStr += item + ' ';
          }
        });
        style.padding = tempStr;
      } else {
        if (style.paddingTop) {
          style.paddingTop = style.paddingTop + 'px';
        }
        if (style.paddingRight) {
          style.paddingRight = style.paddingRight + 'px';
        }
        if (style.paddingBottom) {
          style.paddingBottom = style.paddingBottom + 'px';
        }
        if (style.paddingLeft) {
          style.paddingLeft = style.paddingLeft + 'px';
        }
      }
      if (style.margin) {
        let tempStr = '';
        style.margin.split(',').forEach(item => {
          if (typeof Number(item) === 'number' && !isNaN(item)) {
            tempStr += item + 'px ';
          } else {
            tempStr += item + ' ';
          }
        });
        style.margin = tempStr;
      } else {
        if (style.marginTop) {
          style.marginTop = style.marginTop + 'px';
        }
        if (style.marginRight) {
          style.marginRight = style.marginRight + 'px';
        }
        if (style.marginBottom) {
          style.marginBottom = style.marginBottom + 'px';
        }
        if (style.marginLeft) {
          style.marginLeft = style.marginLeft + 'px';
        }
      }
      if (style.borderWidth) {
        style.borderWidth = style.borderWidth + 'px';
      }
      style.transform = '';
      if (style.translateY) {
        if (style.translateY.indexOf('%') === -1) {
          style.transform = `translateY(${style.translateY}px)`;
        } else {
          style.transform = `translateY(${style.translateY})`;
        }
      }
      if (style.translateX) {
        if (style.translateX.indexOf('%') === -1) {
          style.transform = `${style.transform} translateX(${style.translateX}px)`;
        } else {
          style.transform = `${style.transform} translateX(${style.translateX})`;
        }
      }
      // console.log(style);

      return style;
    }
    return {};
  };

  const onScroll = e => {
    // if (fixedItems && fixedItems.length) {
    //   fixedItems.forEach(id => {
    //     const item = document.getElementById(id);
    //     item.style.transform = `translateY(${e.target.scrollTop}px)`;
    //   });
    // }
  };

  return (
    <div className="decorate-center">
      <div className="all-widget-list">
        <h3 className="all-widget-title">当前已添加组件</h3>
        <div className="widget-list">
          {items.map((item, index) => (
            <div
              key={index}
              index={index}
              className={active === item.draggableId ? 'widget-item active' : 'widget-item'}
              onClick={() => {
                setActive(item.draggableId);
                props.setActive(item);
              }}
            >
              <h4 className="widget-label">
                <span>{item.info.label}</span>
                {active === item.draggableId && (
                  <DeleteOutlined
                    onClick={e => {
                      Modal.confirm({
                        title: '是否删除该组件？',
                        okText: '确认',
                        cancelText: '取消',
                        onOk() {
                          deleteWidget();
                          e.stopPropagation();
                        },
                      });
                    }}
                    style={{
                      fontSize: 14,
                      color: '#999',
                      cursor: 'pointer',
                    }}
                  />
                )}
              </h4>
            </div>
          ))}
        </div>
      </div>
      <Button type="primary" className="decorate-save" onClick={() => props.save()}>
        保存
      </Button>
      <Button
        type="primary"
        className="decorate-save-preview"
        onClick={() => {
          setActive(null);
          props.saveAndPreview();
        }}
      >
        预览
      </Button>
      <div className="decorate-phone">
        <div
          className="phone-header"
          onClick={() => {
            setActive(null);
            props.setActive(null);
          }}
        >
          <div className="phone-title">留资详情</div>
        </div>
        <div style={{ position: 'relative', height: 'calc(100% - 64px)' }}>
          <div className="phone-body" onScroll={onScroll}>
            <Droppable droppableId="decorate-center" type="column">
              {(provided, snapshot) => (
                <div
                  className="decorate-center-body"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {items.map((item, index) => (
                    <Draggable key={item.draggableId} draggableId={item.draggableId} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={e => {
                            setActive(item.draggableId);
                            props.setActive(item);
                            e.stopPropagation();
                          }}
                        >
                          {item.info.type === 'image' && widgetImage(item)}
                          {item.info.type === 'text' && widgetText(item)}
                          {/* {item.info.type === 'consult' && widgetText(item)} */}
                          {item.info.type === 'button' && widgetText(item)}
                          {item.info.type === 'submit' && widgetText(item)}
                          {item.info.type === 'video' && widgetVideo(item)}
                          {item.info.type === 'audio' && widgetAudio(item)}
                          {item.info.type === 'form' && widgetForm(item)}
                          {item.info.type === 'divide' && widgetDivide(item)}
                          {item.info.type === 'suspension' && widgetSuspension(item)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {!items.length && widgetEmpty()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DecorateCenter;
