/*
 * @Date: 2021-06-15 16:21:19
 * @LastEditTime: 2022-02-17 15:22:18
 * @LastEditors: Please set LastEditors
 * @Description: 
 */
import dayjs from 'dayjs'
import moment from 'moment'
import isYesterday from 'dayjs/plugin/isYesterday'
import isToday from 'dayjs/plugin/isToday'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import dayjsPluginUTC from 'dayjs-plugin-utc'



window.isAdmin = window.g_appbase.getSessionGlobalItem("_access_isAdmin"); //是否管理员
window.g_isExpired = window.g_appbase.getSessionGlobalItem("_access_isExpired"); // 是否显示会话存档


dayjs.extend(isYesterday)
dayjs.extend(isToday)
dayjs.extend(dayjsPluginUTC, { parseToLocal: true })
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
window.g_dayjs = dayjs









//验证手机号
function checkPhone(phoneNumber) {
  var chechPhoneNumber = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
  if (!chechPhoneNumber.test(phoneNumber)) {
    return false;
  } else {
    return true;
  }
}
window.checkPhone = checkPhone

//验证邮箱
function checkEmail(email) {
  var chechEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
  if (!chechEmail.test(email)) {
    return false;
  } else {
    return true;
  }
}
window.checkEmail = checkEmail

function checkRole(role) {
  const auth = window.g_cache.getItem("scrm_auth"); // 权限
  if (auth && auth.includes(role.toString())) {
    return true;
  } else {
    return false;
  }
}
window.g_checkRole = checkRole

function isAdadmin() {
  const checkAdmin = window.g_appbase.getSessionGlobalItem("_access_isAdmin");
  if (checkAdmin) {
    return true
  } else {
    return false
  }
}

window.g_isAdadmin = isAdadmin


function source(key) {
  const data = [
    { value: '0', text: '未知来源' },
    { value: '1', text: '扫描二维码' },
    { value: '2', text: '搜索手机号' },
    { value: '3', text: '名片分享 ' },
    { value: '4', text: '群聊 ' },
    { value: '5', text: '手机通讯录' },
    { value: '6', text: '微信联系人' },
    { value: '7', text: '来自微信的添加好友申请' },
    { value: '8', text: '安装第三方应用时自动添加的客服人员' },
    { value: '9', text: '搜索邮箱' },
    { value: '10', text: '视频号添加' },
    { value: '11', text: '通过日程参与人添加' },
    { value: '12', text: '通过会议参与人添加' },
    { value: '13', text: '添加微信好友对应的企业微信' },
    { value: '14', text: '通过智慧硬件专属客服添加' },
    { value: '127', text: '管理员/负责人分配' },
    { value: '201', text: '内部成员共享' },
    { value: '202', text: '管理员/负责人分配' },
  ]
  const value = data.find(item => item.value == key)?.text
  return value || key
}
window.g_source = source

function filterHtml(data) {
  const nohtml = data.replace(/<\/?.+?>/g, "");
  return nohtml
}
window.g_filterHtml = filterHtml

function isCos(value) {
  var patt = /^http(s)?:\/\/.+/;
  if (!(patt.test(value))) {
    return window.g_api.base_url + value;
  } else {
    return value;
  }
}
window.g_isCos = isCos

function time_to_sec(time) {
  const hour = time.split(':')[0];
  const min = time.split(':')[1];
  return Number(hour * 3600) + Number(min * 60);
}
window.g_timeToSec = time_to_sec

function secondToDate(result) {
  let time = moment.duration(result, 'seconds')  //得到一个对象，里面有对应的时分秒等时间对象值
  let hours = time.hours()
  let minutes = time.minutes()
  return moment({ h: hours, m: minutes }).format('HH:mm')
}
window.g_secondToDate = secondToDate

window.allowJump = true



function getDayTime(time) {
  if (time.toString().length === 13) {
    return window.g_dayjs(parseInt(time)).format('YYYY-MM-DD HH:mm')
  } else {
    return window.g_dayjs.unix(time).format('YYYY-MM-DD HH:mm')
  }
}
window.g_getDayTime = getDayTime












