import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DecorateLeft from './decorate-left';
import DecorateCenter from './decorate-center';
import DecorateRight from './decorate-right';
import moment from 'moment';

import './index.scss';
import { widgets } from './config';
import { message, Modal } from 'antd';

function Decorate(props) {
  const [itemsLeft, setItemsLeft] = useState(widgets);
  const [itemsCenter, setItemsCenter] = useState([]);
  const [activeIndex, setActiveIndex] = useState('0');
  const [itemActive, setItemActive] = useState(null);
  const [setting, setSetting] = useState(null);
  const [openPrevidwModal, setOpenPrevidwModal] = useState(false);
  const [previewQrCodeUrl, setPreviewQrCodeUrl] = useState('');
  useEffect(() => {
    const json_field = props.location?.query?.json_field || null;
    const decorateId = props.location?.query?.active_id || null;
    const miniprogramId = props.location?.query?.miniprogramId || null;
    const authorization_appid = props.location?.query?.authorization_appid || null;
    const title = props.location?.query?.title || null;
    const vist_num = props.location?.query?.vist_num || null;
    const effect_time = props.location?.query?.effect_time || [
      moment(new Date(), 'YYYY-MM-DD HH:mm'),
      moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
    ];
    const image = props.location?.query?.image || null;
    const province = props.location?.query?.province || null;
    const city = props.location?.query?.city || null;
    const is_hidden = props.location?.query?.is_hidden || null;
    const is_sms = props.location?.query?.is_sms || null;
    const template_id = props.location?.query?.template_id || null;
    const success_msg = props.location?.query?.success_msg || null;
    const error_msg = props.location?.query?.error_msg || null;

    if (json_field) {
      let list = JSON.parse(json_field.replaceAll('&quot;', '"'));
      setItemsCenter(
        list.map((info, index) => {
          return {
            draggableId: String(index),
            info: {
              ...info,
              draggableId: String(index),
            },
          };
        })
      );
    }
    setSetting({
      title,
      decorateId,
      miniprogramId,
      vist_num,
      effect_time,
      image,
      province,
      city,
      authorization_appid,
      is_hidden,
      is_sms,
      template_id,
      success_msg,
      error_msg,
    });
    setItemActive({
      info: {
        label: '组件配置',
        title,
        decorateId,
        miniprogramId,
        vist_num,
        effect_time,
        image,
        province,
        city,
        authorization_appid,
        is_hidden,
        is_sms,
        template_id,
        success_msg,
        error_msg,
      },
    });
  }, []);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.draggableId === 'empty') return;
    if (result.source.droppableId === 'decorate-left') {
      // 新增widget
      result.draggableId = String(itemsCenter.length);
      result.info = { ...itemsLeft[result.source.index], draggableId: result.draggableId };

      let newItems = [...itemsCenter];
      newItems.splice(result.destination.index, 0, result);
      setItemsCenter(newItems);
      setActiveIndex(result.draggableId);
      setItemActive(newItems[result.destination.index]);
    } else {
      // 调整widget
      setItemsCenter(reorder(itemsCenter, result.source.index, result.destination.index));
      setActiveIndex(result.draggableId);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const setActive = item => {
    console.log(item);
    if (item) {
      setActiveIndex(item.draggableId);
      setItemActive(item);
    } else {
      setItemActive({ info: { label: '组件配置', ...setting } });
    }
  };

  // 修改item信息
  const changeItem = info => {
    if (info.label === '组件配置') {
      setSetting({ ...info });
    } else {
      let newItems = [...itemsCenter];
      let index = newItems.findIndex(i => i.draggableId === itemActive.draggableId);
      itemActive.info = info;
      newItems.splice(index, 1, itemActive);
      setItemsCenter(newItems);
    }
  };

  // 删除item
  const deleteItem = () => {
    let newItems = [...itemsCenter];
    let index = newItems.findIndex(i => i.draggableId === itemActive.draggableId);
    newItems.splice(index, 1);
    setItemsCenter(newItems);

    // 删除item后默认不激活
    setItemActive(null);
  };

  // 保存所有数据
  const save = async (preview = false) => {
    const result = [...itemsCenter].map(item => {
      let info = { ...item.info };
      delete info.img;
      // 这里为什么要删掉label呢？回显的时候岂不是组件的label都没有了？
      // delete info.label;
      delete info.draggableId;
      return info;
    });

    if (!setting?.title?.length) {
      message.warning('请填写留资活动标题');
      setItemActive({ info: { label: '组件配置', ...setting } });
      return;
    }

    if (!setting?.miniprogramId) {
      message.warning('请选择关联小程序');
      setItemActive({ info: { label: '组件配置', ...setting } });
      return;
    }

    if (!setting?.effect_time) {
      message.warning('请选择活动有效期');
      setItemActive({ info: { label: '组件配置', ...setting } });
      return;
    }

    if (!setting?.image) {
      message.warning('请上传活动封面');
      setItemActive({ info: { label: '组件配置', ...setting } });
      return;
    }

    if (!setting?.province) {
      message.warning('请选择活动地区-省份');
      setItemActive({ info: { label: '组件配置', ...setting } });
      return;
    }

    if (!setting?.city) {
      message.warning('请选择活动地区-城市');
      setItemActive({ info: { label: '组件配置', ...setting } });
      return;
    }

    if (setting?.is_sms && !setting?.template_id) {
      message.warning('请选择短信模版');
      setItemActive({ info: { label: '组件配置', ...setting } });
      return;
    }

    let formData = new FormData();
    formData.append('title', setting.title);
    formData.append('vist_num', setting.vist_num);
    formData.append('start_time', moment(setting.effect_time[0]).unix());
    formData.append('end_time', moment(setting.effect_time[1]).unix());
    formData.append('image', setting.image);
    formData.append('province', setting.province);
    formData.append('city', setting.city);
    formData.append('is_sms', setting.is_sms);
    formData.append('template_id', setting.template_id);
    formData.append('status', '1');
    formData.append('json_field', JSON.stringify(result));
    formData.append('success_msg', setting.success_msg);
    formData.append('error_msg', setting.error_msg);
    let active_id = '';
    let authorization_appid = '';
    try {
      if (setting.decorateId) {
        active_id = setting.decorateId;
        formData.append('active_id', setting.decorateId);

        await window.g_dyFetch({
          url: window.g_api.dy_edit_active_url,
          method: 'POST',
          body: formData,
        });
      } else {
        formData.append('app_id', setting.miniprogramId);
        if (preview) {
          formData.append('is_hidden', '1');
        }

        let res = await window.g_dyFetch({
          url: window.g_api.dy_add_active_url,
          method: 'POST',
          body: formData,
        });
        console.log('res:', res);
        active_id = res.data.active_id;
        authorization_appid = res.data.appid;
        setSetting({ ...setting, decorateId: active_id, authorization_appid: authorization_appid });
        setItemActive({
          info: { ...setting, decorateId: active_id, authorization_appid: authorization_appid },
        });
      }
      message.success('操作成功');
      if (!preview) {
        window.history.back();
      } else {
        await getPreviewQrCode({
          authorization_appid: authorization_appid,
          version: 'latest',
          path: 'pages/activity/index?active_id=' + active_id,
        });
        setOpenPrevidwModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 保存并预览
  const saveAndPreview = async () => {
    save(true);
  };

  // 获取预览二维码
  const getPreviewQrCode = async info => {
    let formData = new FormData();
    formData.append(
      'authorization_appid',
      info.authorization_appid ? info.authorization_appid : setting.authorization_appid
    );
    formData.append('version', info.version);
    formData.append('path', info.path);
    let res = await window.g_dyFetch({
      url: window.g_api.get_preview_qrcode_url,
      method: 'POST',
      body: formData,
    });
    setPreviewQrCodeUrl(res.data);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="decorate">
          <DecorateLeft items={itemsLeft} />
          <DecorateCenter
            items={itemsCenter}
            activeIndex={activeIndex}
            deleteItem={deleteItem}
            setActive={setActive}
            save={save}
            saveAndPreview={saveAndPreview}
          />
          <DecorateRight
            info={itemActive?.info}
            changeItem={changeItem}
            deleteItem={deleteItem}
            setting={setting}
          />
        </div>
      </DragDropContext>
      <Modal
        title="请在抖音中扫码预览"
        open={openPrevidwModal}
        onOk={() => {
          setOpenPrevidwModal(false);
        }}
        onCancel={() => {
          setOpenPrevidwModal(false);
        }}
      >
        <div style={{ margin: '20px auto', textAlign: 'center' }}>
          <img src={previewQrCodeUrl} alt="" style={{ width: 200, height: 200 }} />
        </div>
      </Modal>
    </>
  );
}

export default Decorate;
