import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

class FilterDropdown extends React.Component {
  render() {
    const { setSelectedKeys, selectedKeys, clearFilters, confirm, placeholder='搜索', helpText='' } = this.props;
    return <div style={{ padding: 8 }}>
      {helpText && <div>{helpText}</div>}
      <Input
        placeholder={placeholder}
        value={selectedKeys}
        onPressEnter={confirm}
        onChange={e => setSelectedKeys(e.target.value ? e.target.value : '')}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="ant-table-filter-dropdown-link button" onClick={()=>confirm()}>确定</div>
        <div className="ant-table-filter-dropdown-link button" onClick={clearFilters}>重置</div>
      </div>
    </div>
  }
}



export default function (options) {
  return {
    filterDropdown: (props) => <FilterDropdown {...props} {...options} />,
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  };
}
