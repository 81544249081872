export const getAllCategory = async item => {
  let appid = item?.app_id || 'tt5eef2852c1d0289001';

  let { data } = await window.g_dyFetch({
    url: window.g_api.dy_get_all_categories_url,
    method: 'GET',
    body: {
      authorization_appid: appid,
    },
  });

  data.forEach(category => {
    category.label = category.name;
    category.value = category.id;
    if (category.spid !== 0) {
      let parent = data.find(parent => parent.id === category.spid);
      if (parent.children) {
        parent.children.push(category);
      } else {
        parent.children = [category];
      }
    }
  });
  data.forEach(category => {
    if (category.fpid !== 0 && category.spid === 0) {
      let parent = data.find(parent => parent.id === category.fpid);
      if (parent.children) {
        parent.children.push(category);
      } else {
        parent.children = [category];
      }
    }
  });
  let temp = data.filter(category => {
    return category.level === 1;
  });
  return temp;
};

export const getAllIndustryCategoryCode = async () => {
  let { data } = await window.g_dyFetch({
    url: window.g_api.dy_industry_category_code_url,
    method: 'GET',
    body: {},
  });

  data.forEach(item => {
    item.label = item.pname;
    item.value = item.id;
    item.children = item.child.map(oItem => {
      oItem.label = oItem.name;
      oItem.value = oItem.code;
      delete oItem.name;
      delete oItem.id;
      delete oItem.code;
      return oItem;
    });
    delete item.sname;
    delete item.pname;
    delete item.id;
    delete item.code;
    delete item.child;
  });
  return data;
};
