/**
 * 组织架构
 */
import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Modal, Tree, Input } from 'antd';
import { useSingleState } from 'nice-hooks';
import _ from 'lodash'
import { useEffect } from 'react';
import Avatar from '../../asset/image/default_avatar.svg'
import './addUser.scss'



const { Search } = Input;

const UserSelectModal = ({
  allUsersMap,
  allUsersTree,
  value,
  visible,
  onCancel,
  onOk,
}) => {
  const [state, setState] = useSingleState({
    checkedKeys: [],
    selectedUsers: [],
    expandedKeys: [],
    searchValue: '',
    searchUsersTree: [],
    sValue:'',
    autoExpandParent: true,
  })


  const userIdsToCheckedKeys = (userIds) => {
    return userIds.reduce((result, userId) => {
      const user = allUsersMap[userId]
      if (user) {
        result.push(...user.departments.map(d => `department:${d}:user:${userId}`))
      }
      return result
    }, [])
  }

  const checkedKeysToUserIds = (checkedKeys) => {
    return Array.from(checkedKeys.reduce((result, item) => {
      if (item.includes('user')) {
        result.add(item.split(':').pop())
      }
      return result
    }, new Set()))
  }

  useEffect(() => {
    const checkedKeys = userIdsToCheckedKeys(value);
    const firstKey = allUsersTree.length > 0 && _.head(allUsersTree).key;
    setState({
      checkedKeys, selectedUsers: value,
      expandedKeys: [firstKey]
    })

  }, [visible, value])

  const onCheck = (checkedKeys) => {
    let disEffectSelectedUsers = []; // 不受影响的被选中用户
    if (state.searchValue) {
      disEffectSelectedUsers = state.selectedUsers.filter(userId => !allUsersMap[userId]?.title.includes(state.searchValue))
    }
    const selectedUsers = [...disEffectSelectedUsers, ...checkedKeysToUserIds(checkedKeys)]
    setState({ checkedKeys, selectedUsers })
  }

  const unSelectUser = (userId) => {
    const selectedUsers = state.selectedUsers.filter(item => item !== userId)
    const checkedKeys = userIdsToCheckedKeys(selectedUsers)
    setState({ checkedKeys, selectedUsers })
  }

  const onSubmit = () => {
    typeof onOk === 'function' && onOk(state.selectedUsers)
  }

  // const onSearch = (searchKey) => {
  //   // console.log('allUsersTree', allUsersTree)
  //   const userId = Object.keys(allUsersMap).find(userId => allUsersMap[userId].title === searchKey.trim())
  //   if (userId && !state.selectedUsers.includes(userId)) {
  //     const selectedUsers = [userId, ...state.selectedUsers]
  //     const checkedKeys = userIdsToCheckedKeys(selectedUsers)
  //     setState({ checkedKeys, selectedUsers })
  //   }
  // }

  const onSearch = (searchValue) => {
    const expandedKeysSet = new Set()
    const loopTree = (tree = [], parents = []) => {
      // console.log('tree', tree)
      const nodes = []
      for (const item of tree) {
        // console.log('item', item)
        if (item.type === 'user' && item?.title &&  item?.title.includes(searchValue)) {
          for (const parent of parents) {
            expandedKeysSet.add(parent);
          }
          nodes.push(item)
        }
        if (item.type === 'department' && item.children.length > 0) {
          const departmentChildren = loopTree(item.children, [...parents, item.key])
          if (departmentChildren.length > 0) {
            nodes.push({ ...item, children: departmentChildren })
          }
        }
      }
      return nodes
    }
    const searchUsersTree = loopTree(allUsersTree)

    let effectSelectedUsers = state.selectedUsers
      .filter(userId => allUsersMap[userId]?.title.includes(searchValue)); // 受影响的被选中用户
    const checkedKeys = userIdsToCheckedKeys(effectSelectedUsers)

    setState({
      expandedKeys: Array.from(expandedKeysSet),
      checkedKeys,
      searchValue,
      searchUsersTree,
      autoExpandParent: true,
    })
  }

  const onExpand = expandedKeys => {
    setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };



  return (
    <div>
      <Modal
        className='users_modal'
        visible={visible}
        onCancel={onCancel}
        onOk={onSubmit}
        width={640}
        destroyOnClose={true}
        getContainer={document.getElementsByClassName('div')[0]}
      >
        <h2>添加成员</h2>
        <p className='info'>
          {/* 没有想要添加的成员？<span className='span'>添加成员教程</span> */}
        </p>
        <div className='user_content'>
          <div className='user_content__left'>
            <div className='user_content__left__top'>
              <Search
                allowClear
                placeholder="请输入成员昵称"
                onSearch={onSearch}
                onChange={(e) => setState({ sValue: e.target.value })}
                value={state.sValue}
                style={{
                  display: 'unset'
                }}
              />
            </div>
            <div className='all-member'>
              <Tree
                defaultExpandParent={true}
                autoExpandParent={state.autoExpandParent}
                onExpand={onExpand}
                expandedKeys={state.expandedKeys}
                showIcon
                treeData={state.searchValue ? state.searchUsersTree : allUsersTree}
                selectable={false}
                checkable
                checkedKeys={state.checkedKeys}
                onCheck={onCheck}
              />
            </div>
          </div>
          <div className='user_content__right'>
            <p>已选成员：{state.selectedUsers.length}</p>
            <ul className='selected_list user_list'>
              {
                state.selectedUsers.map(userId => {
                  const user = allUsersMap[userId]
                  return user ? (
                    <li key={userId}>
                      <div className='userInfo'>
                        <img src={user.avatar || Avatar} alt="" className='avatar' />
                        <div className='user_name'>{user.title}</div>
                      </div>
                      <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => unSelectUser(userId)} />
                    </li>
                  ) : null;
                })
              }
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UserSelectModal;