import React, { useState } from "react";
import { ChromePicker } from 'react-color';
import './Color.scss'



function Color({
  value,
  onChange = () => { },
}) {
  const [visible, setVisible] = useState(false)
  return <div className="myColor">
    <div className="swatch" style={{ background: value }} onClick={() => setVisible(true)}>
    </div>
    {visible ? <div className="popover">
      <div className="cover" onClick={() => setVisible(false)} />
      <ChromePicker color={value} onChange={(color) => onChange(color.hex)} />
    </div> : null}
  </div>
}
export default Color