import React from 'react';

import { FilterOutlined } from '@ant-design/icons';

import { Select } from 'antd';
import _ from 'lodash'

const { Option } = Select;

class FilterDropdown extends React.Component {

  state = ({
    page: 1,
    pageSize: 15,
    selectedKeys: '',
    scrollPage: 1,
    keyword: '',
    userDataList: this.props.userDatas,
    userTotal: 0
  });

  // 输入时的回调，自动发送请求
  userData = _.debounce((value) => {
    if (value) {
      this.setState({
        scrollPage: 1,
        userDataList: []
      })
    } else {
      this.setState({
        userDataList: []
      })
    }
    window.g_appbase.get_app_token((token) => {
      let formData = new FormData()
      formData.append('p', this.state.scrollPage)
      formData.append('per', this.state.pageSize)
      formData.append('is_addressbook', 1)
      formData.append('department_id', this.state.selectedKeys)
      formData.append('keyword', value || '')
      window.g_postFetch(window.g_api.get_all_code + '?token=' + token, formData, ((res) => {
        this.setState({
          userDataList: res.data.all_code
        })
        this.setState({
          userTotal: res.total
        })
      }))
    })
  }, 500)


  onSelectScroll = (e) => {
    e.persist()
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      const nextScrollPage = this.state.scrollPage + 1
      this.setState({
        scrollPage: nextScrollPage
      }, () => {
        window.g_appbase.get_app_token((token) => {
          let formData = new FormData()
          formData.append('p', this.state.scrollPage)
          formData.append('per', this.state.pageSize)
          formData.append('is_addressbook', 1)
          formData.append('department_id', this.state.selectedKeys)
          formData.append('keyword', this.state.keyword || '')
          window.g_postFetch(window.g_api.get_all_code + '?token=' + token, formData, ((res) => {
            this.setState({
              userDataList: [...this.state.userDataList, ...res.data.all_code]
            })
            this.setState({
              userTotal: res.total
            })
          }))
        })
      })
    }
  }


  handleReset = ()=>{
    this.setState({
      userDataList: []
    },()=> {
      this.props.clearFilters()
    })
  }

  render() {
    const { setSelectedKeys, selectedKeys, clearFilters, confirm, placeholder = '搜索', helpText = '', userDatas } = this.props;
    const { userDataList } = this.state
    return <div style={{ padding: 8 }}>
      {helpText && <div>{helpText}</div>}
      <Select
        value={selectedKeys}
        onChange={value => setSelectedKeys(value ? value : '')}
        onSearch={this.userData}
        filterOption={false}
        showSearch
        onPopupScroll={(e) => this.onSelectScroll(e)}
        placeholder={placeholder}
        style={{ width: 180 }}
      >
        {(userDataList.length >= 1 ? userDataList : userDatas).map((item, index) => (
          <Option key={index} value={item.id}>{item.code_name}</Option>
        ))}
      </Select>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="ant-table-filter-dropdown-link button" onClick={confirm}>确定</div>
        <div className="ant-table-filter-dropdown-link button" onClick={this.handleReset}>重置</div>
      </div>
    </div >
  }
}



export default function (options) {
  return {
    filterDropdown: (props) => <FilterDropdown {...props} {...options} />,
    filterIcon: filtered => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  };
}
