import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, Spin } from 'antd';


function ExportModal({
  title = '导出数据',
  visible,
  exportList,
  loading,
  onOk,
  onCancel
}) {
  const [checkedList, set_checkedList] = useState()
  function onChange(checkedValues) {
    set_checkedList(checkedValues)
  }
  useEffect(() => {
    const newList = exportList.filter(item => item.default === 1).map(aa => aa.value)
    set_checkedList(newList)
  }, [visible, exportList])

  function onSubmit() {
    typeof onOk === 'function' && onOk(checkedList)
  }

  return <Modal title={title} visible={visible} onOk={onSubmit} onCancel={onCancel}>
    <Spin spinning={loading} />
    <Checkbox.Group options={exportList} value={checkedList} onChange={onChange} />
  </Modal>
}

export default ExportModal