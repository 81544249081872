import React, { useState, useEffect, forwardRef } from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Upload, Button, Progress, message } from 'antd';
import appbase from '@/js/appbase';
import ApiConfig from '@/api/apiconfig';
import classNames from 'classnames';
import styles from './Image.scss';
import MaterialModal from './MaterialModal';

function Image(
  {
    value,
    className = 'MyFormItem__Image',
    uploadProps = {},
    width = 120,
    height = 120,
    onChange = () => {},
    isMoments,
    fileSize,
    disabled = false, //禁用
    isUseMaterial = false, //是否调用素材库
    fileType = 'image',
  },
  ref
) {
  const [token, setToken] = useState();
  const [progressStatus, setProgressStatus] = useState('normal');
  const [progressPercent, setProgressPercent] = useState(0);
  const [imageWidth, SET_imageWidth] = useState(0);
  const [imageHeight, SET_imageHeight] = useState(0);
  const [visible, set_visible] = useState(false);
  useEffect(() => {
    appbase.get_app_token(token => {
      setToken(
        isMoments
          ? window.g_api.request_action + '?token=' + token + '&request_action=' + 'point/check_img'
          : ApiConfig.file_upload + '?token=' + token
      );
    });
  }, []);

  const onUploadChange = ({ file }) => {
    setProgressStatus(
      {
        uploading: 'active',
        done: 'success',
        error: 'exception',
      }[file.status]
    );
    if (file.status === 'done') {
      if (file.response.code == -1) {
        message.warn(file.response.msg);
        return Upload.LIST_IGNORE;
      }
      const data = file.response.data.info.upload;
      onChange(data);
    } else if (file.status === 'uploading') {
      setProgressPercent(file.percent);
    }
  };

  const beforeUpload = file => {
    if (fileType === 'image') {
      if (!['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(file.type)) {
        message.error('请上传jpg/jpeg/gif/png格式的图片');
        return false;
      }
      const isLtFileSize = file.size / 1024 / 1024 < fileSize;
      if (fileSize && !isLtFileSize) {
        message.error('图片大小不能超过' + fileSize + 'M');
        return false;
      }
    } else if (fileType === 'video') {
      if (!['video/ogg', 'video/mp4', 'video/wehm'].includes(file.type)) {
        message.error('请上传ogg/mp4/wehm格式的视频');
        return false;
      }
      const isLtFileSize = file.size / 1024 / 1024 < fileSize;
      if (fileSize && !isLtFileSize) {
        message.error('视频大小不能超过' + fileSize + 'M');
        return false;
      }
    } else if (fileType === 'audio') {
      if (!['audio/mpeg', 'audio/wav', 'audio/ogg'].includes(file.type)) {
        message.error('请上传ogg/mpeg/wav格式的视频');
        return false;
      }
      const isLtFileSize = file.size / 1024 / 1024 < fileSize;
      if (fileSize && !isLtFileSize) {
        message.error('音频大小不能超过' + fileSize + 'M');
        return false;
      }
    }

    const imageUrl = window.URL.createObjectURL(file);
    const imageElement = document.createElement('img');
    imageElement.src = imageUrl;
    imageElement.onload = () => {
      SET_imageHeight(imageElement.height);
      SET_imageWidth(imageElement.width);
    };
  };

  const renderContent = () => {
    if (progressStatus === 'active') {
      return (
        <div className={styles.uploading} style={{ width, height }}>
          <Progress
            type="circle"
            percent={Number(progressPercent.toFixed(2))}
            width={70}
            status={progressStatus}
          />
        </div>
      );
    } else if (value) {
      return (
        <div className="Image_show">
          <img
            style={{ width, height, objectFit: 'contain' }}
            src={value.indexOf('http') != 0 ? window.g_isCos(value) : value}
            // src={value}
            alt=""
            className="image"
          />
        </div>
      );
    } else {
      return (
        <Button style={{ borderRadius: 0, width, height }}>
          <CloudUploadOutlined />
          {/* <p>上传图片</p> */}
        </Button>
      );
    }
  };

  function onShowModal() {
    set_visible(true);
  }
  function onCancel() {
    set_visible(false);
  }

  return (
    <div className={classNames(styles.Image, className)} ref={ref}>
      <Upload
        action={token}
        onChange={onUploadChange}
        width={width}
        height={height}
        beforeUpload={beforeUpload}
        disabled={disabled}
        accept={fileType === 'video' ? 'video/*' : fileType === 'audio' ? 'audio/*' : 'image/*'}
        headers={{
          'X-Requested-With': null,
        }}
        showUploadList={false}
        {...uploadProps}
      >
        {renderContent()}
      </Upload>
      {isUseMaterial && (
        <Button type="link" onClick={onShowModal}>
          从素材库中选择
        </Button>
      )}
      <MaterialModal
        visible={visible}
        onChange={onChange}
        onCancel={onCancel}
        materialType="image"
      />
    </div>
  );
}

export default forwardRef(Image);
