/*
 * @Author: zhao
 * @Date: 2021-05-19 19:31:22
 * @LastEditTime: 2022-01-26 14:54:26
 * @LastEditors: Please set LastEditors
 * @Description: 
 */
import React, { PureComponent } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin, Modal, Button } from 'antd';

import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import comRequest from '../../api/comrequest';
import appbase from '../../js/appbase';
import './index.css'

const FormItem = Form.Item
class Login extends PureComponent {
  state = {
    visible: false,
    modalData: null
  }
  
  componentDidMount() {
    var that = this;
    
    appbase.get_app_token(function (res, errcode) {
      const system = localStorage.getItem('system')
      if (errcode == 0) {
        if (system == 1 || system == 2) {
          console.log('1111111')
          localStorage.setItem('allowJump', 1)
          that.props.history.push('/home');
        } else {
          console.log('22222')
          localStorage.setItem('allowJump', 0)
          window.location.href = "/home";
        }
      } else {
        that.setState({
          visible: true,
          modalData: res
        })
      }
    })

  }

  render() {
    const { modalData } = this.state
    return (
      <div className="login_loading">
        <Spin size="large" />
        <Modal
          title='提示'
          visible={this.state.visible}
          closable={false}
          footer={
            <div>
              {
                modalData?.url_title ? <a href={modalData?.doc_url} target='_blank'><Button>{modalData?.url_title}</Button></a> : null
              }
              <a href='/pc/login'><Button style={{ marginLeft: 20 }} type='primary'>我知道了</Button></a>
            </div>
          }
        >
          {modalData?.info}
        </Modal>
      </div>)
  }
}

export default Form.create()(Login);
