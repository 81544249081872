import React, { useState, useEffect } from 'react';
import {
  Space,
  Table,
  Button,
  message,
  Popconfirm,
  Form,
  Select,
  Row,
  Col,
  Drawer,
  Input,
} from 'antd';

import './index.scss';
const { Option } = Select;

function AuthUser() {
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [miniprogramList, setMiniprogramList] = useState([]);
  const [searchForm] = Form.useForm();
  const [bindAuthUserForm] = Form.useForm();
  const [capacityKeyList] = useState([
    { title: '短视频自主挂载', label: '短视频自主挂载', key: 0, value: 'video_self_mount' },
    { title: '直播自主挂载', label: '直播自主挂载', key: 1, value: 'live_self_mount' },
  ]);
  const onClose = () => {
    setOpen(false);
  };
  const initSearchForm = () => {
    return (
      <Form
        layout="inline"
        form={searchForm}
        onFinish={values => {
          searchTableDataFun(values);
        }}
      >
        <Form.Item label="选择小程序" name="authorization_appid">
          <Select placeholder="请选择绑定的小程序" style={{ width: '200px' }}>
            {miniprogramList.map(item => {
              return (
                <Option value={item.app_id} key={item.app_id}>
                  {item.app_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="挂载类型" name="capacity_key">
          <Select placeholder="请选择挂载类型" style={{ width: '200px' }} allowClear>
            {capacityKeyList.map(item => {
              return (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={() => {
              searchForm.resetFields();
            }}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const searchTableDataFun = async values => {
    setPage(1);
    setSize(size);
    getTableData();
  };

  const columns = [
    {
      title: '关联小程序',
      dataIndex: 'app_name',
      key: 'app_name',
    },
    {
      title: '抖音昵称',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: '抖音号',
      dataIndex: 'aweme_id',
      key: 'aweme_id',
    },
    {
      title: '挂载类型',
      dataIndex: 'capacity_key',
      key: 'capacity_key',
      render: (_, record, index) => (
        <span>
          {record.capacity_key === 'video_self_mount'
            ? '短视频自主挂载'
            : record.capacity_key === 'live_self_mount'
            ? '直播自主挂载'
            : ''}
        </span>
      ),
    },
    {
      title: '授权状态',
      dataIndex: 'bind_status',
      key: 'bind_status',
      render: (_, record, index) => (
        <span>
          {record.bind_status === '1'
            ? '授权待确认'
            : record.bind_status === '2'
            ? '授权成功'
            : record.bind_status === '3'
            ? '授权失败,' + record.unbind_reason
            : record.bind_status === '4'
            ? '取消绑定'
            : record.bind_status === '5'
            ? '封禁绑定'
            : record.bind_status === '6'
            ? '绑定失效'
            : ''}
        </span>
      ),
    },
    // {
    //   title: '授权时间',
    //   dataIndex: 'add_time',
    //   key: 'add_time',
    //   render: (_, record, index) => <span>{window.g_getDayTime(record.add_time)}</span>,
    // },
    {
      title: '操作',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          {window.g_checkRole(15) && (
            <Popconfirm
              title="确认要解绑吗?"
              onConfirm={() => {
                onDeleteClick(record);
              }}
              okText="确认"
              cancelText="取消"
            >
              <Button type="link">解绑</Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  useEffect(async () => {
    await getMiniprogramList();
  }, []);

  useEffect(async () => {
    if (miniprogramList.length) {
      searchForm.setFieldsValue({
        authorization_appid: miniprogramList[0].app_id,
      });
      await getTableData();
    }
  }, [miniprogramList, page]);

  // 获取列表数据
  const getTableData = async () => {
    let searchInfo = searchForm.getFieldValue();
    for (const key in searchInfo) {
      if (!searchInfo[key]) {
        delete searchInfo[key];
      }
    }
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_auth_user_list_url,
      method: 'GET',
      body: {
        page,
        pagesize: size,
        ...searchInfo,
      },
    });
    setTableData(data.list);
    setTotal(Number(data.total_count));
  };

  const getMiniprogramList = async () => {
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_app_list_url,
      method: 'GET',
    });
    setMiniprogramList(data);
  };

  const onDeleteClick = async record => {
    try {
      await window.g_dyFetch({
        url: window.g_api.dy_unbind_self_mount_user_url,
        method: 'POST',
        bodyType: 'obj',
        body: {
          authorization_appid: record.appid,
          capacity_key: record.capacity_key,
          aweme_id: record.aweme_id,
        },
      });
      message.success('解绑成功');
      getTableData();
    } catch (error) {
      message.error(error.msg);
    }
  };

  const submitAuthUser = async () => {
    let bindAuthUserInfo = bindAuthUserForm.getFieldValue();
    try {
      await window.g_dyFetch({
        url: window.g_api.dy_bind_self_mount_user_url,
        method: 'POST',
        bodyType: 'obj',
        body: bindAuthUserInfo,
      });
      message.success('授权发起成功，请通知对应抖音用户进行同意授权');
      getTableData();
    } catch (error) {
      message.error(error.msg);
    }
  };

  return (
    <div className="auth-user">
      <Row className="chunk-view">
        <Col span={22}>{initSearchForm()}</Col>
        <Col span={2} style={{ textAlign: 'right' }}>
          {window.g_checkRole(14) && (
            <Button
              type="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              新增
            </Button>
          )}
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={tableData}
        rowKey="id"
        pagination={{
          total,
          onChange: (page, pageSize) => {
            setPage(page);
          },
        }}
      />
      <Drawer title="新增授权" placement="right" width={600} onClose={onClose} open={open}>
        <Form
          labelCol={{ span: 5 }}
          labelAlign="left"
          form={bindAuthUserForm}
          onFinish={values => {
            submitAuthUser(values);
          }}
        >
          <Form.Item label="选择小程序" name="authorization_appid">
            <Select placeholder="请选择要绑定的小程序">
              {miniprogramList.map(item => {
                return (
                  <Option value={item.app_id} key={item.app_id}>
                    {item.app_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="选择挂载类型" name="capacity_key">
            <Select placeholder="请选择要挂载类型">
              {capacityKeyList.map(item => {
                return (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="请输入抖音号" name="aweme_id">
            <Input placeholder="请输入要绑定的抖音号" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4 }}>
            <Button type="primary" htmlType="submit">
              提交绑定
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default AuthUser;
