import React from "react";
import { DatePicker } from 'antd'
import moment from "moment/moment";
import dayjs from "dayjs";

function DatePickerModule({
  value,
  onChange = () => { },
  showTime,
  isDisabledDate = true,
  isDisabledTime = true
}) {
  function disabledDate(current) {
    return current && current < moment().subtract(1, 'days').endOf('day')
  }
  // 时间选择器
  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  function disabledDateTime(date) {
    let hour = moment().format('HH');
    let minute = moment().subtract(-1, 'minutes').format('mm')
    if (moment(date).format('YYYY-MM-DD').valueOf() === moment().format('YYYY-MM-DD').valueOf()) {
      if (moment(date).format('HH').valueOf() !== moment().format('HH').valueOf()) {
        return {
          disabledHours: () => range(0, 24).splice(0, hour),
          disabledMinutes: () => range(0, 60).splice(0, 0),
        };
      } else {
        return {
          disabledHours: () => range(0, 24).splice(0, hour),
          disabledMinutes: () => range(0, 60).splice(0, minute),
        };
      }
    }
  }


  return <DatePicker
    showTime={{
      format: 'HH:mm',
      ...showTime,
    }}
    showNow
    format="YYYY-MM-DD HH:mm"
    value={value ? moment(value, 'YYYY-MM-DD HH:mm') : ''}
    disabledDate={isDisabledDate ? disabledDate : false}
    disabledTime={isDisabledTime ? disabledDateTime : false}
    onChange={(_, newDateTime) => {
      const minutes = new Date(newDateTime).getMinutes()
      const newdate = showTime?.minuteStep ? new Date(newDateTime).setMinutes(Math.floor(minutes / showTime?.minuteStep) * showTime?.minuteStep) : newDateTime
      return newDateTime ? onChange(dayjs(newdate).format('YYYY-MM-DD HH:mm')) : onChange(undefined)
    }}
  />
}
export default DatePickerModule