import ApiConfig from '../api/apiconfig';
import postFetch from '../js/fetch';
import { Button, message, Modal } from 'antd';

//企业微信授权js
var appbase = {
  //企业微信内授权登录
  get_app_token: function (callback_func) {
    var access_key = ApiConfig.appId + '_pull_msg_token_' + ApiConfig.debug;
    var access_token = this.getCookie(access_key);

    // if(ApiConfig.debug=="dev"){
    //     access_token = "544806b29098381d1e8076bdc4d231c7";
    // }
    if (access_token != '') {
      //优先本地
      if (typeof callback_func == 'function') callback_func(access_token, 0);
      return;
    }

    //前一次消费过的code
    var before_code = localStorage.getItem('before_code');
    var code_value = '';
    var chain_id = '';
    // console.log("redirecturl", window.location.href)
    if (window.g_api.use_edition === 'saas') {
      // console.log("redirecturl", window.location)
      // console.log("this.GetQueryString('appid');",this.GetQueryString('appid'))
      code_value = this.GetQueryString('auth_code');
      if (!code_value) {
        code_value = this.GetQueryString('code');
        chain_id = this.GetQueryString('appid');
      }
    } else {
      code_value = this.GetQueryString('code');
    }
    //非空切不是消费过的code则进入换取用户信息
    if (code_value != null && code_value != '' && before_code != code_value) {
      // console.log('2222test ----',chain_id)
      localStorage.setItem('before_code', code_value);
      this.get_access_token(code_value, chain_id, callback_func);
    } else {
      window.location.href = '/pc/login';
    }
  },

  get_qywechat_user_4_test: function (page_location, callback_func) {
    window.location.href =
      'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
      ApiConfig.suitId +
      '&redirect_uri=' +
      encodeURIComponent(window.location.href) +
      '&response_type=code&scope=snsapi_privateinfo&state=wininsales_test#wechat_redirect';
  },

  get_access_token: function (code, chain_id, callback_func) {
    var get_apptoken_url = ApiConfig.qywechat_login_url;
    var that = this;
    let formData = new FormData();

    if (ApiConfig.use_edition == 'saas') {
      formData.append('chain_id', chain_id);
      formData.append('auth_code', code);
    } else {
      formData.append('code', code);
      formData.append('eid', that.GetQueryString('code') || '');
    }
    postFetch(get_apptoken_url, formData, res => {
      if (res.code == 0) {
        const menu = res.menu.filter(item => item.name != '首页');
        const openKeys = menu.map(item => item.id);
        var access_key = ApiConfig.appId + '_pull_msg_token_' + ApiConfig.debug;
        var user_range = ApiConfig.appId + '_pull_msg_user_range_' + ApiConfig.debug;
        window.g_cache.setItem('scrm_menu', menu);
        window.g_cache.setItem('scrm_auth', res.auth);
        appbase.setSessionGlobalItem('__access_sauth', JSON.stringify(res.auth));
        if (res.is_expired) {
          appbase.setSessionGlobalItem('_access_isExpired', res.is_expired);
        }
        localStorage.setItem('openKeys', openKeys);
        localStorage.setItem('system', res.system);
        // window.g_cache.setItem('currentUser', res.truename)
        window.g_cache.setItem(
          'currentUser',
          res.truename !== ''
            ? res.truename
            : res.enterprise.enterprise_name
            ? res.enterprise.enterprise_name
            : '请完善用户信息'
        );
        window.g_cache.setItem('company', res.enterprise.enterprise_name);
        window.g_cache.setItem('enterprise', res.enterprise);
        that.setCookie(access_key, res.access_token, 29000);
        that.setCookie(user_range, JSON.stringify(res.user_range), 29000);
        if (typeof callback_func == 'function') callback_func(res.access_token, res.code);
      } else {
        if (typeof callback_func == 'function') callback_func(res);
      }
    });
  },

  setCookie: function (name, value, time) {
    //传值为名，值，过期时间
    if (time) {
      //如果有过期时间的话则执行这个条件

      var oDate = new Date(); //获取当且的事件戳

      oDate.setTime(oDate.getTime() + time * 1000);

      document.cookie = name + '=' + value + '; path=/; expires=' + oDate.toGMTString(); //设置cookie
    } else {
      //如果有过期时间的话则执行这个条件 设置cookie
      document.cookie = name + '=' + value + '; path=/;domain=.wininsales.com'; //名，值以及根目录
    }
  },

  getCookie: function (name) {
    var arr,
      reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
    else return '';
  },

  removeCookie: function (name) {
    this.setCookie(name, '', -1); //再次调用setCookie,关键是把过期时间设置为负值
  },
  //获取页面时间，格式 2017-09-01
  getNowFormatDate: function () {
    var date = new Date();
    var seperator1 = '-';
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = '0' + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
  },

  GetQueryString: function (name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },

  GetLabelString: function () {
    var r = window.location.href.split('#');

    if (r != null && r.length > 1) return unescape(r[1]);
    return '';
  },

  /**
   * 时间戳转化为年 月 日 时 分 秒
   * number: 传入时间戳
   * format：返回格式，支持自定义，但参数必须与formateArr里保持一致
   */
  formatTime: function (number, format) {
    if (!number) return '-';

    var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    var returnArr = [];

    var date = new Date(number * 1000);
    returnArr.push(date.getFullYear());
    returnArr.push(appbase.formatNumber(date.getMonth() + 1));
    returnArr.push(appbase.formatNumber(date.getDate()));

    returnArr.push(appbase.formatNumber(date.getHours()));
    returnArr.push(appbase.formatNumber(date.getMinutes()));
    returnArr.push(appbase.formatNumber(date.getSeconds()));

    for (var i in returnArr) {
      format = format.replace(formateArr[i], returnArr[i]);
    }
    return format;
  },

  //数据转化
  formatNumber: function (n) {
    n = n.toString();
    return n[1] ? n : '0' + n;
  },

  //数据转化
  message_show: function (code, msg) {
    var that = this;
    if (code == '40007' || code == '40006') {
      message.info(msg).then(() => {
        window.location.href = '/';
        return;
      });
    } else {
      message.error(msg);
    }
  },

  highlight: function (str, keyword) {
    if (!str) return str;
    return str.replace(keyword, "<b style='color:red'>" + keyword + '</b>');
  },

  echo_msg_body: function (msgtype, content, keyword) {
    if (!content) return content;
    var content = JSON.parse(content);
    if (msgtype == 'image') {
      //图片
      return "<img src='" + content.filepath + "'></img>";
    } else if (msgtype == 'text') {
      //文字
      return this.highlight(content.content, keyword);
    } else if (msgtype == 'voice') {
      //语音
      return '<audio src={audio} autoPlay="false"/>';
    } else if (msgtype == 'video') {
      //视频
      return '<Video src={audio} autoPlay=false/>';
    } else if (msgtype == 'weapp') {
      //小程序
      return '[小程序]' + content.title;
    } else if (msgtype == 'agree') {
      //同意
      return '[同意会话存档]';
    } else if (msgtype == 'disagree') {
      //不同意
      return '[拒绝同意]';
    } else if (msgtype == 'card') {
      //名片
      return '[企业微信名片]' + content.corpname;
    } else if (msgtype == 'location') {
      //定位
      return '[位置]' + content.title + ' ' + content.address;
    } else if (msgtype == 'file') {
      //文件
      return (
        "<a href='" + content.filepath + "' target='_blank'>[文件]" + content.filename + '</a>'
      );
    } else if (msgtype == 'link') {
      //链接
      return "<a href='" + content.link_url + "' target='_blank'>[链接]" + content.title + '</a>';
    } else if (msgtype == 'chatrecord') {
      //会话记录消
      return '[会话记录消]' + content.title;
    } else if (msgtype == 'todo') {
      //待办
      return '[待办]' + content.title + ' ' + content.content;
    } else if (msgtype == 'vote') {
      //投票
      return '[投票]' + content.votetitle;
    } else if (msgtype == 'collect') {
      //填表
      return '[填报]' + content.title;
    } else if (msgtype == 'redpacket') {
      //红包
      return '[红包]' + content.title;
    } else if (msgtype == 'meeting') {
      //会议
      return '[会议]' + content.topic;
    } else if (msgtype == 'docmsg') {
      //在线文档
      return (
        '[在线文档]' +
        "<a href='" +
        content.link_url +
        "' target='_blank'>" +
        content.title +
        '</a>'
      );
    } else if (msgtype == 'news') {
      //图文
      return '[图文]' + content.title;
    } else if (msgtype == 'calendar') {
      //日历
      return '[图文]' + content.title;
    } else if (msgtype == 'revoke') {
      //撤回
      return '[撤回消息]' + content.title;
    } else if (msgtype == 'mixed') {
      //混合消息
      return '[混合消息]' + content.title;
    }
  },

  showtotal: function (total, length) {
    if (total && total > 0) {
      return length + '/' + total;
    }
    return '';
  },
  getSessionGlobalItem(code) {
    var value = sessionStorage.getItem(code);
    if (value) value = decodeURI(value);
    return value;
  },
  setSessionGlobalItem(code, value) {
    value = encodeURI(value);
    var value = sessionStorage.setItem(code, value);
  },
  removeSessionGlobalItem(code, value) {
    value = encodeURI(value);
    return sessionStorage.removeItem(code);
  },
};
window.g_appbase = appbase;
export default appbase;
