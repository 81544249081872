import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function DecorateLeft(props) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  return (
    <div className="decorate-left">
      <Droppable droppableId="decorate-left" type="column" isDropDisabled={true}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="widget-list">
            <div className="widget-group-title">全部组件</div>
            {items.map((item, index) => (
              <div key={item.type} className="widget-item-wrap">
                {/* {index === 0 && <div className="widget-group-title">基础组件</div>} */}
                {/* {index === 6 && <div className="widget-group-title">留资组件</div>} */}
                <Draggable draggableId={item.type} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="widget-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <img src={item.img} />
                      <div>{item.label}</div>
                    </div>
                  )}
                </Draggable>
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default DecorateLeft;
