import React from 'react';
import { Tooltip, Avatar } from 'antd';
import DetaultAvatar from '../../asset/image/default_avatar.svg'
import './customerInfo.scss';

export default ({ type = '', name = '', avatar, corp_name, remark, remark_corp_name, className = '', style }) => {
  function getCorpName(name) {
    return name ? `@${name}` : ''
  }
  return <div className='customerCommonInfo'>
    <img className='avatar' src={avatar ? avatar : DetaultAvatar} />
    <div className='customerinfo'>
      <div style={{ display: 'flex' }}>
        <span className='nickname'>{name}</span>
        <span className={`form ${type === '1' ? 'wx' : 'wb'}`}>
          {!type ? '' :
            type == '1' ? ' @微信' :
              type == '2' && (
                <Tooltip title={getCorpName(corp_name)}>
                  <span>{getCorpName(corp_name)}</span>
                </Tooltip>
              )
          }
        </span>
      </div>
      {remark &&
        <div className='remark'>
          <Tooltip title={remark}>
            <span>昵称: {remark}</span>
          </Tooltip>
        </div>
      }
      {remark_corp_name &&
        <div className='remark'>
          <Tooltip title={remark_corp_name}>
            <span>公司: {remark_corp_name}</span>
          </Tooltip>
        </div>
      }
    </div>
  </div>;
}