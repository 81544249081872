import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Radio,
  Checkbox,
  Popover,
  Modal,
  Upload,
  message,
  Select,
  DatePicker,
  Slider,
  InputNumber,
} from 'antd';
import { SketchPicker } from 'react-color';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import NewImage from '@/component/FormItem/NewImage';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

function DecorateRight(props) {
  const [miniprogramList, setMiniprogramList] = useState([]);
  const [smsTemplateList, setSmsTemplateList] = useState([]);
  const [pageMaxHeight, setPageMaxHeight] = useState(0);
  const [info, setInfo] = useState(null);
  const [setting, setSetting] = useState(null);
  const [draggableId, setDraggableId] = useState(null);
  const [tab, setTab] = useState(0);
  const [colorInputSketchPicker, setColorInputSketchPicker] = useState('');
  const [areas, setAreas] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [openTypeList, setOpenTypeList] = useState([
    {
      label: '消息推送客服',
      value: 'contact',
    },
    {
      label: '抖音IM客服',
      value: 'im',
    },
    {
      label: '授权手机号',
      value: 'getPhoneNumber',
    },
  ]);

  useEffect(() => {
    setInfo({ ...props.info });
    if (props.info && props.info.draggableId !== draggableId) {
      setDraggableId(props.info.draggableId);
      setTab(0);
    }
    setPageMaxHeight(document.getElementsByClassName('decorate-center-body')[0].offsetHeight);
  }, [props.info]);

  useEffect(() => {
    setSetting({ ...props.setting });
  }, [props.setting]);

  useEffect(() => {
    if (props.info && props.info.province) {
      let item = areas[props.info.province];
      if (item && Object.keys(item)) {
        let temp = Object.keys(item)
          .filter(key => key !== 'name')
          .map(key => {
            return {
              label: item[key],
              value: key,
            };
          });
        setCities(temp);
      }
    }
  }, [areas]);

  useEffect(() => {
    getMiniprogramList();
    getSmsTemplateList();
    getAllArea();

    const clickColorInput = e => {
      const className = e.target.className;
      if (className && typeof className === 'string') {
        if (className.indexOf('color-chunk') === -1) {
          setColorInputSketchPicker('');
        }
      }
    };

    document.addEventListener('click', clickColorInput);

    // 页面销毁时触发
    return () => {
      document.removeEventListener('click', clickColorInput);
    };
  }, []);

  const getMiniprogramList = async () => {
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_app_list_url,
      method: 'GET',
    });
    setMiniprogramList(
      data.map(item => {
        return {
          label: item.app_name,
          value: item.id,
        };
      })
    );
  };

  // 获取短信模版列表
  const getSmsTemplateList = async () => {
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_sms_template_url,
      method: 'GET',
    });
    console.log('data:', data);
    setSmsTemplateList(
      data.data.map(item => {
        return {
          label: item.name,
          value: item.task_id,
        };
      })
    );
  };

  const getAllArea = async () => {
    try {
      const { data } = await window.g_dyFetch({
        url: window.g_api.dy_get_all_area_url,
        method: 'GET',
      });

      let p = [];
      Object.keys(data).forEach(key => {
        p.push({
          value: key,
          label: data[key].name,
        });
      });
      setAreas(data);
      setProvinces(p);
    } catch (error) {
      message.error(error.msg);
    }
  };

  const inputNumberWidget = (title, style, prop, placeholder = '') => {
    return (
      <div className="info-item">
        <div className="title">{title}</div>
        {
          <InputNumber
            placeholder={placeholder}
            value={style ? info.style[prop] || '' : info[prop] || ''}
            onChange={e => {
              let temp = JSON.parse(JSON.stringify(info));
              if (style) {
                temp.style[prop] = e;
              } else {
                temp[prop] = e;
              }
              setInfo(temp);
              props.changeItem(temp);
            }}
          />
        }
      </div>
    );
  };

  const setZIndexWidget = (title, prop) => {
    return (
      <div className="info-item">
        <div className="title">{title}</div>
        <Button
          onClick={() => {
            let temp = JSON.parse(JSON.stringify(info));
            temp.style[prop] = Number(temp.style[prop]) + 1;
            setInfo(temp);
            props.changeItem(temp);
          }}
          style={{ marginRight: 10 }}
        >
          上移
        </Button>

        <Button
          disabled={Number(info.style[prop]) - 1 < 0}
          onClick={() => {
            let temp = JSON.parse(JSON.stringify(info));
            if (Number(temp.style[prop]) - 1 < 0) {
              return;
            }
            temp.style[prop] = Number(temp.style[prop]) - 1;
            setInfo(temp);
            props.changeItem(temp);
          }}
        >
          下移
        </Button>
      </div>
    );
  };

  const openSmsSwitch = () => {
    return (
      <>
        <div className="info-item">
          <div className="title">是否开启短信通知</div>
          <Radio.Group
            value={info.is_sms}
            onChange={e => {
              let temp = JSON.parse(JSON.stringify(info));
              let value = e.target.value;
              temp.is_sms = value;
              setInfo(temp);
              props.changeItem(temp);
            }}
          >
            <Radio value={'1'}>开启</Radio>
            <Radio value={'0'}>关闭</Radio>
          </Radio.Group>
        </div>
        {info.is_sms === '1' && selectWidget('选择短信模版', 'template_id', smsTemplateList)}
      </>
    );
  };

  const inputWidget = (
    title,
    style,
    prop,
    placeholder = '请输入',
    otherInfo = { type: 'input', min: 0, max: 1 },
    propOwnership = null,
    callCallback = null
  ) => {
    return (
      <div className="info-item">
        <div className="title">{title}</div>
        {otherInfo?.type === 'input' && (
          <Input
            placeholder={placeholder}
            value={style ? info.style[prop] : propOwnership ? propOwnership[prop] : info[prop]}
            onChange={e => {
              if (callCallback && typeof callCallback === 'function') {
                callCallback(e.target.value);
              } else {
                let temp = JSON.parse(JSON.stringify(info));
                if (style) {
                  temp.style[prop] = e.target.value;
                } else {
                  temp[prop] = e.target.value;
                }
                setInfo(temp);
                props.changeItem(temp);
              }
            }}
          />
        )}
        {otherInfo?.type === 'slider' && (
          <Slider
            min={otherInfo.min}
            max={otherInfo.max || 375}
            value={style ? info.style[prop] || 0 : info[prop] || 0}
            onChange={e => {
              let temp = JSON.parse(JSON.stringify(info));
              let val = e;
              // if (e === otherInfo.min - 1) {
              //   val = '';
              // }
              // if (style) {
              //   if (temp.style.position === 'fixed' && (prop === 'left' || prop === 'right')) {
              //     let x = 0;
              //     if (prop === 'right') {
              //       x = 375 - parseInt(temp.style.width) - val;
              //     } else {
              //       x = val;
              //     }
              //     console.log('x:', x);
              //     temp.style['translateX'] = x + '';
              //   }
              //   temp.style[prop] = val + '';
              // } else {
              //   temp[prop] = val + '';
              // }
              if (style) {
                temp.style[prop] = val + '';
              } else {
                temp[prop] = val + '';
              }
              setInfo(temp);
              props.changeItem(temp);
            }}
          />
        )}
      </div>
    );
  };

  const datePickerWidget = (title, prop) => {
    return (
      <div className="info-item">
        <div className="title">{title}</div>
        <RangePicker
          showTime={{
            format: 'HH:mm',
          }}
          format="YYYY-MM-DD HH:mm"
          defaultValue={[
            moment(info[prop][0], 'YYYY-MM-DD HH:mm'),
            moment(info[prop][1], 'YYYY-MM-DD HH:mm'),
          ]}
          onChange={(value, dateString) => {
            console.log(value, dateString);
            let temp = JSON.parse(JSON.stringify(info));
            // temp[prop] = e.target.value;
            temp[prop] = dateString;
            setInfo(temp);
            props.changeItem(temp);
          }}
        />
      </div>
    );
  };

  const checkboxWidget = (title, prop) => {
    return (
      <div className="info-item">
        <div className="title">{title}</div>
        <Checkbox
          checked={info[prop]}
          onChange={e => {
            let temp = JSON.parse(JSON.stringify(info));
            temp[prop] = e.target.checked;
            setInfo(temp);
            props.changeItem(temp);
          }}
        >
          显示
        </Checkbox>
      </div>
    );
  };

  const suspensionBtnListWidget = () => {
    return (
      <div className="info-item">
        {info['functionBtnList'].map((item, index) => {
          return (
            <>
              <div style={{ marginBottom: 10, border: '1px solid #ededed', padding: 10 }}>
                <div
                  className="title"
                  style={{
                    marginBottom: 5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>按钮文本</span>
                  <Button
                    size="small"
                    onClick={() => {
                      let temp = JSON.parse(JSON.stringify(info));
                      temp['functionBtnList'][index].open = !temp['functionBtnList'][index].open;
                      setInfo(temp);
                      props.changeItem(temp);
                    }}
                  >
                    {info['functionBtnList'][index].open ? '禁用' : '启用'}
                  </Button>
                </div>
                <div style={{ marginBottom: 5 }}>
                  {
                    <Input
                      value={item.text}
                      onChange={e => {
                        let temp = JSON.parse(JSON.stringify(info));
                        temp['functionBtnList'][index].text = e.target.value;
                        setInfo(temp);
                        props.changeItem(temp);
                      }}
                    />
                  }
                </div>
                <div className="title" style={{ marginBottom: 5 }}>
                  开放能力
                </div>
                <div style={{ marginBottom: 5 }}>
                  {
                    <Select
                      placeholder="请选择"
                      onChange={e => {
                        let temp = JSON.parse(JSON.stringify(info));
                        temp['functionBtnList'][index].openType = e;
                        setInfo(temp);
                        props.changeItem(temp);
                      }}
                      value={info['functionBtnList'][index].openType}
                      allowClear
                    >
                      {openTypeList.map(item => {
                        return (
                          <Option value={item.value} key={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  }
                </div>
                {info['functionBtnList'][index].openType === 'im' && (
                  <div style={{ marginBottom: 5 }}>
                    {inputWidget(
                      '客服抖音号',
                      false,
                      'dataImId',
                      '请输入客服的抖音号',
                      { type: 'input' },
                      info['functionBtnList'][index],
                      e => {
                        console.log(e);
                        info['functionBtnList'][index].dataImId = e;
                        setInfo(info);
                        console.log('info:', info);
                      }
                    )}
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
    );
  };

  const imageWidget = (title = '图片链接', prop = 'imageList', type = 'image', fileSize = 10) => {
    return (
      <div className="info-item">
        <div className="title">{title}</div>
        {title === '活动封面' && <div className="title">尺寸比例建议：750px*270px</div>}

        {Array.isArray(info[prop]) &&
          type === 'image' &&
          info[prop].map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  height: '200px',
                  overflow: 'hidden',
                  alignItems: 'center',
                  marginBottom: '15px',
                }}
              >
                {Array.isArray(info[prop]) && info[prop].length > 1 && (
                  <MinusCircleOutlined
                    style={{ marginRight: '10px', fontSize: '20px', color: '#4073fa' }}
                    onClick={() => {
                      let temp = JSON.parse(JSON.stringify(info));
                      temp[prop].splice(index, 1);
                      setInfo(temp);
                      props.changeItem(temp);
                    }}
                  />
                )}
                <img style={{ marginBottom: 10 }} src={window.g_api.base_url + item} />
              </div>
            );
          })}
        {!Array.isArray(info[prop]) && info[prop] && type === 'image' && (
          <img
            style={{ marginBottom: 10, width: '120px' }}
            src={window.g_api.base_url + info[prop]}
          />
        )}
        {type === 'video' && (
          <video
            controls
            style={{ marginBottom: 10, width: '100%' }}
            src={window.g_api.base_url + info[prop]}
          ></video>
        )}
        {type === 'audio' && (
          <audio controls style={{ marginBottom: 10, width: '100%', height: '40px' }}>
            <source src={window.g_api.base_url + info[prop]} type="audio/mpeg"></source>
          </audio>
        )}
        <NewImage
          isUseMaterial={false}
          fileType={type}
          fileSize={fileSize}
          onChange={e => {
            let temp = JSON.parse(JSON.stringify(info));
            if (Array.isArray(info[prop])) {
              temp[prop].push(e);
            } else {
              temp[prop] = e;
            }
            setInfo(temp);
            props.changeItem(temp);
          }}
        ></NewImage>
      </div>
    );
  };

  const imageFillWidget = () => {
    return (
      <div className="info-item">
        <div className="title">填充方式</div>
        <Radio.Group
          onChange={e => {
            let temp = JSON.parse(JSON.stringify(info));
            let value = e.target.value;
            temp.style.objectFit = value;
            setInfo(temp);
            props.changeItem(temp);
          }}
          value={info.style.objectFit}
        >
          <Radio value={'none'}>none</Radio>
          <Radio value={'fill'}>fill</Radio>
          <Radio value={'cover'}>cover</Radio>
          <Radio value={'contain'}>contain</Radio>
        </Radio.Group>
      </div>
    );
  };

  const textAlignWidget = () => {
    return (
      <div className="info-item">
        <div className="title">对齐方式</div>
        <Radio.Group
          onChange={e => {
            let temp = JSON.parse(JSON.stringify(info));
            let value = e.target.value;
            temp.style.textAlign = value;
            setInfo(temp);
            props.changeItem(temp);
          }}
          value={info.style.textAlign}
        >
          <Radio value={'left'}>居左</Radio>
          <Radio value={'center'}>居中</Radio>
          <Radio value={'right'}>居右</Radio>
        </Radio.Group>
      </div>
    );
  };

  const smallInputWidget = (title, style, prop) => {
    return (
      <div className="info-flex-item">
        <div className="flex-item-title">{title}</div>
        <Input
          size="small"
          defaultValue={style ? info.style[prop] : info[prop]}
          onChange={e => {
            let temp = JSON.parse(JSON.stringify(info));
            if (style) {
              temp.style[prop] = e.target.value;
            } else {
              temp[prop] = e.target.value;
            }
            setInfo(temp);
            props.changeItem(temp);
          }}
        />
      </div>
    );
  };

  const borderStyleWidget = () => {
    return (
      <div className="info-item">
        <div className="title">边框样式</div>
        <Radio.Group
          onChange={e => {
            let temp = JSON.parse(JSON.stringify(info));
            let value = e.target.value;
            temp.style.borderStyle = value;
            setInfo(temp);
            props.changeItem(temp);
          }}
          value={info.style.borderStyle}
        >
          <Radio value={'solid'}>实线</Radio>
          <Radio value={'dashed'}>虚线</Radio>
          <Radio value={'double'}>双实线</Radio>
        </Radio.Group>
      </div>
    );
  };

  const colorWidget = (title, prop, className) => {
    return (
      <div className="info-item">
        <div className="title">
          <span>{title}</span>
          <Button
            size="small"
            onClick={() => {
              let temp = JSON.parse(JSON.stringify(info));
              temp.style[prop] = 'unset';
              setInfo(temp);
              props.changeItem(temp);
            }}
          >
            清除
          </Button>
        </div>
        <Popover
          content={
            <SketchPicker
              color={info.style[prop]}
              onChange={e => {
                let temp = JSON.parse(JSON.stringify(info));
                temp.style[prop] = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`;
                setInfo(temp);
                props.changeItem(temp);
              }}
            />
          }
          title=""
          trigger="click"
          open={colorInputSketchPicker === className}
        >
          <div
            className="color-chunk"
            onClick={() => {
              setColorInputSketchPicker(className);
            }}
            style={{
              width: 20,
              height: 20,
              backgroundColor: info.style[prop],
              border: '1px solid #000',
            }}
          />
          {/* <Input
            value={info.style[prop]}
            className={className}
            onChange={e => {
              let temp = JSON.parse(JSON.stringify(info));
              temp.style[prop] = e.target.value;
              setInfo(temp);
              props.changeItem(temp);
            }}
          /> */}
        </Popover>
      </div>
    );
  };

  const formSettingWidget = () => {
    return (
      <div className="info-item">
        <div className="title">表单配置</div>
        {info.formList.map((item, index) => {
          return (
            <div key={index}>
              {info.formList.length > 1 && (
                <MinusCircleOutlined
                  style={{ marginBottom: '10px', fontSize: '20px', color: '#4073fa' }}
                  onClick={() => {
                    let temp = JSON.parse(JSON.stringify(info));
                    temp.formList.splice(index, 1);
                    setInfo(temp);
                    props.changeItem(temp);
                  }}
                />
              )}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ width: '50px' }}>标签</div>
                <Input
                  value={item.label}
                  style={{ flex: 1 }}
                  onChange={e => {
                    let temp = JSON.parse(JSON.stringify(info));
                    temp.formList[index].label = e.target.value;
                    setInfo(temp);
                    props.changeItem(temp);
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ width: '50px' }}>输入框</div>
                <Input
                  value={item.input}
                  style={{ flex: 1 }}
                  onChange={e => {
                    let temp = JSON.parse(JSON.stringify(info));
                    temp.formList[index].input = e.target.value;
                    setInfo(temp);
                    props.changeItem(temp);
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ width: '50px' }}>字段</div>
                <Input
                  value={item.key}
                  style={{ flex: 1 }}
                  disabled={item.key === 'name' || item.key === 'phone'}
                  onChange={e => {
                    let temp = JSON.parse(JSON.stringify(info));
                    temp.formList[index].key = e.target.value;
                    setInfo(temp);
                    props.changeItem(temp);
                  }}
                />
              </div>
            </div>
          );
        })}
        <PlusCircleOutlined
          style={{ fontSize: '20px', color: '#4073fa' }}
          onClick={() => {
            let temp = JSON.parse(JSON.stringify(info));
            temp.formList.push({
              label: '',
              input: '',
              key: '',
            });
            setInfo(temp);
            props.changeItem(temp);
          }}
        />
      </div>
    );
  };

  const positionTypeWidget = () => {
    return (
      <>
        <div className="info-item">
          <div className="title">悬浮位置</div>
          <Radio.Group
            onChange={e => {
              const val = e.target.value;
              info.position = val;
              let temp = JSON.parse(JSON.stringify(info));
              if (val === 'bottom') {
                delete temp.style.left;
                delete temp.style.right;
                delete temp.style.functionButtonBackgroundColor;
                delete temp.style.functionButtonTextColor;
                temp.style.width = '100%';
                temp.style.height = '50';
                temp.style.bottom = '0';
              } else if (val === 'left') {
                delete temp.style.right;
                temp.style.width = '120';
                temp.style.height = 'auto';
                temp.style.bottom = '10%';
                temp.style.left = '30';
              } else if (val === 'right') {
                delete temp.style.left;
                delete temp.style.bottom;
                temp.style.height = 'auto';
                temp.style.width = '120';
                temp.style.bottom = '10%';
                temp.style.right = '30';
              }
              setInfo(temp);
              props.changeItem(temp);
            }}
            value={info.position}
          >
            <Radio value={'bottom'}>底部</Radio>
            <Radio value={'left'}>左侧</Radio>
            <Radio value={'right'}>右侧</Radio>
          </Radio.Group>
        </div>
        {(info.position === 'left' || info.position === 'right') &&
          colorWidget(
            '按钮背景色',
            'functionButtonBackgroundColor',
            'function-btn-backgroundColor-input'
          )}
        {(info.position === 'left' || info.position === 'right') &&
          colorWidget('按钮文本颜色', 'functionButtonTextColor', 'function-btn-textColor-input')}
      </>
    );
  };

  const positionStyleWidget = () => {
    return (
      <>
        <div className="info-item">
          <div className="title">定位方式</div>
          <Radio.Group
            onChange={e => {
              let temp = JSON.parse(JSON.stringify(info));
              temp.style.position = e.target.value;
              delete temp.style.transform;
              setInfo(temp);
              props.changeItem(temp);
            }}
            value={info.style.position}
          >
            <Radio value={''}>默认</Radio>
            <div>
              <Radio value={'relative'}>相对(保持原有位置，但显示会偏移)</Radio>
            </div>
            <div>
              <Radio value={'absolute'}>绝对(会随页面滚动条滚动)</Radio>
            </div>
            <div>
              <Radio value={'fixed'}>浮动(会浮动在页面上，不随滚动条滚动)</Radio>
            </div>
          </Radio.Group>
        </div>
        {(info?.style?.position === 'relative' ||
          info?.style?.position === 'absolute' ||
          info?.style?.position === 'fixed') && (
          <div className="info-item" style={{ paddingTop: 0 }}>
            <div>
              {inputWidget('顶部', true, 'top', '', {
                type: 'slider',
                min: -50,
                max: pageMaxHeight,
              })}
            </div>
            <div>
              {inputWidget('底部', true, 'bottom', '', {
                type: 'slider',
                min: -50,
                max: pageMaxHeight,
              })}
            </div>
            <div>
              {inputWidget('左侧', true, 'left', '', {
                type: 'slider',
                min: -50,
              })}
            </div>
            <div>
              {inputWidget('右侧', true, 'right', '', {
                type: 'slider',
                min: -50,
              })}
            </div>
          </div>
        )}
      </>
    );
  };

  // const textTypeWidget = () => {
  //   return (
  //     <>
  //       <div className="info-item">
  //         <div className="title">文本类型</div>
  //         <Radio.Group
  //           onChange={e => {
  //             let temp = JSON.parse(JSON.stringify(info));
  //             let value = e.target.value;
  //             temp.textType = value;
  //             setInfo(temp);
  //             props.changeItem(temp);
  //           }}
  //           value={info.textType}
  //         >
  //           <Radio value={'normal'}>普通文本</Radio>
  //           <Radio value={'consult'}>咨询按钮</Radio>
  //           <Radio value={'submit'}>表单提交</Radio>
  //         </Radio.Group>
  //       </div>
  //       {info.textType &&
  //         info.textType === 'consult' &&
  //         inputWidget('小程序Id', false, 'miniprogramId')}
  //     </>
  //   );
  // };

  const paddingWidget = () => {
    return (
      <div className="info-item">
        <div className="title">内边距</div>
        <div className="info-flex-wrap">
          {smallInputWidget('上边距', true, 'paddingTop')}
          {smallInputWidget('右边距', true, 'paddingRight')}
          {smallInputWidget('下边距', true, 'paddingBottom')}
          {smallInputWidget('左边距', true, 'paddingLeft')}
        </div>
      </div>
    );
  };

  const transformWidget = () => {
    return (
      <div className="info-item">
        <div className="title">形变</div>
        <div className="info-flex-wrap">
          {smallInputWidget('上下', true, 'translateY')}
          {smallInputWidget('左右', true, 'translateX')}
        </div>
      </div>
    );
  };

  const selectWidget = (title, prop, list, cb = null) => {
    return (
      <div className="info-item">
        <div className="title">{title}</div>
        <Select
          placeholder="请选择"
          onChange={e => {
            let temp = JSON.parse(JSON.stringify(info));
            temp[prop] = e;
            setInfo(temp);
            props.changeItem(temp);
            if (typeof cb === 'function') {
              cb(e, temp);
            }
          }}
          value={info[prop]}
          allowClear
        >
          {list.map(item => {
            return (
              <Option value={item.value} key={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  };

  return (
    <div className="decorate-right">
      <div className="info-header">
        {info && info.label && <div className="info-title">{info.label}</div>}
        {info && info.type && (
          <div className="tabs-header">
            <div
              className={`${tab === 0 ? 'tabs-header__item active' : 'tabs-header__item'}`}
              onClick={() => setTab(0)}
            >
              内容
            </div>
            <div
              className={`${tab === 1 ? 'tabs-header__item active' : 'tabs-header__item'}`}
              onClick={() => setTab(1)}
            >
              样式
            </div>
          </div>
        )}
      </div>
      {info && info.label && info.label === '组件配置' && (
        <div>
          {inputWidget('留资标题', false, 'title')}
          {selectWidget('关联小程序', 'miniprogramId', miniprogramList)}
          {inputWidget('初始访问量', false, 'vist_num', '请输入初始访问量')}
          {openSmsSwitch()}
          {inputWidget('留资成功提示语', false, 'success_msg')}
          {inputWidget('留资失败提示语', false, 'error_msg')}
          {datePickerWidget('活动有效期', 'effect_time')}
          {imageWidget('活动封面', 'image')}
          {selectWidget('请选择省份', 'province', provinces, (value, info) => {
            let item = areas[value];
            if (item && Object.keys(item)) {
              let temp = Object.keys(item)
                .filter(key => key !== 'name')
                .map(key => {
                  return {
                    label: item[key],
                    value: key,
                  };
                });
              let infoTemp = JSON.parse(JSON.stringify(info));
              infoTemp['city'] = null;
              setInfo(infoTemp);
              setCities(temp);
            }
          })}
          {selectWidget('请选择城市', 'city', cities)}
          <div style={{ marginBottom: 40 }}></div>
        </div>
      )}
      {info && info.type && (
        <div>
          {tab === 0 && (
            <div>
              {info?.type === 'suspension' && <div>{suspensionBtnListWidget()}</div>}
              {info?.type === 'image' && <div>{imageWidget()}</div>}
              {(info?.type === 'text' || info?.type === 'button' || info?.type === 'submit') && (
                <div>
                  {inputWidget('文本内容', false, 'text')}
                  {info.type === 'button' && selectWidget('开放能力', 'openType', openTypeList)}
                  {info.openType === 'im' && inputWidget('客服抖音号', false, 'dataImId')}
                </div>
              )}
              {info?.type === 'video' && (
                <div>
                  {imageWidget('视频封面', 'coverImg', 'image')}
                  {imageFillWidget()}
                  {imageWidget('视频链接', 'src', 'video')}
                </div>
              )}
              {info?.type === 'audio' && (
                <div>
                  {imageWidget('音频开关图片', 'coverImg')}
                  {imageFillWidget()}
                  {imageWidget('音频链接', 'src', 'audio')}
                </div>
              )}
              {info?.type === 'form' && (
                <div>
                  {formSettingWidget('表单配置', 'forms')}
                  {checkboxWidget('是否显示协议（请在系统设置中维护用户协议）', 'ifShowPrivace')}
                  {inputWidget('协议文本', false, 'privacyAgreementText')}
                  {inputWidget('提交文本', false, 'formSubmitText')}
                  {inputWidget('跳转链接', false, 'jumpTo')}
                </div>
              )}
              {info?.type === 'divide' && (
                <div style={{ margin: '20px 20px 0' }}>方块组件常用于占位、分隔等布局时使用</div>
              )}
            </div>
          )}
          {tab === 1 && (
            <div>
              {info?.type === 'suspension' && positionTypeWidget()}
              {info?.type !== 'suspension' && positionStyleWidget()}
              {info?.type === 'suspension' &&
                colorWidget('背景颜色', 'backgroundColor', 'backgroundColor-input')}

              {info?.type === 'form' && (
                <>
                  {/* {inputWidget('表单层级', true, 'zIndex')} */}
                  {setZIndexWidget('层级', 'zIndex')}
                  {inputWidget('表单宽度', true, 'width', '', {
                    type: 'slider',
                    min: 0,
                    max: 375,
                  })}
                  {inputWidget('表单高度', true, 'height', '', {
                    type: 'slider',
                    min: 0,
                    max: 1000,
                  })}
                  {inputWidget('表单内间距', true, 'padding', '', {
                    type: 'slider',
                    min: 10,
                    max: 20,
                  })}

                  {inputWidget('边框宽度', true, 'borderWidth', '', {
                    type: 'slider',
                    min: 0,
                    max: 10,
                  })}
                  {colorWidget('边框颜色', 'borderColor', 'borderColor-input')}
                  {borderStyleWidget()}
                  {inputWidget('表单圆角', true, 'borderRadius', '', {
                    type: 'slider',
                    min: 0,
                    max: 100,
                  })}
                  {inputWidget('表单字体大小', true, 'fontSize', '', {
                    type: 'slider',
                    min: 0,
                    max: 24,
                  })}
                  {inputWidget('输入框间距', true, 'itemMarginBottom', '', {
                    type: 'slider',
                    min: 0,
                    max: 40,
                  })}
                  {inputWidget('标签宽度', true, 'labelWidth', '', {
                    type: 'slider',
                    min: 0,
                    max: 100,
                  })}
                  {colorWidget('表单背景颜色', 'backgroundColor', 'backgroundColor-input')}
                  {colorWidget('标签文本颜色', 'labelColor', 'label-color-input')}
                  {colorWidget('输入框文本颜色', 'inputColor', 'input-color-input')}
                  {colorWidget('提交按钮文本颜色', 'formSubmitColor', 'form-submit-color-input')}
                  {colorWidget(
                    '提交按钮背景色',
                    'formSubmitBackgroundColor',
                    'form-submit-background-color-input'
                  )}
                  {inputWidget('提交按钮宽度', true, 'formSubmitWidth', '', {
                    type: 'slider',
                    min: 150,
                    max: 300,
                  })}
                  {inputWidget('提交按钮高度', true, 'formSubmitHeight', '', {
                    type: 'slider',
                    min: 35,
                    max: 80,
                  })}
                  {inputWidget('提交按钮圆角', true, 'formSubmitBorderRadius', '', {
                    type: 'slider',
                    min: 0,
                    max: 100,
                  })}
                </>
              )}
              {info?.type !== 'suspension' && (
                <>
                  <div className="info-item">
                    <div className="title">组件外间距</div>
                    <Radio.Group
                      onChange={e => {
                        console.log('e:', e);
                        let temp = JSON.parse(JSON.stringify(info));
                        if (e.target.value === 'auto') {
                          temp.style.margin = '0 auto';
                          delete temp.style.marginTop;
                          delete temp.style.marginBottom;
                          delete temp.style.marginLeft;
                          delete temp.style.marginRight;
                        } else if (e.target.value === 'auto2') {
                          let lr = (375 - Number(info.style.width)) / 2;
                          temp.style.marginLeft = lr.toString();
                          temp.style.marginRight = lr.toString();
                          delete temp.style.margin;
                        } else {
                          temp.style.marginLeft = '0';
                          temp.style.marginRight = '0';
                          delete temp.style.margin;
                        }
                        setInfo(temp);
                        props.changeItem(temp);
                      }}
                      value={
                        info.style.margin === '0 auto'
                          ? 'auto'
                          : info.style.marginLeft ===
                            ((375 - Number(info.style.width)) / 2).toString()
                          ? 'auto2'
                          : 'custom'
                      }
                    >
                      <div>
                        <Radio value={'auto'}>居中</Radio>
                      </div>
                      <div>
                        <Radio value={'auto2'}>居中且上下不同</Radio>
                      </div>
                      <div>
                        <Radio value={'custom'}>
                          自定义（若需要实现左右居中，上下间距不同请保持左右间距相同即可实现居中）
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                  {!info.style.hasOwnProperty('margin') && (
                    <>
                      <div>
                        {inputWidget('上边距', true, 'marginTop', '', {
                          type: 'slider',
                          min: -100,
                          max: 100,
                        })}
                      </div>
                      <div>
                        {inputWidget('下边距', true, 'marginBottom', '', {
                          type: 'slider',
                          min: -100,
                          max: 100,
                        })}
                      </div>
                      {info.style.marginLeft !==
                        ((375 - Number(info.style.width)) / 2).toString() && (
                        <>
                          <div>
                            {inputWidget('左边距', true, 'marginLeft', '', {
                              type: 'slider',
                              min: -100,
                              max: 100,
                            })}
                          </div>
                          <div>
                            {inputWidget('右边距', true, 'marginRight', '', {
                              type: 'slider',
                              min: -100,
                              max: 100,
                            })}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {info?.type !== 'form' && (
                <>
                  {setZIndexWidget('层级', 'zIndex')}
                  {inputWidget('宽度', true, 'width', '', {
                    type: 'slider',
                    min: 0,
                    max: 375,
                  })}
                  {info?.type !== 'image' &&
                    inputWidget('高度', true, 'height', '', {
                      type: 'slider',
                      min: 0,
                      max: 300,
                    })}

                  {inputWidget('圆角设置', true, 'borderRadius', '', {
                    type: 'slider',
                    min: 0,
                    max: 1000,
                  })}
                  {/* {info?.type !== 'image' && paddingWidget()} */}
                  {info?.type !== 'image' &&
                    inputWidget('边框宽度', true, 'borderWidth', '', {
                      type: 'slider',
                      min: 0,
                      max: 10,
                    })}
                  {info?.type !== 'image' &&
                    colorWidget('边框颜色', 'borderColor', 'borderColor-input')}
                  {info?.type !== 'image' && borderStyleWidget()}
                  {info?.type === 'button' && (
                    <>
                      {inputWidget('字体大小', true, 'fontSize', '', {
                        type: 'slider',
                        min: 0,
                        max: 24,
                      })}
                      {textAlignWidget()}
                      {colorWidget('字体颜色', 'color', 'color-input')}
                    </>
                  )}
                  {info?.type === 'divide' ||
                    (info?.type === 'button' &&
                      colorWidget('背景颜色', 'backgroundColor', 'backgroundColor-input'))}
                  {/* {inputWidget('阴影', true, 'borderWidth')} */}
                  {/* {transformWidget()} */}
                </>
              )}
            </div>
          )}
          {
            <Button
              type="primary"
              block
              danger
              className="delete-button"
              onClick={() =>
                Modal.confirm({
                  title: '是否删除该元素？',
                  okText: '确认',
                  cancelText: '取消',
                  onOk() {
                    props.deleteItem();
                  },
                })
              }
            >
              删除
            </Button>
          }
        </div>
      )}
    </div>
  );
}

export default DecorateRight;
