import React, { useState, useEffect } from 'react';
import { SmileOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Select,
  Cascader,
  DatePicker,
  Upload,
  Result,
  message,
  Alert,
  Modal,
  Radio,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './index.scss';
import { getAllIndustryCategoryCode } from './util';
import moment from 'moment';

const { Option } = Select;

function Settle() {
  const qualificationTypes = [
    { label: '企业', value: '1' },
    { label: '个体工商户', value: '2' },
    { label: '党政机关', value: '3' },
    { label: '事业单位', value: '4' },
    { label: '港澳台', value: '5' },
    { label: '社会组织', value: '6' },
    { label: '境外', value: '7' },
    { label: '其他组织', value: '100' },
  ];

  const companyTypes = [
    { label: '有限责任公司', value: '1' },
    { label: '有限责任公司（自然人独资）', value: '2' },
    { label: '有限责任公司（自然人投资或控股）', value: '3' },
    { label: '有限责任公司（法人独资）', value: '4' },
    { label: '股份有限责任公司', value: '5' },
    { label: '其他', value: '100' },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [result, setResult] = useState(0);
  const [form] = Form.useForm();
  const [enterpriseForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [areas, setAreas] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [options, setOptions] = useState([]);
  const [preAuthData, setPreAuthData] = useState({});
  const [preAuthReason, setPreAuthReason] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [reasonRefusal, setReasonRefusal] = useState('');
  const [creditEndDateType, setCreditEndDateType] = useState(1);
  const [auditInfo, setAuditInfo] = useState({});

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (provinces.length) {
      // 查询认证状态
      getApplyStatus();
    }
  }, [provinces]);

  const init = async () => {
    // 获取所有区域
    await getAllArea();

    // 获取所有类目
    let categories = await getAllIndustryCategoryCode();
    setOptions(categories);
  };

  const creditEndDateOnChange = e => {
    setCreditEndDateType(e.target.value);
    if (e.target.value === 2) {
      form.setFieldValue('credit_end_date', '0');
    }
  };

  const setCreditEndDate = e => {
    console.log(e);
    form.setFieldValue('credit_end_date', e);
  };

  const getAllArea = async () => {
    try {
      const { data } = await window.g_dyFetch({
        url: window.g_api.dy_get_all_area_url,
        method: 'GET',
      });

      let p = [];
      Object.keys(data).forEach(key => {
        p.push({
          value: key,
          label: data[key].name,
        });
      });
      setAreas(data);
      setProvinces(p);
    } catch (error) {
      console.log(error);
    }
  };

  const getApplyStatus = async () => {
    const { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_apply_status_url,
      method: 'GET',
    });
    if (data.info) {
      setAuditInfo({
        ...data.info,
        appid: data.enterprise.appid,
        appsecret: data.enterprise.appsecret,
      });
      enterpriseForm.setFieldsValue({
        appid: data.enterprise.appid,
        appsecret: data.enterprise.appsecret,
      });
      if (data.info.check_status) {
        setCurrentStep(2);
        setResult(Number(data.info.check_status));
        setReasonRefusal(data.info.reason);
      } else if (data.info.status) {
        if (data.info.status === '0' || data.info.status === '2') {
          let preAuthDataCopy = JSON.parse(JSON.stringify(data.info));
          preAuthDataCopy.settle_id = data.info.id;
          preAuthDataCopy.credit_end_date = moment(preAuthDataCopy.credit_end_date);
          setPreAuthReason(preAuthDataCopy.reason);
          delete preAuthDataCopy.id;
          delete preAuthDataCopy.status;
          delete preAuthDataCopy.add_time;
          delete preAuthDataCopy.reason;
          let registered_province = {};
          let registered_city = {};
          Object.keys(preAuthDataCopy).forEach(key => {
            if (key === 'registered_province_code') {
              let provinceCode = Number(preAuthDataCopy['registered_province_code']);
              console.log('areas[provinceCode]:', areas[provinceCode]);

              registered_province = {
                key: provinceCode,
                value: areas[provinceCode].name,
                label: areas[provinceCode].name,
              };
            } else if (key === 'registered_city_code') {
              let provinceCode = Number(preAuthDataCopy['registered_province_code']);
              let cityCode = Number(preAuthDataCopy['registered_city_code']);
              registered_city = {
                key: cityCode,
                value: areas[provinceCode][cityCode],
                label: areas[provinceCode][cityCode],
              };
            }
          });
          preAuthDataCopy.registered_province_code = registered_province;
          preAuthDataCopy.registered_city_code = registered_city;
          form.setFieldsValue(preAuthDataCopy);

          setCurrentStep(0);
          setResult(0);
        } else if (data.info.status === '1') {
          preAuthDataToForm(JSON.parse(JSON.stringify(data)));
          let preAuthDataCopy = JSON.parse(JSON.stringify(data.info));
          preAuthDataCopy.settle_id = data.info.id;
          delete preAuthDataCopy.id;
          delete preAuthDataCopy.status;
          setPreAuthData(preAuthDataCopy);
          setCurrentStep(1);
          setResult(0);
        }
      }
    }
    return data;
  };

  const preAuthDataToForm = data => {
    let preAuthDataCopy = JSON.parse(JSON.stringify(data.info));
    preAuthDataCopy.settle_id = data.info.id;
    preAuthDataCopy.credit_end_date = moment(preAuthDataCopy.credit_end_date);
    setPreAuthReason(preAuthDataCopy.reason);
    delete preAuthDataCopy.id;
    delete preAuthDataCopy.status;
    delete preAuthDataCopy.add_time;
    delete preAuthDataCopy.reason;
    let registered_province = {};
    let registered_city = {};
    Object.keys(preAuthDataCopy).forEach(key => {
      if (key === 'registered_province_code') {
        let provinceCode = Number(preAuthDataCopy['registered_province_code']);
        console.log('areas[provinceCode]:', areas[provinceCode]);

        registered_province = {
          key: provinceCode,
          value: areas[provinceCode].name,
          label: areas[provinceCode].name,
        };
      } else if (key === 'registered_city_code') {
        let provinceCode = Number(preAuthDataCopy['registered_province_code']);
        let cityCode = Number(preAuthDataCopy['registered_city_code']);
        registered_city = {
          key: cityCode,
          value: areas[provinceCode][cityCode],
          label: areas[provinceCode][cityCode],
        };
      }
    });
    preAuthDataCopy.registered_province_code = registered_province;
    preAuthDataCopy.registered_city_code = registered_city;
    form.setFieldsValue(preAuthDataCopy);
  };

  const checkStatusFun = async () => {
    let data = await getApplyStatus();
    if (data.info && data.info.check_status === 2) {
      message.info('入驻审核中,请耐心等候');
    }
  };
  const editInfo = async () => {
    setCurrentStep(0);
    setResult(0);
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const uploadItem = () => {
    const customRequest = async options => {
      let formData = new FormData();

      formData.append('material_type', 1);
      formData.append('material_file', options.file);

      try {
        let res = await window.g_dyFetch({
          url: window.g_api.dy_upload_pic_material_url,
          method: 'POST',
          body: formData,
        });
        options.onSuccess(res, options.file);
      } catch (error) {
        message.error(error.msg);
      }
    };
    return (
      <Upload
        listType="picture-card"
        maxCount={1}
        fileList={fileList}
        customRequest={customRequest}
        onPreview={handlePreview}
        onChange={async ({ fileList: newFileList }) => {
          setFileList(newFileList);
          if (newFileList.length === 1 && newFileList[0].response?.data.path) {
            form.setFieldValue('credit_image_path', newFileList[0].response.data.path);
            let localeFormData = new FormData();
            localeFormData.append('file', newFileList[0].originFileObj);
            const localeRes = await window.g_dyFetch({
              url: window.g_api.file_upload,
              method: 'POST',
              body: localeFormData,
            });
            console.log('localeRes:', localeRes);
            // form.setFieldValue('credit_image_path_locality', localeRes.data.info.upload);
            preAuthData.credit_image_path_locality = localeRes.data.info.upload;
            setPreAuthData(preAuthData);
          }
        }}
      >
        <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            点击上传
          </div>
        </div>
      </Upload>
    );
  };

  const submit = () => {
    if (currentStep === 1 && typeof form.getFieldValue('credit_image_path') !== 'string') {
      message.warning('文件上传中');
      return;
    }
    form
      .validateFields()
      .then(async values => {
        console.log('creditEndDateType:', creditEndDateType);
        console.log('values:', values);
        if (values.credit_end_date && creditEndDateType === 1) {
          values.credit_end_date = values.credit_end_date.format('YYYY-MM-DD');
        }

        let formData = new FormData();
        Object.keys(values).forEach(key => {
          if (key !== 'reason') {
            if (key === 'registered_province_code' || key === 'registered_city_code') {
              formData.append(key, values[key]['key']);
            } else if (key === 'categorey_arr') {
              values.categorey_arr.forEach((item, index) => {
                formData.append(`category_arr[${index}]`, item);
              });
            } else if (key === 'credit_end_date') {
              if (creditEndDateType === 1) {
                formData.append(key, values[key]);
              } else {
                formData.append(key, '0');
              }
            } else {
              formData.append(key, values[key]);
            }
          }
          // console.log(`${key}:${formData.get(key)}`);
        });

        if (currentStep === 0) {
          try {
            const { data } = await window.g_dyFetch({
              url: window.g_api.dy_substitute_pre_check_url,
              method: 'POST',
              body: formData,
            });
            console.log('预审data:', data);
            let preAuthData = JSON.parse(JSON.stringify(data));
            delete preAuthData.status;
            setPreAuthData(preAuthData);
            setCurrentStep(1);
          } catch (error) {
            console.log(error);
            message.error(error.msg);
          }
        } else if (currentStep === 1) {
          Object.keys(preAuthData).forEach(key => {
            formData.append(key, preAuthData[key]);
          });
          let category_code = JSON.parse(JSON.stringify(values['categorey_arr']))
            .map(item => {
              return item.pop();
            })
            .join();
          formData.append('category_code', category_code);

          try {
            const { data } = await window.g_dyFetch({
              url: window.g_api.dy_substitute_apply_url,
              method: 'POST',
              body: formData,
            });
            setCurrentStep(2);
            setResult(2);
          } catch (error) {
            console.log(error);
            message.error(error.msg);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onPerfectEnterpriseInfoFinish = async values => {
    console.log('完善:', values);
    try {
      let formData = new FormData();
      formData.append('appid', values.appid);
      formData.append('appsecret', values.appsecret);
      const { data } = await window.g_dyFetch({
        url: window.g_api.dy_perfect_enterprise_url,
        method: 'POST',
        body: formData,
      });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  // 去对公认证
  const toVerifyUrl = () => {
    if (auditInfo.verify_url) {
      window.open(auditInfo.verify_url);
    } else {
      message.warning('地址不正确，请联系客服');
    }
  };

  return (
    <div className={currentStep === 2 ? 'settle settle-result' : 'settle'}>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
      {currentStep !== 2 && (
        <div className="form-wrap">
          <Form
            form={form}
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 6,
            }}
            layout="horizontal"
          >
            {currentStep === 0 && (
              <>
                {preAuthReason && (
                  <div style={{ marginBottom: 30 }}>
                    <Alert message={preAuthReason} type="error" />
                  </div>
                )}
                <Form.Item
                  label="主体类型"
                  name="qualification_type"
                  rules={[
                    {
                      required: true,
                      message: '请选择主体类型',
                    },
                  ]}
                >
                  <Select placeholder="请选择主体类型">
                    {qualificationTypes.map((item, index) => {
                      return (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="企业名称"
                  name="company_name"
                  rules={[
                    {
                      required: true,
                      message: '请输入企业名称',
                    },
                  ]}
                >
                  <Input placeholder="请输入企业名称" />
                </Form.Item>
                <Form.Item
                  label="营业执照编号"
                  name="credit_code"
                  rules={[
                    {
                      required: true,
                      message: '请输入营业执照编号',
                    },
                  ]}
                >
                  <Input placeholder="请输入营业执照编号" />
                </Form.Item>
                <Form.Item
                  label="营业执照有效期"
                  name="credit_end_date"
                  rules={[
                    {
                      required: true,
                      message: '请选择营业执照有效期',
                    },
                  ]}
                >
                  <div style={{ marginBottom: 10 }}>
                    <Radio.Group onChange={creditEndDateOnChange} value={creditEndDateType}>
                      <Radio value={1}>截止时间</Radio>
                      <Radio value={2}>无截止时间</Radio>
                    </Radio.Group>
                  </div>
                  <DatePicker
                    disabled={creditEndDateType === 2}
                    format="YYYY-MM-DD"
                    placeholder="请选择营业执照有效期"
                    onChange={(date, dateString) => setCreditEndDate(date)}
                  />
                </Form.Item>
                <Form.Item
                  label="企业类型"
                  name="company_type"
                  rules={[
                    {
                      required: true,
                      message: '请选择企业类型',
                    },
                  ]}
                >
                  <Select placeholder="请选择企业类型">
                    {companyTypes.map((item, index) => {
                      return (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="法人姓名"
                  name="legal_person_name"
                  rules={[
                    {
                      required: true,
                      message: '请输入法人姓名',
                    },
                  ]}
                >
                  <Input placeholder="请输入法人姓名" />
                </Form.Item>
                <Form.Item
                  label="省份编号"
                  name="registered_province_code"
                  rules={[
                    {
                      required: true,
                      message: '请选择省份编号',
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择省份编号"
                    labelInValue
                    showSearch
                    allowClear
                    onChange={eventData => {
                      console.log('eventData:', eventData);
                      if (eventData) {
                        let item = areas[Number(eventData.key)];
                        if (item && Object.keys(item)) {
                          let temp = Object.keys(item)
                            .filter(key => key !== 'name')
                            .map(key => {
                              return {
                                label: item[key],
                                value: key,
                              };
                            });
                          form.setFieldValue('registered_city_code', null);
                          setCities(temp);
                        }
                      }
                    }}
                  >
                    {provinces.map(item => {
                      return (
                        <Option value={item.label} key={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="城市编号"
                  name="registered_city_code"
                  rules={[
                    {
                      required: true,
                      message: '请选择城市编号',
                    },
                  ]}
                >
                  <Select placeholder="请选择城市编号" labelInValue showSearch allowClear>
                    {cities.map(item => {
                      return (
                        <Option value={item.label} key={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="详细地址"
                  name="registered_address"
                  rules={[
                    {
                      required: true,
                      message: '请输入详细地址',
                    },
                  ]}
                >
                  <Input placeholder="请输入详细地址" />
                </Form.Item>
                <Form.Item
                  label="注册资本(元)"
                  name="registered_capital"
                  rules={[
                    {
                      required: true,
                      message: '请输入注册资本',
                    },
                  ]}
                >
                  <Input placeholder="请输入注册资本" />
                </Form.Item>
              </>
            )}
            {currentStep === 1 && (
              <>
                <Form.Item
                  label="手机号"
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: '请输入手机号',
                    },
                  ]}
                >
                  <Input placeholder="请输入手机号" />
                </Form.Item>
                <Form.Item
                  label="营业执照"
                  name="credit_image_path"
                  rules={[
                    {
                      required: true,
                      message: '请上传营业执照',
                    },
                  ]}
                >
                  {uploadItem()}
                </Form.Item>
                <Form.Item
                  label="经营行业类目"
                  name="categorey_arr"
                  rules={[
                    {
                      required: true,
                      message: '请选择经营行业类目',
                    },
                  ]}
                >
                  <Cascader
                    multiple
                    showCheckedStrategy="SHOW_CHILD"
                    options={options}
                    placeholder="请选择经营行业类目"
                  ></Cascader>
                </Form.Item>
                <Form.Item
                  label="运营人员姓名"
                  name="business_person_name"
                  rules={[
                    {
                      required: true,
                      message: '请输入运营人员姓名',
                    },
                  ]}
                >
                  <Input placeholder="请输入运营人员姓名" />
                </Form.Item>
                <Form.Item
                  label="运营人员身份证号"
                  name="business_id_card"
                  rules={[
                    {
                      required: true,
                      message: '请输入运营人员身份证号',
                    },
                  ]}
                >
                  <Input placeholder="请输入运营人员身份证号" />
                </Form.Item>
                <Form.Item
                  label="运营人员手机号"
                  name="business_mobile"
                  rules={[
                    {
                      required: true,
                      message: '请输入运营人员手机号',
                    },
                  ]}
                >
                  <Input placeholder="请输入运营人员手机号" />
                </Form.Item>
              </>
            )}
            <Form.Item wrapperCol={{ offset: 4, span: 6 }}>
              <Button type="primary" onClick={submit}>
                提交
              </Button>
              {currentStep === 1 && (
                <Button
                  style={{ marginLeft: 10 }}
                  type="primary"
                  onClick={() => {
                    setCurrentStep(0);
                  }}
                >
                  上一步
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      )}
      {currentStep === 2 &&
        (result === 1 ? (
          <Result
            status="warning"
            title="账号注册失败"
            subTitle={`失败原因:${reasonRefusal}`}
            extra={
              <Button type="primary" onClick={editInfo}>
                修改信息，重新申请
              </Button>
            }
          />
        ) : result === 2 ? (
          <Result
            status="success"
            icon={<SmileOutlined />}
            title="恭喜您代入驻申请提交成功！请您耐心等待审核结果。"
            extra={
              <Button type="primary" onClick={checkStatusFun}>
                查询入驻状态
              </Button>
            }
          />
        ) : result === 3 ? (
          <Result
            status="warning"
            title="入驻失败"
            subTitle={`失败原因:${reasonRefusal}`}
            extra={
              <Button type="primary" onClick={editInfo}>
                修改信息，重新申请
              </Button>
            }
          />
        ) : result === 4 && auditInfo.verify_status !== 'success' ? (
          <>
            <div style={{ flexDirection: 'column' }}>
              <Result
                status="success"
                title="恭喜您入驻成功！"
                subTitle="请点击按钮去进行对公认证链接"
                extra={
                  <Button type="primary" onClick={toVerifyUrl}>
                    对公认证
                  </Button>
                }
              />
              <div>
                <h3 style={{ marginBottom: 15 }}>完善企业信息</h3>
                <Form
                  form={enterpriseForm}
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  onFinish={onPerfectEnterpriseInfoFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label="AppId"
                    name="appid"
                    rules={[{ required: true, message: '请输入AppId!' }]}
                  >
                    <Input placeholder="请输入AppId!" />
                  </Form.Item>

                  <Form.Item
                    label="AppSecret"
                    name="appsecret"
                    rules={[{ required: true, message: '请输入AppSecret!' }]}
                  >
                    <Input placeholder="请输入AppSecret!" />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                      立即完善
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>

            {auditInfo.verify_status === 'fail' && <p>对公认证失败原因:{auditInfo.reason}</p>}
          </>
        ) : (
          <></>
        ))}
    </div>
  );
}

export default Settle;
