import React, { useState, useEffect } from 'react';
import { Space, Table, Button, message, Popconfirm, Modal } from 'antd';
import { Link } from 'react-router-dom';

import './index.scss';

function Consultation() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [miniprogramList, setMiniprogramList] = useState([]);
  const [openPrevidwModal, setOpenPrevidwModal] = useState(false);
  const [previewQrCodeUrl, setPreviewQrCodeUrl] = useState('');

  const columns = [
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '关联小程序',
      dataIndex: 'app_name',
      key: 'app_name',
      render: (_, record, index) => {
        if (record.app_id !== '0') {
          return <span>{record.app_name}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: '活动封面',
      dataIndex: 'image',
      key: 'image',
      render: (_, record, index) => {
        if (record.image) {
          return (
            <img
              style={{ width: 60, height: 60 }}
              src={
                record.image.indexOf('https://') === -1
                  ? window.g_api.base_url + record.image
                  : record.image
              }
            />
          );
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: '活动有效期',
      dataIndex: 'effect_time',
      key: 'effect_time',
      render: (_, record, index) => {
        if (record.start_time && record.app_id !== '0') {
          return (
            <span>
              {record.start_time}至{record.end_time}
            </span>
          );
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: '添加时间',
      dataIndex: 'add_time',
      key: 'add_time',
      render: (_, record, index) => {
        if (record.add_time && record.app_id !== '0') {
          return <span>{window.g_getDayTime(record.add_time)}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          {record.app_id === '0' && (
            <Link
              to={{
                pathname: '/consultation-decorate',
                query: {
                  json_field: record.json_field,
                  title: '',
                  vist_num: record.vist_num,
                },
              }}
            >
              <Button type="link">拷贝</Button>
            </Link>
          )}
          {window.g_checkRole(35) && record.app_id !== '0' && (
            <>
              <Link
                to={{
                  pathname: '/consultation-decorate',
                  query: {
                    json_field: record.json_field,
                    active_id: record.id,
                    title: record.title,
                    miniprogramId: record.app_id,
                    vist_num: record.vist_num,
                    effect_time: [record.start_time, record.end_time],
                    image: record.image,
                    province: record.province,
                    city: record.city,
                    authorization_appid: record.authorization_appid,
                    is_hidden: record.is_hidden,
                    is_sms: record.is_sms,
                    template_id: record.template_id,
                    success_msg: record.success_msg,
                    error_msg: record.error_msg,
                  },
                }}
              >
                <Button type="link">编辑</Button>
              </Link>
              <Button
                type="link"
                onClick={async () => {
                  await getPreviewQrCode({
                    authorization_appid: record.authorization_appid,
                    version: 'latest',
                    path: 'pages/activity/index?active_id=' + record.id,
                  });
                  setOpenPrevidwModal(true);
                }}
              >
                预览
              </Button>
            </>
          )}
          {window.g_checkRole(11) && record.app_id !== '0' && (
            <>
              <Popconfirm
                title={record.is_hidden === '0' ? '确认下线此活动?' : '确认上线此活动'}
                onConfirm={() => {
                  onUpdateHidden(record);
                }}
                okText="确认"
                cancelText="取消"
              >
                <Button type="link">{record.is_hidden === '0' ? '下线' : '上线'}活动</Button>
              </Popconfirm>

              <Popconfirm
                title="确认删除此条数据?"
                onConfirm={() => {
                  onDeleteClick(record);
                }}
                okText="确认"
                cancelText="取消"
              >
                <Button type="link">删除</Button>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];

  useEffect(async () => {
    await getMiniprogramList();
  }, []);

  useEffect(async () => {
    await loadData();
  }, page);

  const loadData = async () => {
    let {
      data: { list, total },
    } = await window.g_dyFetch({
      url: `${window.g_api.dy_list_active_url}?page=${page}&pagesize=10&type=1`,
      method: 'GET',
    });
    setData(
      list.map(item => {
        if (miniprogramList.length) {
          item.app_name = miniprogramList.find(mini => mini.id == item.app_id)?.app_name || '';
        }
        return item;
      })
    );
    setTotal(total);
  };
  // 获取小程序列表
  const getMiniprogramList = async () => {
    let { data } = await window.g_dyFetch({
      url: window.g_api.dy_get_app_list_url,
      method: 'GET',
    });
    setMiniprogramList(data);
  };

  // 删除活动
  const onDeleteClick = async record => {
    await window.g_dyFetch({
      url: `${window.g_api.dy_del_active_url}?active_id=${record.id}`,
      method: 'GET',
    });
    message.success('删除成功');
    loadData();
  };

  // 更新hidden
  const onUpdateHidden = async record => {
    try {
      const is_hidden = record.is_hidden === '0' ? '1' : '0';
      await window.g_dyFetch({
        url: `${window.g_api.dy_update_active_hidden_url}?active_id=${record.id}&is_hidden=${is_hidden}`,
        method: 'GET',
      });
      await loadData();
    } catch (error) {
      console.log('error：', error);
    }
  };

  // 获取预览二维码
  const getPreviewQrCode = async info => {
    let formData = new FormData();
    formData.append('authorization_appid', info.authorization_appid);
    formData.append('version', info.version);
    formData.append('path', info.path);
    let res = await window.g_dyFetch({
      url: window.g_api.get_preview_qrcode_url,
      method: 'POST',
      body: formData,
    });
    setPreviewQrCodeUrl(res.data);
  };

  return (
    <div className="consultation">
      {window.g_checkRole(34) && (
        <Link
          to={{
            pathname: '/consultation-decorate',
          }}
        >
          <Button type="primary" style={{ marginBottom: '10px' }}>
            新增
          </Button>
        </Link>
      )}
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{
          total,
          onChange: (page, pageSize) => {
            setPage(page);
          },
        }}
      />
      <Modal
        title="请在抖音中扫码预览"
        open={openPrevidwModal}
        onOk={() => {
          setOpenPrevidwModal(false);
        }}
        onCancel={() => {
          setOpenPrevidwModal(false);
        }}
      >
        <div style={{ margin: '20px auto', textAlign: 'center' }}>
          <img src={previewQrCodeUrl} alt="" style={{ width: 200, height: 200 }} />
        </div>
      </Modal>
    </div>
  );
}

export default Consultation;
